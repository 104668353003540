import { useGetQuery, UseGetQueryOptions } from "@src/appV2/api";
import { UseQueryResult } from "@tanstack/react-query";
import { environmentConfig } from "@src/appV2/environment";
import { z } from "zod";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { startOfHour } from "date-fns";

/**
 * Describes the parameters for the getFacilityStreaks API.
 * The `transforms` found in this schema do the work of converting
 * the input parameters (human readable) into the format that are
 * optimal for the API (machine readable).
 */
const getFacilityStreaksParamsSchema = z.object({
  facilityId: z.string().optional(),
  filter: z.object({
    date: z
      .date()
      .optional()
      .default(startOfHour(new Date()))
      .transform((date) => date?.toISOString()),
    agentIds: z
      .array(z.string())
      .optional()
      .default([])
      .transform((agentIds) => agentIds.join(",")),
  }),
});

/**
 * Describes the request schema for the getFacilityStreaks API that the
 * endpoint expects.
 */
const getFacilityStreaksRequestSchema = z.object({
  filter: z.object({
    date: z.string(),
    agentIds: z.string(),
  }),
});

export enum StreakStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

/**
 * Describes the schema for the streak object that the getFacilityStreaks
 * API returns.
 */
export const streakSchema = z.object({
  id: z.string(),
  type: z.literal("STREAKS"),
  attributes: z.object({
    agentId: z.string(),
    facilityId: z.string(),
    validityInterval: z.object({
      from: z.string(),
      to: z.string(),
    }),
    status: z.nativeEnum(StreakStatus),
    minHoursForActiveStreak: z.number(),
    payBoostEnabled: z.boolean(),
    payBoostPercentage: z.number(),
  }),
});

const getFacilityStreaksResponseSchema = z.object({
  data: z.array(streakSchema),
});

export type FacilityStreaksAPIResponseType = z.infer<typeof getFacilityStreaksResponseSchema>;
export type Streak = z.infer<typeof streakSchema>;
export type GetFacilityStreaksParams = z.input<typeof getFacilityStreaksParamsSchema>;

export function useGetFacilityStreaks(
  params: GetFacilityStreaksParams,
  options: UseGetQueryOptions<FacilityStreaksAPIResponseType> = {}
): UseQueryResult<FacilityStreaksAPIResponseType> {
  const { facilityId, filter } = getFacilityStreaksParamsSchema.parse(params);

  return useGetQuery({
    url: `${environmentConfig.REACT_APP_BASE_API_URL}/facility/${facilityId}/streaks`,
    queryParams: {
      filter,
    },
    requestSchema: getFacilityStreaksRequestSchema,
    responseSchema: getFacilityStreaksResponseSchema,
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_FACILITY_STREAKS_FAILURE,
    },
    staleTime: 60 * 1000,
    retryDelay: 3000,
    ...options,
  });
}
