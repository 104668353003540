import { formatTime } from "@clipboard-health/date-time";
import { useEffect, useState } from "react";

export interface UseDisplayTimeProps {
  timeZone?: string;
}

export function useDisplayTime(props: UseDisplayTimeProps = {}): string | undefined {
  const { timeZone } = props;
  const [currentTime, updateCurrentTime] = useState<string | undefined>();

  useEffect(() => {
    if (!timeZone) {
      return;
    }
    const intervalId = window.setInterval(() => {
      updateCurrentTime(formatTime(new Date(), { timeZone }));
    }, 1000);
    return () => {
      if (intervalId) {
        window.clearInterval(intervalId);
      }
    };
  }, [updateCurrentTime, timeZone]);

  return currentTime;
}
