const TIMEZONES = {
  PACIFIC: "America/Los_Angeles",
  EASTERN: "America/New_York",
  CENTRAL: "America/Chicago",
  MOUNTAIN: "America/Denver",
};

const FULL_ZONE_NAMES = {
  EST: "Eastern Standard Time",
  EDT: "Eastern Daylight Time",
  CST: "Central Standard Time",
  CDT: "Central Daylight Time",
  MST: "Mountain Standard Time",
  MDT: "Mountain Daylight Time",
  PST: "Pacific Standard Time",
  PDT: "Pacific Daylight Time",
};

const DEFAULT_TIMEZONE = TIMEZONES.PACIFIC;

export { TIMEZONES, DEFAULT_TIMEZONE, FULL_ZONE_NAMES };
