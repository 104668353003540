import React, { useState, useEffect } from "react";
import { Form, Row, Select, Button, PageHeader, Divider, Typography, Alert } from "antd";
import MonacoEditor from "react-monaco-editor";
import yaml from "yaml";
import ShouldRender from "./ShouldRender";
import { AGENT_LICENSE_UPDATE, AGENT_ADDRESS_UPDATE } from "../constants/RUN_SERVICE";
import axios from "axios";
import { showErrorToast, showSuccessToast } from "@src/appV2/lib/Notifications";

const { Title } = Typography;

function RunService(props: any) {
  const { selectedRegressionTestData = {} } = props;
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [serviceYAMLData, setServiceYAMLData] = useState(AGENT_LICENSE_UPDATE);
  const [serviceType, setServiceType] = useState("agentLicenceUpdated");

  useEffect(() => {
    setServiceYAMLData(
      serviceType === "agentLicenceUpdated" ? AGENT_LICENSE_UPDATE : AGENT_ADDRESS_UPDATE
    );
  }, [serviceType]);

  const editorDidMount = (editor, monaco) => {
    editor.focus();
  };

  const options = {
    selectOnLineNumbers: true,
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const onSubmit = async () => {
    setLoading(true);

    try {
      const { service } = yaml.parse(serviceYAMLData);
      axios
        .post(`/api/testHelpers/runService`, { service })
        .then((res) => {
          showSuccessToast("Success");
        })
        .catch((e) => {
          showErrorToast(`Error: ${e.response.data}`);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      showErrorToast(`Error running -  ${(error as Error).message}`);
      console.error(error);
      setLoading(false);
    }
  };

  return (
    <div>
      <PageHeader
        className="shiftActionPageHeader"
        title="Run Service"
        extra={[
          <Button
            key="Intro to Yaml"
            type="default"
            onClick={() => {
              window.open(`https://dev.to/paulasantamaria/introduction-to-yaml-125f`)?.focus();
            }}
          >
            Intro to YAML
          </Button>,
        ]}
      />
      <Divider className="shiftActionHeaderDivider" />
      <Row justify="start" align="middle" style={{ width: "100%", height: "100%" }}>
        <Form {...layout} name="basic" form={form} onFinish={onSubmit} style={{ width: "600px" }}>
          <Row>
            <h4>Select service </h4>
            <Select
              placeholder="Please select service type"
              className="scenariosBox"
              onChange={(value) => setServiceType(value)}
              value={serviceType}
              allowClear
              data-testid="service-switcher"
            >
              <Select.Option value={"agentLicenceUpdated"} data-testid="Agent License Updated">
                {" "}
                Agent License Updated
              </Select.Option>
              <Select.Option value={"agentAddressUpdated"} data-testid="Agent Address Updated">
                {" "}
                Agent Address Updated
              </Select.Option>
            </Select>
          </Row>
          <Row>
            <Title level={4}>YAML Config</Title>
            <div>
              <ShouldRender condition={selectedRegressionTestData.name}>
                <Alert
                  style={{ marginBottom: ".5rem" }}
                  message={`Showing config data for test - ${selectedRegressionTestData.name}`}
                  type="info"
                  showIcon
                />
              </ShouldRender>
            </div>
          </Row>
          <Row>
            <MonacoEditor
              height="400"
              language="javascript"
              theme="vs-dark"
              value={serviceYAMLData}
              options={options}
              editorDidMount={editorDidMount}
              onChange={(value) => {
                setServiceYAMLData(value);
              }}
            />
          </Row>

          <Row justify="end" align="middle" style={{ marginTop: "1rem" }}>
            <Button type="primary" htmlType="submit" loading={loading}>
              Submit
            </Button>
          </Row>
        </Form>
      </Row>
    </div>
  );
}

export default RunService;
