import { useState, useEffect, useCallback } from "react";
import { RateNegotiationConfigEnum } from "src/components/RateNegotiationModal/interfaces";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";

interface IUseRateNegotiationFlag {
  isAdmin: boolean;
  facilityRateNegotiationFlag: RateNegotiationConfigEnum | undefined;
  facilityId: string;
  facilityMsa: string;
  facilityState: string;
}

interface INegotiationFeatureFlag {
  id?: string[];
  state?: string[];
  msa?: string[];
  global?: boolean;
}

/**
 * Checks if the "rate negotiation" feature is enabled for this facility based on its attributes.
 */
export const useRateNegotiationFlag = ({
  isAdmin,
  facilityRateNegotiationFlag,
  facilityId,
  facilityMsa,
  facilityState,
}: IUseRateNegotiationFlag): {
  isActive: boolean;
  featureFlagStatus: boolean;
  timeNegotiationFeatureFlagStatus: boolean;
} => {
  const [isRateNegotiationActive, setIsRateNegotiationActive] = useState<boolean>(isAdmin);
  // Decides activation. It is a combination of FF + DB. For admin it is always true.

  const [rateNegotiationFeatureFlagStatus, setRateNegotiationFeatureFlagStatus] =
    useState<boolean>(false);
  // This is just the FF value. Does not decide activation of feature.

  const [timeNegotiationFeatureFlagStatus, setTimeNegotiationFeatureFlagStatus] =
    useState<boolean>(isAdmin);
  // This is FF value BUT also decides activation unlike rate, so always true for admins

  const getFeatureFlagStatus = useCallback(
    (activationConditions: INegotiationFeatureFlag): boolean => {
      const facilityUserIdEnabled = !!activationConditions.id?.includes(facilityId?.toString());
      const facilityStateEnabled = !!activationConditions.state?.includes(facilityState);
      const facilityMsaEnabled = !!activationConditions?.msa?.includes(facilityMsa);
      const ffStatus =
        facilityUserIdEnabled ||
        facilityStateEnabled ||
        facilityMsaEnabled ||
        !!activationConditions.global;
      return ffStatus;
    },
    [facilityState, facilityMsa, facilityId]
  );
  const rateActivationConditions = useCbhFlag(CbhFeatureFlag.RATE_NEGOTIATION_ACTIVATION, {
    defaultValue: {},
  });

  const timeActivationConditions = useCbhFlag(CbhFeatureFlag.TIME_NEGOTIATION_ACTIVATION, {
    defaultValue: {},
  });

  useEffect(() => {
    const rateFFStatus = getFeatureFlagStatus(rateActivationConditions);
    const timeFFStatus = getFeatureFlagStatus(timeActivationConditions);
    setRateNegotiationFeatureFlagStatus(rateFFStatus);
    setTimeNegotiationFeatureFlagStatus(isAdmin || timeFFStatus);
    setIsRateNegotiationActive(
      isAdmin || (facilityRateNegotiationFlag === RateNegotiationConfigEnum.ON && rateFFStatus)
    );
  }, [
    facilityId,
    facilityMsa,
    facilityRateNegotiationFlag,
    facilityState,
    getFeatureFlagStatus,
    isAdmin,
    rateActivationConditions,
    timeActivationConditions,
  ]);

  return {
    isActive: isRateNegotiationActive,
    featureFlagStatus: rateNegotiationFeatureFlagStatus,
    timeNegotiationFeatureFlagStatus,
  };
};
