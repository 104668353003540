import { Modal } from "antd";
import styled from "styled-components";

export const Container = styled.div`
  margin-top: -64px;
`;

export const Slider = styled.div`
  position: absolute;
  left: 24px;
  top: 16px;
  font-size: 18px;
`;

export const Controls = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: end;
  gap: 20px;
  margin-top: 32px;
`;

export const ButtonGroup = styled.div`
  display: flex;
  gap: 12px;
`;

export const SuccessContent = styled.div`
  text-align: center;
`;

export const SuccessImage = styled.img`
  width: 96px;
  margin: 0 auto 20px auto;
`;

export const SuccessFooter = styled.div`
  margin-top: 32px;
  gap: 12px;
  display: flex;
  justify-content: center;
`;

export const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 8px;
  }
`;
