import { IoMdClose } from "react-icons/io";
import { StyledPopover, Wrapper } from "./Popover.styles";
import { Popover as AntdPopover } from "antd";
import { ReactNode } from "react";

interface PopoverProps {
  variant: "primary" | "secondary";
  content: ReactNode;
  open: boolean;
  showPopover: boolean;
  onHandleClose: () => void;
  placement:
    | "left"
    | "right"
    | "top"
    | "bottom"
    | "topLeft"
    | "topRight"
    | "bottomLeft"
    | "bottomRight"
    | "leftTop"
    | "leftBottom"
    | "rightTop"
    | "rightBottom";
  width?: number;
  children: JSX.Element;
}

export function InfoPopover(props: PopoverProps) {
  const { variant, content, open, showPopover, onHandleClose, placement, width, children } = props;
  return showPopover ? (
    <StyledPopover variant={variant} width={width}>
      <AntdPopover
        getPopupContainer={(triggerNode) => triggerNode}
        content={
          <Wrapper onClick={(e) => e.stopPropagation()} variant={variant}>
            {content}
            <div>
              <IoMdClose onClick={onHandleClose} />
            </div>
          </Wrapper>
        }
        trigger="click"
        placement={placement}
        visible={open}
      >
        {children}
      </AntdPopover>
    </StyledPopover>
  ) : (
    children
  );
}
