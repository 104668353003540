import { Rate, Typography } from "antd";
import styled from "styled-components";

export const Wrapper = styled.div`
  margin: 10px 30px 10px 0px;
  color: #333333;
`;

export const ReviewListTitle = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 8px;
  font-size: 20px;
  line-height: 20px;
`;

export const ReviewList = styled.ul`
  font-size: 18px;
  padding: 0;
  > li {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }
  > li + li {
    margin: 10px 0;
    padding-top: 20px;
    border-top: 1px solid #bdbdbd;
  }
`;

export const WorkerRating = styled(Rate)`
  color: #f9cb7c;
  border: none;
  font-size: 16px;
  & > .ant-rate-star:not(:last-child) {
    margin-right: 4px;
  }
`;
export const ReviewListItemTitle = styled.div`
  display: flex;
  align-items: end;
  justify-content: start;
  width: 100%;
  gap: 8px;
  > span {
    font-size: 14px;
    font-weight: 400;
  }
`;
export const ReviewListItemContent = styled(Typography.Paragraph)`
  width: 100%;
  font-size: 16px;
  font-weight: 500;
`;
