import Text from "antd/lib/typography/Text";
import { WorkerWithLastShift } from "src/api/workers";
import moment from "moment-timezone";
import { getFirstLetterOfName, getFirstName } from "src/utils/strings";
import { Rating } from "src/components/WorkerRatingCarouselModal/RatingPerformanceForm";
import {
  formatShiftDate,
  getQualityTitle,
} from "src/components/WorkerRatingPerformanceModal/utils";
import {
  Container,
  WorkerInfo,
  WorkerAvatar,
  Title,
  WorkerRating,
  Label,
} from "./WorkerRatingPerformance.styles";

interface ViewRatingPerformanceProps {
  worker: { avatarUrl: string; name: string; userId: string };
  lastShift: WorkerWithLastShift["lastShift"];
  rating: Rating;
  timezone: string;
}

export function ViewRatingPerformance({
  worker,
  lastShift,
  rating,
  timezone,
}: ViewRatingPerformanceProps) {
  return (
    <Container data-testid="view-rating-performance">
      <WorkerInfo>
        <WorkerAvatar src={worker.avatarUrl} alt={worker.name}>
          {getFirstLetterOfName(worker.name)}
        </WorkerAvatar>
        <Title>
          {worker.name}
          {lastShift !== undefined ? `, ${lastShift.agentReq}` : undefined}
        </Title>
        <Title bold>How would you rate {getFirstName(worker.name)}’s overall performance?</Title>
        {lastShift !== undefined && (
          <Text italic type="secondary">
            Last worked on {formatShiftDate(lastShift.start, lastShift.end, timezone)}
          </Text>
        )}
        <WorkerRating value={rating.rating} allowClear={false} disabled={true} />
      </WorkerInfo>
      {rating.qualities && rating.qualities.length > 0 && (
        <div>
          <Label>{getQualityTitle(rating.rating)}</Label>
          <Text type="secondary">{rating.qualities?.join(", ")}</Text>
        </div>
      )}
      {rating.additionalFeedback && (
        <div>
          <Label>Additional feedback</Label>
          <Text type="secondary">{rating.additionalFeedback}</Text>
        </div>
      )}
      {rating.lastEditedBy !== undefined && (
        <Text type="secondary">
          Added by {rating.lastEditedBy} on {moment(rating.updatedAt).format("MM/DD/YYYY")}
        </Text>
      )}
    </Container>
  );
}
