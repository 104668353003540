import request from "superagent";
import { getErrorMessage } from "../../utils/errors";
import { getAuthHeader } from "../../utils/superagent";
const handleError = (error) => ({
  body: {
    error: getErrorMessage(error),
  },
});

export const getUpcomingShiftsGrouped = async () => {
  return request
    .get(`${global.api}/v1/shifts/upcomingShiftsGrouped`)
    .set(await getAuthHeader())
    .then(({ body }) => body.response)
    .catch(handleError);
};
