import { Box, type BoxProps } from "@mui/material";
import { forwardRef } from "react";

import { mergeSxProps } from "../mui";

export interface ImageProps extends Omit<BoxProps, "component" | "children"> {
  src: string;
  alt: string;
}

export const Image = forwardRef((props: ImageProps, ref) => {
  const { sx, width, height, ...boxProps } = props;

  return (
    <Box
      component="img"
      {...boxProps}
      ref={ref}
      sx={mergeSxProps(
        {
          /**
           * Box does not pass `width` and `height` through.
           * Instead, pass it in via `sx`
           */
          width,
          height,
        },
        sx
      )}
    />
  );
});

Image.displayName = "Image";
