export function getSignInLink(requestedURL: string, email: string, keepMeLogin: boolean): string {
  const isDev = window.location.hostname === "localhost";
  const port = window.location.port !== "" ? `:${window.location.port}` : "";
  const appDomain = `http${isDev ? "" : "s"}://${window.location.hostname}${port}`;
  const searchParams = new URLSearchParams({
    requestedUrl: requestedURL,
    email,
    keepMeLogin: String(keepMeLogin),
  });
  return `${appDomain}/login/verify?${searchParams}`;
}
