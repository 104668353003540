import moment from "moment-timezone";

const SHIFT_STARTS_NOW = `shift:
  length: 10
  agentReq: CNA
  facilityName: Acacia Park Nursing & Rehab
  agentEmail: admk8501@yopmail.com
  charge: 32
  pay: 24
  isInstantPay: true`;
const SHIFT_ENDS_NOW = `shift:
  length: 10
  agentReq: CNA
  facilityName: Acacia Park Nursing & Rehab
  agentEmail: admk8501@yopmail.com
  charge: 32
  pay: 24
  isInstantPay: false`;
const CUSTOM_TIME_SHIFT = `shift:
  start: ${moment().startOf("day").format()}
  end: ${moment().startOf("day").format()}
  agentReq: CNA
  facilityName: Acacia Park Nursing & Rehab
  agentEmail: admk8501@yopmail.com
  charge: 32
  pay: 24
  isInstantPay: true`;

const ASSIGN_SHIFT_YAML_DATA = {
  STARTS_NOW: SHIFT_STARTS_NOW,
  ENDS_NOW: SHIFT_ENDS_NOW,
  CUSTOM_TIME: CUSTOM_TIME_SHIFT,
};

export { ASSIGN_SHIFT_YAML_DATA };
