import { useIsAdmin } from "./useIsAdmin";
import { useWorkplaceType } from "./useWorkplaceType";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";

export function useIsHomeHealthEnabled() {
  const isAdmin = useIsAdmin();

  const featureFlagEnabled = useCbhFlag(CbhFeatureFlag.HOME_HEALTH_ENABLED, {
    defaultValue: false,
  });

  const workplaceType = useWorkplaceType();
  const isTypeAllowed = workplaceType === "Home Healthcare" || workplaceType === "Hospice";

  return (isAdmin || featureFlagEnabled) && isTypeAllowed;
}
