import { isDefined } from "@clipboard-health/util-ts";
import { isEmpty } from "lodash";
import { logError } from "../lib/analytics";
import { RenderCustomMessageProps } from "./type";

export function parseShiftInviteChatMessage(props: RenderCustomMessageProps) {
  const { message } = props;
  const defaultData = { shiftInviteId: undefined, status: undefined };
  try {
    let facilityMessage;
    if ("message" in message && typeof message.message === "string") {
      ({ facility: facilityMessage } = JSON.parse(message.message) as { facility: string });
    }

    const { shiftInviteId, status } =
      isDefined(message.data) && !isEmpty(message.data)
        ? (JSON.parse(message.data) as {
            shiftInviteId: string;
            status: string;
          })
        : defaultData;
    return { facilityMessage, shiftInviteId, status };
  } catch (error) {
    logError("Shift Invite Chat Message Parsing Error", { error, metadata: { message } });
    return { facilityMessage: undefined, ...defaultData };
  }
}
