import { useSelector } from "react-redux";
import { ModalCommonProps } from "./types";
import {
  ButtonGroup,
  StyledModal,
  WorkplaceContractFormModalTitle,
  WorkplaceContractFormModalHelpText,
} from "./styles";
import { Button } from "src/designsystem/Button/Button";
import { SessionType } from "src/modules/interface";
import { Link } from "../../../designsystem/Link/Link";
import { useMutation } from "@tanstack/react-query";
import { verifyFacilityUser as verifyFacilityUserApi } from "../api";
import { notification } from "antd";
import { EMAILS } from "src/constants/communications";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";

function WorkplaceSignContractUnverifiedUser({ closeModal, onSubmit }: ModalCommonProps) {
  const { user, profile } = useSelector((state: SessionType) => state.session);

  const { showResendEmailCTA } = useCbhFlag(CbhFeatureFlag.WORKPLACE_SELF_SIGNUP, {
    defaultValue: {},
  });

  const verifyFacilityUser = useMutation({
    mutationFn: verifyFacilityUserApi,
  });

  const onSubmitForVerificationEmail = async () => {
    if (!user?._id || !profile?.userId) {
      notification.error({
        message: "Unexpected error occurred, when sending the email!",
      });
      return;
    }
    try {
      const { success } = await verifyFacilityUser.mutateAsync({
        facilityUserId: user._id,
        facilityId: profile.userId,
      });
      if (!success) {
        throw new Error(
          "Unfortunately! Message sending failed. Please try again later or contact us!"
        );
      }
      notification.success({
        message: "Resending the verification email is successful!",
      });
      onSubmit();
      closeModal();
    } catch (error) {
      notification.error({
        message: "Error in sending the verification email. Please try again after some time.",
      });
    }
  };

  return (
    <StyledModal visible closable onCancel={closeModal} footer={null} cancelText={null}>
      <WorkplaceContractFormModalTitle level={3}>
        As a last step, we need to verify your email
      </WorkplaceContractFormModalTitle>
      <WorkplaceContractFormModalHelpText>
        Check your inbox at <strong>{user?.email}</strong> to verify your account. Once you click
        the link in the email, you’ll have full access and can start posting shifts!
      </WorkplaceContractFormModalHelpText>
      <br />
      <WorkplaceContractFormModalHelpText>
        Didn't get the email? Be sure to check your spam folder. If you still need help, please
        contact us at <Link href={`mailto:${EMAILS.getAccess}`}>{EMAILS.getAccess}</Link>.
      </WorkplaceContractFormModalHelpText>
      <ButtonGroup>
        {showResendEmailCTA && (
          <Button
            variant="secondary"
            onClick={onSubmitForVerificationEmail}
            isLoading={verifyFacilityUser.isLoading}
          >
            Resend email
          </Button>
        )}
        <Button variant="primary" onClick={closeModal}>
          OK
        </Button>
      </ButtonGroup>
    </StyledModal>
  );
}

export { WorkplaceSignContractUnverifiedUser };
