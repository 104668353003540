import { useMutation, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";
import { SHIFT_INVITES_URL } from "../constants";
import { remove } from "@src/appV2/api";
import { useToast } from "@src/appV2/lib";
import { isDefined } from "@clipboard-health/util-ts";
import { AxiosError } from "axios";

export const shiftInvitesErrorSchema = z.object({
  detail: z.string().nullish(),
  title: z.string().nullish(),
  code: z.string().nullish(),
  id: z.string().nullish(),
});

export const shiftInviteErrorResponseSchema = z.object({
  errors: z.array(shiftInvitesErrorSchema).nullish(),
});

export function useDeleteShiftInvite() {
  const queryClient = useQueryClient();
  const { showErrorToast, showSuccessToast } = useToast();

  return useMutation({
    mutationFn: (shiftInviteId: string) =>
      remove({
        url: `${SHIFT_INVITES_URL}/${shiftInviteId}`,
        responseSchema: z.any({}).nullish(),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [SHIFT_INVITES_URL] });
      showSuccessToast("Shift invite removed successfully");
    },
    onError: (error: AxiosError) => {
      let errorMessage =
        "Unexpected error occurred while deleting shift invite. Please try again later.";
      if (isDefined(error.response?.data)) {
        const parsedErrorResult = shiftInviteErrorResponseSchema.safeParse(error.response?.data);
        if (parsedErrorResult.success && isDefined(parsedErrorResult.data.errors?.[0].detail)) {
          errorMessage = parsedErrorResult.data.errors?.[0].detail ?? "";
        }
      }
      showErrorToast(errorMessage);
    },
  });
}
