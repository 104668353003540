import React, { useState, useEffect } from "react";
import { Form, Row, Button, PageHeader, Divider, Typography, Alert } from "antd";
import { _recordShiftTime } from "./common/recordShiftTime";
import "./css/shift_action.css";
import { SHIFT_ACTION_DEFAULT_YAML_VALUE } from "./constants/shift";
import MonacoEditor from "react-monaco-editor";
import yaml from "yaml";
import ShouldRender from "./components/ShouldRender";
import "moment-timezone";
import { showErrorToast, showSuccessToast } from "@src/appV2/lib/Notifications";

const { Title } = Typography;

function ShiftAction(props) {
  const { selectedRegressionTestData = {} } = props;
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [shiftActionsYAMLData, setShiftActionsYAMLData] = useState(SHIFT_ACTION_DEFAULT_YAML_VALUE);

  useEffect(() => {
    if (selectedRegressionTestData.config) {
      const { shiftActions: shiftActionsData } = yaml.parse(selectedRegressionTestData.config);
      setShiftActionsYAMLData(yaml.stringify({ shiftActions: shiftActionsData }));
    } else if (selectedRegressionTestData.id) {
      setShiftActionsYAMLData("shiftActions:\n  -");
    }
  }, [selectedRegressionTestData]);

  const editorDidMount = (editor) => {
    editor.focus();
  };

  const options = {
    selectOnLineNumbers: true,
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const onSubmit = async () => {
    setLoading(true);

    try {
      const { shiftActions } = yaml.parse(shiftActionsYAMLData);
      const shiftActionsPromises: any[] = [];

      shiftActions.forEach((shiftAction) => {
        const { shiftId, actionTime, action } = shiftAction;
        shiftActionsPromises.push(_recordShiftTime(shiftId, actionTime, action));
      });

      const shiftActionsResponses = await Promise.all(shiftActionsPromises);

      shiftActionsResponses.forEach((shiftActionsResponse, index) => {
        const resp = JSON.parse(shiftActionsResponse.text);
        if (resp?.success) {
          showSuccessToast(`${shiftActions[index].action} shift action completed successfully`);
        } else {
          showErrorToast(
            `${shiftActions[index].action}  shift actions did not complete successfully`
          );
        }
      });
    } catch (error) {
      console.error("Action Resp Error: ", error);
      showErrorToast(`Test panel Error ${(error as Error).message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <PageHeader
        className="shiftActionPageHeader"
        title="Shift Action"
        extra={[
          <Button
            key="Intro to YAML"
            type="default"
            onClick={() => {
              window.open(`https://dev.to/paulasantamaria/introduction-to-yaml-125f`)?.focus();
            }}
          >
            Intro to YAML
          </Button>,
        ]}
      />
      <Divider className="shiftActionHeaderDivider" />
      <Row justify="start" align="middle" style={{ width: "100%", height: "100%" }}>
        <Form {...layout} name="basic" form={form} onFinish={onSubmit} style={{ width: "600px" }}>
          <Row>
            <Title level={4}>YAML Config</Title>
            <div>
              <ShouldRender condition={selectedRegressionTestData.name}>
                <Alert
                  style={{ marginBottom: ".5rem" }}
                  message={`Showing config data for test - ${selectedRegressionTestData.name}`}
                  type="info"
                  showIcon
                />
              </ShouldRender>
            </div>
          </Row>
          <Row>
            <MonacoEditor
              height="400"
              language="javascript"
              theme="vs-dark"
              value={shiftActionsYAMLData}
              options={options}
              editorDidMount={editorDidMount}
              onChange={(value) => {
                setShiftActionsYAMLData(value);
              }}
            />
          </Row>

          <Row justify="end" align="middle" style={{ marginTop: "1rem" }}>
            <Button type="primary" htmlType="submit" loading={loading}>
              Submit
            </Button>
          </Row>
        </Form>
      </Row>
    </div>
  );
}

export default ShiftAction;
