import { UseModalState } from "@clipboard-health/ui-react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
} from "@mui/material";
import { format } from "date-fns";
import { ShiftInviteInfo } from "../ShiftInviteReview";

type MultiShiftInviteErrorDialogProps = {
  modalState: UseModalState;
  failedInvites: ShiftInviteInfo[];
  onClose: () => void;
};

export function MultiShiftInviteErrorDialog(props: MultiShiftInviteErrorDialogProps) {
  const { modalState, failedInvites, onClose } = props;
  return (
    <Dialog
      open={modalState.modalIsOpen}
      onClose={() => modalState.closeModal()}
      aria-labelledby="shift--multi-invite-error-dialog-title"
    >
      <DialogTitle>Some invitations could not be sent.</DialogTitle>
      <DialogContent>
        These invites could not be sent, please try again.
        <List>
          {failedInvites.map((invite) => (
            <ListItem key={`${invite.start.getTime()}-${invite.end.getTime()}`}>
              {format(invite.start, "MM/dd/yy h:mm a")} to {format(invite.end, "h:mm a")}
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => onClose()}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
