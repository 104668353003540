import { showErrorToast } from "@src/appV2/lib/Notifications";
import { ResponseError } from "superagent";

export const getErrorMessage = (error, msg = "request failed") => {
  if (error.response?.body?.message) {
    return error.response.body.message;
  } else if (error.response?.text) {
    return error.response.text;
  } else if (error.message) {
    return error.message;
  }

  return msg;
};

export const handleApiError = (error: unknown) => {
  showErrorToast(getErrorMessage(error));
};

export const parseObjectErrorResponse = (error: string | Record<string, unknown>) => {
  if (typeof error === "object") {
    return error;
  }

  try {
    return JSON.parse(error);
  } catch (e) {
    return {};
  }
};

export function isRequestErrorResponse(error: unknown): error is ResponseError {
  return error instanceof Error && "response" in error && "status" in error;
}
