import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { Visit, visitResponseSchema } from "../types";
import { isDefined } from "@clipboard-health/util-ts";
import { get } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { useCurrentUserId } from "../../lib/useCurrentUserId";

const VISIT_URL = `${environmentConfig.REACT_APP_HOME_HEALTH_API_URL}/v1/:workplaceId/visits/:visitId`;

export function getVisitQueryKey(params: { workplaceId: string; visitId?: string }) {
  return ["home-health", params.workplaceId, "visits", params.visitId];
}

export function useVisit(visitId?: string, options: UseQueryOptions<Visit, unknown, Visit> = {}) {
  const workplaceId = useCurrentUserId() ?? "";

  return useQuery({
    queryKey: getVisitQueryKey({ workplaceId, visitId }),
    queryFn: async () => {
      const response = await get({
        url: VISIT_URL.replace(":workplaceId", workplaceId).replace(":visitId", visitId!),
        responseSchema: visitResponseSchema,
      });

      return response.data.data;
    },
    retry: 0,
    staleTime: 1000 * 60 * 5,
    refetchInterval: 1000 * 60 * 10,
    enabled: isDefined(visitId),
    ...options,
  });
}
