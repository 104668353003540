import { QuizAttemptsDocument } from "@src/appV2/FacilityRules/types";
import { LicenseDetails } from "./license.types";
import { WorkerDocument } from "./workerDocument.types";

export enum DocumentType {
  DOCUMENT = "DOCUMENT",
  LICENSE = "LICENSE",
  QUIZ_ATTEMPTS = "QUIZ_ATTEMPTS",
}

export enum DownloadRequestStatus {
  PENDING = "PENDING",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
}

export enum PageActions {
  VIEW = "view",
  DOWNLOAD = "download",
  PRINT = "print",
  DOWNLOAD_SELECTED = "downloadSelected",
  PRINT_SELECTED = "printSelected",
  VIEW_NEXT = "viewNext",
  VIEW_PREV = "viewPrev",
}

export enum ViewType {
  LIST = "list",
}

export enum DownloadResultType {
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
}

export interface DocumentWithId {
  document: WorkerDocument | LicenseDetails | QuizAttemptsDocument;
  documentId: string;
  documentType: DocumentType;
}

export interface DocumentPrefetchedData {
  distributionUrl?: string;
  createdAt: number;
  inMemoryObjectUrl?: string;
  isValid: boolean;
}
