import React, { useState, useEffect } from "react";
import { Form, Row, Button, PageHeader, Divider, Typography, Alert } from "antd";
import axios from "axios";

import "../css/shift_action.css";
import { HCP_CLOCK_OUT_DEFAULT_YAML_VALUE } from "../constants/hcpClockOut";
import MonacoEditor from "react-monaco-editor";
import yaml from "yaml";
import ShouldRender from "./ShouldRender";
import { environmentConfig } from "@src/appV2/environment";
import { showErrorToast, showSuccessToast } from "@src/appV2/lib/Notifications";

const { Title } = Typography;

function HCPClockOut(props: any) {
  const { selectedRegressionTestData = {} } = props;
  const [isGetData, setIsGetData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [hcpClockOutYAMLData, setHCPClockOutsYAMLData] = useState(
    HCP_CLOCK_OUT_DEFAULT_YAML_VALUE()
  );

  useEffect(() => {
    getShiftDetails();
  }, []);

  useEffect(() => {
    if (selectedRegressionTestData.config) {
      const { hcpClockOut: hcpClockOutData } = yaml.parse(selectedRegressionTestData.config);
      setHCPClockOutsYAMLData(yaml.stringify({ hcpClockOut: hcpClockOutData }));
    } else if (selectedRegressionTestData.id) {
      setHCPClockOutsYAMLData("hcpClockOut:\n");
    }
  }, [selectedRegressionTestData]);

  const getShiftIdFromPathname = () => {
    const path = "/home/myShifts/";
    const { pathname } = window.location;
    if (!window.location) {
      return "";
    }
    const shiftId = pathname.substring(path.length, pathname.length);
    if (!shiftId) {
      return "";
    }
    return shiftId;
  };

  const getShiftDetails = async () => {
    const shiftId = getShiftIdFromPathname();
    if (!shiftId) {
      return;
    }
    setIsGetData(true);
    try {
      const resp = await axios.get(
        `${
          environmentConfig.REACT_APP_API_MAIN_URL || ""
        }/api/v1/shifts/myShifts/details/${shiftId}`,
        {
          headers: {
            Authorization: localStorage.getItem("authToken"),
          },
        }
      );
      if (!resp.data?.response?.end) {
        showErrorToast(
          `cannot get Shift Detail with ID ${shiftId}, please insert shiftId and clockoutTime manually`
        );
        return;
      }
      const clockOutTime = resp.data.response.end;
      const newYaml = HCP_CLOCK_OUT_DEFAULT_YAML_VALUE({
        shiftId,
        actionTime: clockOutTime,
      });
      setHCPClockOutsYAMLData(newYaml);
    } catch (error) {
      console.error("Action Resp Error: ", error);
      showErrorToast(`Test panel Error ${(error as Error).message}`);
    } finally {
      setIsGetData(false);
    }
  };

  const editorDidMount = (editor, monaco) => {
    editor.focus();
  };

  const options = {
    selectOnLineNumbers: true,
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const onSubmit = async () => {
    setLoading(true);
    try {
      const { hcpClockOut } = yaml.parse(hcpClockOutYAMLData);
      if (!hcpClockOut.shiftId) {
        return;
      }
      // /v1/shifts/recordTime/ will post the current time
      // /testHelpers/shift/recordTime/ also has logTime() without calling  ShiftDomain.events.Shift_ClockedIn
      const resp = await axios.post(
        `${environmentConfig.REACT_APP_API_MAIN_URL || ""}/api/testHelpers/shift/recordTime/${
          hcpClockOut.shiftId
        }`,
        {
          stage: hcpClockOut.action,
          location: [73.0702553, 33.6717341],
          timecard: "",
          locationType: "LIVE",
          appType: "WEB",
          connectivityMode: "ONLINE",
          actionTime: hcpClockOut.actionTime,
        },
        {
          headers: {
            Authorization: localStorage.getItem("authToken"),
          },
        }
      );
      if (!resp.data?.success) {
        showErrorToast(`${hcpClockOut.shiftId} clockOut actions did not complete successfully`);
        return;
      }
      showSuccessToast(`${hcpClockOut.shiftId} clockOut action completed successfully`);
    } catch (error) {
      console.error("Action Resp Error: ", error);
      showErrorToast(`Test panel Error ${(error as Error).message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <PageHeader
        className="hcpClockOutPageHeader"
        title="HCP ClockOut"
        extra={[
          <Button
            key="Intro to Yaml"
            type="default"
            onClick={() => {
              window.open(`https://dev.to/paulasantamaria/introduction-to-yaml-125f`)?.focus();
            }}
          >
            Intro to YAML
          </Button>,
        ]}
      />
      <Divider className="hcpClockOutHeaderDivider" />
      <Row justify="start" align="middle" style={{ width: "100%", height: "100%" }}>
        <Form {...layout} name="basic" form={form} onFinish={onSubmit} style={{ width: "600px" }}>
          <Row>
            <Title level={4}>YAML Config</Title>
            <div>
              <ShouldRender condition={selectedRegressionTestData.name}>
                <Alert
                  style={{ marginBottom: ".5rem" }}
                  message={`Showing config data for test - ${selectedRegressionTestData.name}`}
                  type="info"
                  showIcon
                />
              </ShouldRender>
            </div>
          </Row>
          <Row>
            {isGetData ? (
              <div>Getting required data...</div>
            ) : (
              <MonacoEditor
                height="400"
                language="javascript"
                theme="vs-dark"
                value={hcpClockOutYAMLData}
                options={options}
                editorDidMount={editorDidMount}
                onChange={(value) => {
                  setHCPClockOutsYAMLData(value);
                }}
              />
            )}
          </Row>

          <Row justify="end" align="middle" style={{ marginTop: "1rem" }}>
            <Button type="primary" htmlType="submit" loading={loading}>
              Submit
            </Button>
          </Row>
        </Form>
      </Row>
    </div>
  );
}

export default HCPClockOut;
