import { environmentConfig } from "@src/appV2/environment";
import { useEffect, useRef } from "react";

export default function useWarmly() {
  const scriptUrl = environmentConfig.REACT_APP_WARMLY_SCRIPT_URL;
  const scriptRef = useRef<HTMLScriptElement | null>();

  useEffect(() => {
    if (!scriptUrl || scriptRef.current) {
      return;
    }

    const script = document.createElement("script");
    script.id = "warmly-script-loader";
    script.src = scriptUrl;
    script.defer = true;

    scriptRef.current = script;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
      scriptRef.current = null;
    };
  }, [scriptUrl]);
}
