import { Button } from "src/designsystem/Button/Button";
import { Rate } from "src/designsystem/Rate/Rate";
import styled from "styled-components";

export const Worker = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 112px minmax(0, 1fr);
  background: white;
  border: 1px solid #33333324;
  border-radius: 8px;
  padding: 12px 8px 12px 4px;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  color: #333;
  transition: box-shadow 0.25s;
  cursor: pointer;
  gap: 16px;
  &:hover {
    box-shadow: 1px 3px 6px -1px rgba(0, 0, 0, 0.15);
  }
`;

export const WorkerAvatar = styled.div<{ blur?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;

  ${({ blur }) =>
    blur
      ? `
  .ant-avatar img {
    filter: blur(1rem);
  }
  `
      : undefined}
`;

export const WorkerRatingSection = styled.button`
  display: flex;
  align-items: end;
  gap: 6px;
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

export const WorkerRate = styled(Rate)`
  font-size: 16px;
`;

export const List = styled.ul`
  margin: 0;
  padding: 0;
  padding-inline-start: 16px;
`;

export const WorkerActions = styled.div`
  display: flex;
  gap: 4px;
`;

export const ButtonIcon = styled(Button)<{ variant: "primary" | "secondary" }>`
  border: 1px solid rgba(51, 51, 51, 0.3);
  border-radius: 100%;
  padding: 6px;
  display: flex;
`;

export const QualificationText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const WorkerName = styled.div`
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
