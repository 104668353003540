export enum ExtraWorkedTimeRequestLiterals {
  TYPE = "ExtraWorkedTimeRequest",
}

export enum ExtraWorkedTimeType {
  BREAK = "BREAK",
}

export enum ExtraWorkedTimeRequestStatus {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  DECLINED = "DECLINED",
  FAILED = "FAILED",
}

export enum ExtraWorkedTimeRequestStatusUpdater {
  SYSTEM = "SYSTEM",
  FACILITY_USER = "FACILITY_USER",
  CBH_AGENT = "CBH_AGENT",
}

export enum ExtraWorkedTimeRequestPayer {
  CBH = "CBH",
  HCF = "HCF",
}

export enum IncludedForExtraWorkedTimeRequestType {
  SHIFT = "shift",
  FACILITY_PROFILE = "facilityProfile",
  FACILITY_USER = "facilityUser",
}

export interface ExtraWorkedTimeRequest {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  _id: string;
  type: ExtraWorkedTimeType;
  reason: string;
  start: string;
  end: string;
  status: ExtraWorkedTimeRequestStatus;
  createdAt: string;
  billedTo?: ExtraWorkedTimeRequestPayer;

  statusUpdatedBy?: ExtraWorkedTimeRequestStatusUpdater | null;
  statusUpdatedById?: string;
  statusUpdateReason?: string;
  updatedAt?: string;
}
