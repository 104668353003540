export function DebugPage() {
  return (
    <div>
      <h1>🇩🐛 Debug Page</h1>

      <section>
        <table width="50%">
          <tbody>
            <tr>
              <td>App version</td>
              <td>
                <b>{__APP_VERSION__}</b>
              </td>
            </tr>
            <tr>
              <td>Commit Hash</td>
              <td>
                <b>{__COMMIT_HASH__}</b>
              </td>
            </tr>
            <tr>
              <td>REACT_APP_ENVIRONMENT_NAME</td>
              <td>
                <b>{process.env.REACT_APP_ENVIRONMENT_NAME}</b>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
    </div>
  );
}
