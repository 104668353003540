import {
  addHours,
  addMinutes,
  differenceInMinutes,
  isBefore,
  setDate,
  setHours,
  setMinutes,
  setMonth,
  setYear,
  startOfMinute,
} from "date-fns";
import { DefaultShiftTimes } from "src/containers/facilityDashboard/ShiftCalendar/api";

export function getFlooredHourMinuteDuration(minutes: number) {
  return {
    hours: Math.floor(minutes / 60),
    minutes: minutes % 60,
  };
}

interface ShiftTime {
  startTime: Date;
  endTime: Date;
}

interface ConvertShiftTimeToDateTimeProps {
  shiftTime: DefaultShiftTimes[keyof DefaultShiftTimes];
  startDate?: Date;
}

export function convertShiftTimeToDateTime(shiftTimes: ConvertShiftTimeToDateTimeProps): ShiftTime {
  const { shiftTime, startDate = startOfMinute(new Date()) } = shiftTimes;
  const startTime = setMinutes(setHours(startDate, shiftTime.start.hour), shiftTime.start.minute);
  const endTime = addMinutes(
    addHours(startTime, shiftTime.duration.hours),
    shiftTime.duration.minutes
  );

  return { startTime, endTime };
}

/**
 * Converts date time to shift start time and duration.
 * The time and duration are granular to the minute, which matches shift time granularity.
 */
export function convertDateTimeToShiftTime(startTime: Date, endTime: Date) {
  const normalizedEndTime = normalizeShiftEndTime(startTime, endTime);
  const minutes = differenceInMinutes(normalizedEndTime, startTime);

  return {
    start: {
      hour: startTime.getHours(),
      minute: startTime.getMinutes(),
    },
    duration: getFlooredHourMinuteDuration(minutes),
  };
}

export function normalizeShiftEndTime(startTime: Date, endTime: Date) {
  let normalizedEndTime = setDate(
    setMonth(setYear(endTime, startTime.getFullYear()), startTime.getMonth()),
    startTime.getDate()
  );
  if (isBefore(normalizedEndTime, startTime)) {
    normalizedEndTime = addHours(normalizedEndTime, 24);
  }
  return normalizedEndTime;
}
