import { datadogRum } from "@datadog/browser-rum";

export interface LogErrorProps {
  // try catch errors are `unknown` type.
  error: unknown;
  metadata?: Record<string, unknown>;
}
export interface LogEventProps {
  eventKey: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  properties?: Record<string, any>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type EventLogger = (eventKey: string, properties?: Record<string, any>) => void;
export type ErrorLogger = (eventName: string, params: LogErrorProps) => void;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function logEvent(eventKey: string, properties?: Record<string, any>): void {
  datadogRum.addAction(eventKey, properties);
  // FIXME: Segment Analytics is attached to global Window rather than accessible via import
  // Use CbhSegment from "@clipboard-health/cbh-segment";
  window.analytics?.track(eventKey, properties);
}

export function logError(eventName: string, params: LogErrorProps): void {
  const { error: unknownError, metadata } = params;
  const isError = unknownError instanceof Error;
  const error = isError ? unknownError : String(unknownError);
  datadogRum.addError(error, { eventName, ...metadata });
}
