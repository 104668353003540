import { ExclamationCircleFilled, HeartTwoTone } from "@ant-design/icons";
import { Avatar, Input, Spin, Tabs } from "antd";
import { Rate } from "src/designsystem/Rate/Rate";
import styled from "styled-components";

export const Wrapper = styled.div`
  & + main {
    display: none !important;
  }
`;

export const WorkerTabs = styled(Tabs)`
  .ant-tabs-nav:before {
    border-bottom: 2px solid #e5e5e5;
  }

  .ant-tabs-tab {
    padding: 12px 20px;
    font-size: 17px;
    color: #868686;
  }

  .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #032e68;
    text-shadow: none;
  }

  .ant-tabs-ink-bar {
    background-color: #032e68;
  }
`;

export const SearchField = styled(Input)`
  border-radius: 8px;
  background-color: rgba(160, 160, 160, 0.15);
  width: 100%;
  margin: 16px 0;

  .ant-input-prefix {
    color: rgba(134, 134, 134, 0.8);
    padding-left: 4px;
    font-size: 20px;
  }

  .ant-input.ant-input {
    flex: 1;
    max-width: unset;
    background: transparent;
    padding-left: 8px;
  }

  input::placeholder {
    color: #868686;
  }
`;

export const WorkerDetails = styled.div`
  margin-left: 8px;
`;

export const WorkerName = styled.div`
  color: #032e68;
  font-size: 15px;
  margin-bottom: 8px;
  font-weight: bold;
  display: flex;
  align-items: center;
`;

export const WorkerRow = styled.div<{ header?: boolean }>`
  display: flex;
  flex-direction: row;
  padding: 22px;

  ${({ header }) =>
    !header
      ? `
	background-color: rgba(255, 255, 255, 0.4);
  border: 1px solid #e5e5e5;
  border-radius: 12px;
  margin-bottom: 20px;
  cursor: pointer;
  box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.1);
	`
      : `
	font-size: 15px;
`}
  gap: 20px;
  align-items: center;
  color: #424242;
  transition: box-shadow 0.25s;

  &:hover {
    ${({ header }) =>
      !header
        ? `
    box-shadow: 0 3px 4px -1px rgba(0, 0, 0, 0.1);
  `
        : ``}

    ${WorkerName} {
      text-decoration: underline;
    }
  }
`;

export const Column = styled.div<{
  flex?: number | "none";
  width?: number;
  height?: number;
  align?: string;
}>`
  flex: ${({ flex }) => flex ?? 1};
  ${({ width }) => (width ? `width: ${width}px;` : undefined)}
  ${({ height }) => (height ? `height: ${height}px;` : undefined)}
  position: relative;
  ${({ align }) => (align ? `text-align: ${align}` : undefined)}
`;

export const WorkerProfileAvatar = styled(Avatar)`
  border-radius: 48px;
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 16px;
`;

export const WorkerRate = styled(Rate)`
  font-size: 20px;
`;

export const WorkerLicense = styled.div`
  color: #032e68;
  font-size: 14px;
  & p {
    margin-bottom: 0px;
    line-height: 16.71px;
  }
`;

export const WorkerActions = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 15px;
`;

export const WorkerIcon = styled.span`
  font-size: 20px;
  vertical-align: middle;
  margin-right: 8px;
`;

export const WorkerActionBlock = styled.div`
  color: #ca4031;
`;

export const WorkerProfileFavorite = styled(HeartTwoTone)`
  position: absolute;
  right: 0px;
  bottom: 4px;
  font-size: 24px;

  svg {
    path:nth-child(1) {
      fill: white;
    }

    path:nth-child(2) {
      fill: #a10702;
    }
  }
`;

export const ProfileCancelMarkIcon = styled(ExclamationCircleFilled)`
  position: absolute;
  right: 0px;
  bottom: 4px;
  font-size: 26px;

  svg {
    background-color: white;
    padding: 2px;
    border-radius: 50%;
    path:nth-child(1) {
      fill: #a10702;
    }
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const LoadingIcon = styled(Spin)`
  font-size: 24px;
  margin-left: 24px;
  color: #032e68;
`;

export const WorkerBlock = styled.div<{
  isBlock?: boolean;
  disabled?: boolean;
}>`
  color: ${({ isBlock }) => (isBlock ? "red" : "inherit")};
  transition: opacity 0.2s linear;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

  &:hover {
    opacity: 0.5;
  }
`;

export const FacilityCancelledCount = styled.div<{
  hasRecentCancellation?: boolean;
}>`
  color: ${({ hasRecentCancellation }) => (hasRecentCancellation ? "#a10702" : "inherit")};
`;

export const AttendanceRate = styled.div`
  &:hover {
    text-decoration: underline;
  }
`;
