import { Text, Title } from "@clipboard-health/ui-react";
import { Container, Paper, Stack } from "@mui/material";
import { Link } from "react-router-dom";

export function NotFoundPage() {
  return (
    <Container
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 2,
        textAlign: "center",
      }}
    >
      <Paper
        sx={{
          padding: 4,
          borderRadius: 2,
        }}
      >
        <Stack spacing={2}>
          <Title component="h1" align="center">
            Page Not Found.
          </Title>
          <Text variant="body1">We&apos;re sorry. This page does not exist.</Text>
          <Text variant="body1">
            <Link to="/">Go to Home Page</Link>
          </Text>
        </Stack>
      </Paper>
    </Container>
  );
}
