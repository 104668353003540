import { type ReactElement } from "react";

import { AppV2PrivateRoutes } from "./PrivateRoutes";
import { AppV2PublicRoutes } from "./PublicRoutes";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { NotFoundPage } from "../lib";

export function AppV2Routes(): ReactElement {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.url}/home`}>
        <AppV2PrivateRoutes />
      </Route>
      <Route path={match.url}>
        <AppV2PublicRoutes />
      </Route>
      <Route path="*">
        <NotFoundPage />
      </Route>
    </Switch>
  );
}
