import { WorkerWithLastShift } from "src/api/workers";
import { ExclusionForList } from "src/api/workerReview";
import { Rating } from "./RatingPerformanceForm";
import {
  DnrAction,
  WorkerReviewMode,
  RatingPerformanceState,
} from "src/components/WorkerRatingPerformanceModal/workerReview.types";

export type WorkerWithRatingAndLastShift = {
  worker: { avatarUrl: string; name: string; userId: string };
  lastShift: WorkerWithLastShift["lastShift"];
  rating?: Rating;
};

export function convertToWorkerReviewState(
  rating: Rating,
  exclusion: ExclusionForList | undefined,
  mode: WorkerReviewMode,
  dnrAction: DnrAction | undefined
): RatingPerformanceState {
  return {
    rating: rating.rating,
    additionalFeedback: rating.additionalFeedback,
    dnrWorker: rating.dnrWorker,
    qualities: rating.qualities ?? [],
    exclusionId: exclusion?._id,
    workerReviewId: rating.id,
    shiftId: rating.shiftId,
    reviewerName: rating.lastEditedBy,
    updatedAt: rating.updatedAt,
    dnrFieldTouched: dnrAction !== undefined,
    mode,
  };
}
