import { BsExclamationTriangleFill, BsInfoCircleFill } from "react-icons/bs";
import { Link } from "../Link/Link";
import { primitives } from "../primitives";
import { Action, BannerContent, BannerIcon, BannerInfo, Wrapper } from "./Banner.styles";
import { BannerProps } from "./Banner.type";

function getBannerIcon(type: BannerProps["type"]) {
  switch (type) {
    case "info":
      return <BsInfoCircleFill color="#032e68" />;
    case "warning":
      return <BsExclamationTriangleFill color={primitives.colors.red[700]} />;
    default:
      return null;
  }
}

export function Banner({
  closable,
  action,
  title,
  description,
  type,
  onClose,
  visible = true,
  ...props
}: BannerProps): JSX.Element {
  if (!visible) {
    return <></>;
  }

  return (
    <Wrapper type={type} {...props}>
      <BannerInfo>
        <BannerIcon>{getBannerIcon(type)}</BannerIcon>
        <BannerContent>
          <div>{title}</div>
          <div>{description}</div>
        </BannerContent>
      </BannerInfo>
      <Action>
        {action && <>{action}</>}
        {closable && (
          <Link href="#" onClick={onClose} underline={false} strong>
            Dismiss
          </Link>
        )}
      </Action>
    </Wrapper>
  );
}

Banner.defaultProps = {
  visible: true,
};
