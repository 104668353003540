import { Divider, Modal } from "antd";
import styled from "styled-components";

export const Wrapper = styled.div`
  margin-top: -64px;
`;

export const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 8px;
  }
`;

export const StyledDivider = styled(Divider)`
  margin: 5px 0;
`;
