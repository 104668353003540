import { InfiniteData, useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { LONG_STALE_TIME } from "src/constants/query";
import { GetWorkersDirectoryResponse, fetchHighRatingWorkers } from "src/api/workers";
import { FacilityOnboardingStep } from "src/api/facility";
import { HCF_USER_EVENTS } from "src/constants/firebaseEvents";
import { logEvent } from "@src/appV2/lib/analytics";
import { WORKER_DIRECTORY } from "./WorkersDirectory";

const DEFAULT_PAGE_SIZE = 20;

export function useHighRatingWorkers(
  workplaceId: string,
  filters: Partial<{
    searchTerm: string;
    workerType?: string[];
    sortBy: "distance" | "name";
    sortOrder: "asc" | "desc";
    pageSize: number;
  }>,
  isAdmin: boolean,
  trackingData?: Partial<{
    workplaceOnboardingSteps: Array<"favorites" | "shifts">;
    postShiftOptionSteps: Array<"block-shifts" | "per-diem">;
    workplaceUserId: string;
    onboardingState: FacilityOnboardingStep;
  }>,
  opts?: {
    onSuccess: (data: InfiniteData<GetWorkersDirectoryResponse>) => void;
  }
) {
  const queryClient = useQueryClient();
  const queryKey = ["high-rating-workers", workplaceId.toString(), JSON.stringify(filters)];

  const {
    isLoading: highRatingWorkersLoading,
    data: highRatingWorkers,
    isError: highRatingWorkersError,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey,
    queryFn: ({ pageParam = 1 }) => {
      if (pageParam > 1 && !isAdmin) {
        logEvent(HCF_USER_EVENTS.WORKER_DIRECTORY, {
          ...trackingData,
          workplaceId,
          action: WORKER_DIRECTORY.LOAD_MORE_WORKERS,
          searchTerm: filters.searchTerm,
        });
      }
      return fetchHighRatingWorkers({
        workplaceId,
        pageNumber: pageParam,
        pageSize: filters.pageSize ?? DEFAULT_PAGE_SIZE,
        ...filters,
      });
    },
    enabled: !!workplaceId,
    getNextPageParam: (lastPage) =>
      lastPage.workers.length >= 20 ? lastPage.pageNumber + 1 : undefined,
    staleTime: LONG_STALE_TIME,
    onSuccess: opts?.onSuccess,
  });

  const invalidateCache = () => {
    queryClient.removeQueries(queryKey);
  };

  return {
    data: highRatingWorkers?.pages.flatMap((page) => page.workers) ?? [],
    isLoading: highRatingWorkersLoading,
    isError: highRatingWorkersError,
    hasNextPage,
    fetchNextPage,
    invalidateCache,
  };
}
