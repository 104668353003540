import styled from "styled-components";
import { SearchOutlined } from "@ant-design/icons";

const FilterIcon = styled<any>(SearchOutlined)`
  color: ${(props) => (props.styled.filtered ? "#1890ff !important" : "#bfbfbf")};
  font-size: 12px;
  cursor: pointer;
  transition: all 0.3s;
  width: 28px;
  text-align: center;
`;

export { FilterIcon };
