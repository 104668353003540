import { Chip, Button, CardActions } from "@mui/material";
import { isDefined } from "@clipboard-health/util-ts";
import { useDeleteShiftInvite } from "@src/appV2/ShiftInvites/api/useDeleteShiftInvite";
import { CbhCustomChatMessage } from "./CbhCustomChatMessage";
import { RenderCustomMessageProps } from "./type";
import { ShiftInviteStatus } from "../ShiftInvites/type";
import { parseShiftInviteChatMessage } from "./utils";

const shiftInviteStatusToColor: Record<string, "default" | "error" | "success"> = {
  EXPIRED: "default",
  ACCEPTED: "success",
  DECLINED: "error",
};

export function ShiftInviteRequestMessage(props: RenderCustomMessageProps) {
  const { shiftInviteId, status, facilityMessage } = parseShiftInviteChatMessage(props);
  const { mutateAsync: deleteShiftInviteMutate, isLoading: isDeletingShiftInvite } =
    useDeleteShiftInvite();

  if (!isDefined(facilityMessage) || status === ShiftInviteStatus.DELETED) {
    return <></>;
  }

  return (
    <CbhCustomChatMessage message={facilityMessage}>
      <CardActions>
        {status === ShiftInviteStatus.PENDING && isDefined(shiftInviteId) && (
          <Button
            size="small"
            variant="contained"
            sx={{ width: "100%" }}
            onClick={async () => {
              await deleteShiftInviteMutate(shiftInviteId);
            }}
            disabled={isDeletingShiftInvite}
          >
            Remove Invite
          </Button>
        )}
        {isDefined(status) && status !== ShiftInviteStatus.PENDING && (
          <Chip
            label={status.toUpperCase()}
            sx={{ width: "100%" }}
            color={shiftInviteStatusToColor[status.toUpperCase()]}
            variant="outlined"
          />
        )}
      </CardActions>
    </CbhCustomChatMessage>
  );
}
