import { Avatar } from "antd";
import styled from "styled-components";
import { Rate } from "src/designsystem/Rate/Rate";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
`;

export const WorkerInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
`;

export const WorkerAvatar = styled(Avatar)`
  border-radius: 100%;
	width: 96px;
	height 96px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 18px;
`;

export const Title = styled.div<{ bold?: boolean }>`
  font-size: 18px;
  text-align: center;
  ${({ bold }) => (bold ? `font-weight: 500;` : undefined)}
`;

export const Label = styled.div`
  font-size: 16px;
  margin-bottom: 8px;
`;

export const WorkerRating = styled(Rate)`
  font-size: 32px;
  color: #fbb641;
`;
