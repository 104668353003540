import { Link, type LinkProps } from "@mui/material";
import { forwardRef } from "react";
import { type LinkProps as RouterLinkProps } from "react-router-dom";

import { BaseLink } from "../Link";

export interface InternalLinkProps extends Omit<LinkProps, "href"> {
  to: RouterLinkProps["to"];
}

export const InternalLink = forwardRef<HTMLAnchorElement, InternalLinkProps>((props, ref) => {
  return <Link ref={ref} component={BaseLink} {...props} />;
});

InternalLink.displayName = "InternalLink";
