import moment from "moment-timezone";
import { GroupChannel } from "@sendbird/chat/groupChannel";

export function getLastMessageDate(channel: GroupChannel) {
  const useTimestamp = channel.lastMessage ? channel.lastMessage.createdAt : channel.createdAt;
  const lastMessage = moment(useTimestamp);

  if (moment().add(-1, "day").startOf("day").isAfter(lastMessage)) {
    return lastMessage.format("MM/DD/YYYY");
  }
  if (moment().startOf("day").isAfter(lastMessage)) {
    return "Yesterday";
  }

  return lastMessage.format("hh:mm A");
}
