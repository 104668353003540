import { createHttpLink } from "@apollo/client";
import { mockSingleLink } from "@apollo/client/testing";
import { setContext } from "@apollo/client/link/context";
import axios from "axios";
import { defaultEmail } from "../constants";
import fetch from "cross-fetch";
import { environmentConfig, isTestNodeEnvironment } from "@src/appV2/environment";

const httpLink =
  // HACK - using the actual createHttpLink() makes Jest tests fail
  isTestNodeEnvironment()
    ? mockSingleLink()
    : createHttpLink({
        uri: environmentConfig.REACT_APP_DOCUMENTS_SERVICE_URL,
        fetch,
      });

const authLink = setContext(async () => {
  const getToken = await axios.post(
    `${environmentConfig.REACT_APP_BASE_API_URL}/testHelpers/token?email=${defaultEmail}`
  );
  return {
    headers: {
      Authorization: getToken.data.token,
    },
  };
});

const link = authLink.concat(httpLink);

export { link };
