import styled from "styled-components";
import { primitives } from "../primitives";

export const UnknownPropsContainer = styled.div`
  border: 1px solid ${primitives.colors.red[700]};
  border-radius: 4px;
  padding: 8px;
  background-color: ${primitives.colors.red[100]};
  color: ${primitives.colors.red[700]};
`;
export const UnknownPropsList = styled.ul`
  margin: 0;
  padding: 0 20px;
`;
