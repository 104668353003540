import { Text } from "@clipboard-health/ui-react";
import { Box, Card, CardContent, Stack } from "@mui/material";
import { type ReactNode } from "react";

interface CbhCustomChatMessageProps {
  message: string;
  children?: ReactNode;
}

export function CbhCustomChatMessage(props: CbhCustomChatMessageProps) {
  const { message, children } = props;

  return (
    <Stack direction="row" spacing={1} marginBottom={2}>
      <Box sx={{ display: "flex", justifyContent: "end", flexDirection: "column" }}>
        <img src="/bunnyB.png" alt="" width={32} height={32} />
      </Box>
      <Stack>
        <Text>Clipboard Health</Text>
        <Card>
          <CardContent sx={{ paddingBottom: "0px" }}>
            <Text variant="body2">{message}</Text>
          </CardContent>
          {children}
        </Card>
      </Stack>
    </Stack>
  );
}
