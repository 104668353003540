const HCPDefaultYAMLValue = `hcp:
  firstName: haileyesus
  lastName: zemed
  phone: 2025550194
  email: haileyesuszemed@gmail.com
  qualification: CNA
  rate: 40.5
  locations: 
    - 5bcf686f4b89fe00040536c8
  tmz: America/Los_Angeles
  geoLocation:
    coordinates: 
      - -120.969572
      - 37.6514023
    type: "Point"
  license:
    number: 898989
    state: "California"
    expires: "2022-11-12"
  address:
    streetNumber: "400"
    streetName: "Oak Street"
    city: "San Francisco"
    region: "San Francisco County"
    state: "California"
    stateCode: "CA"
    country: "United States"
    countryCode: "US"
    postalCode: "94102"
    formatted: "400 Oak St, San Francisco, CA 94102, USA"
    metropolitanStatisticalArea: "San Francisco-Oakland-Fremont, CA"
  noCallNoShow: 0`;

export { HCPDefaultYAMLValue };
