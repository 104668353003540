import { CircularProgress } from "@mui/material";
import { Suspense, lazy } from "react";
import { useIsHomeHealthEnabled } from "../lib/useIsHomeHealthEnabled";
import { useCurrentUserId } from "../lib/useCurrentUserId";
import { isDefined } from "@clipboard-health/util-ts";
import { Redirect } from "react-router-dom";
import { HOME_HEALTH_VISIT_VERIFICATION_PATH } from "./paths";

const VisitVerificationPage = lazy(() =>
  import("./VisitVerificationPage").then((module) => ({
    default: module.VisitVerificationPage,
  }))
);

export function HomeHealthProtectedVisitVerificationPage() {
  const currentUserId = useCurrentUserId();
  const isLoggedIn = isDefined(currentUserId);
  const isHomeHealthEnabled = useIsHomeHealthEnabled();

  if (!isLoggedIn) {
    localStorage.setItem("redirectURL", `/v2/home/${HOME_HEALTH_VISIT_VERIFICATION_PATH}`);
    return <Redirect to={`/enter?requestedUrl=v2/home/${HOME_HEALTH_VISIT_VERIFICATION_PATH}`} />;
  }
  if (!isHomeHealthEnabled) {
    localStorage.removeItem("redirectURL");
    return <Redirect to="/" />;
  }
  return (
    <Suspense fallback={<CircularProgress />}>
      <VisitVerificationPage />
    </Suspense>
  );
}
