import { useMutation } from "@tanstack/react-query";
import { SHIFT_INVITES_VALIDATION_URL } from "@src/appV2/ShiftInvites/constants";
import { post } from "@src/appV2/api";
import {
  ShiftInviteRequestParams,
  shiftInviteRequestSchema,
  shiftInviteValidationResponseSchema,
} from "../type";

export function useValidateShiftInvite() {
  return useMutation({
    mutationFn: async (invite: ShiftInviteRequestParams) => {
      const { shiftInviteRequest } = invite;
      const response = post({
        url: SHIFT_INVITES_VALIDATION_URL,
        data: {
          data: {
            type: "shiftInvite",
            attributes: shiftInviteRequest,
          },
        },
        requestSchema: shiftInviteRequestSchema,
        responseSchema: shiftInviteValidationResponseSchema,
      });
      return response;
    },
  });
}
