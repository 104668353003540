import constate from "constate";
import { useState } from "react";
import { GroupShiftInvite, Severity, ShiftInviteInfo } from "../ShiftInviteReview";
import { useShiftTimes } from "@src/containers/postShifts/hooks/useShiftTimes";
import { SessionType } from "@src/modules/interface";
import { useSelector } from "react-redux";
import { DEFAULT_TIMEZONE } from "@src/constants/timezone";
import { SelectedShiftInfo } from "../ShiftTypeTimeSelection";
import { isDefined } from "@clipboard-health/util-ts";
import { getDocsNames, getShiftInfoKey, generateDocumentListText } from "../utils";
import { useValidateShiftInvite } from "@src/appV2/ShiftInvites/api/useValidateShiftInvite";
import { AxiosError } from "axios";
import { NON_BOOKABLE_CRITERIA } from "../constants";
import { RequirementMappingLevel } from "@src/constants/documents";
import { HCF_USER_EVENTS } from "@src/constants/firebaseEvents";
import { logEvent } from "src/appV2/lib/analytics";
import { NearbyWorkerSearchResult } from "../Autocomplete/useGetNearbyWorkers";
import { zonedTimeToUtc } from "date-fns-tz";
import { isValid } from "date-fns";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import { useCheckShiftInviteDetail } from "@src/appV2/ShiftInvites/api/useCheckShiftInviteDetail";
import { CheckShiftInviteDetail } from "@src/appV2/ShiftInvites/type";
interface UseShiftInviteParams {
  agentReq: string;
}

type MultipleShiftInfoKey = string;

interface ValidateShiftInfoParams {
  shiftInviteInfo: ShiftInviteInfo;
  selectedWorker: NearbyWorkerSearchResult | undefined;
}

interface ValidateAndUpdateShiftInviteParams {
  selectedShiftInfo: SelectedShiftInfo;
  selectedDate: Date | undefined;
  selectedWorker: NearbyWorkerSearchResult | undefined;
}

interface ShiftInviteValidationDetails {
  message: string;
  level: Severity;
  missingDocuments?: string[];
  isOverworkingShift?: boolean;
  tooltip?: string;
}

function useShiftInvite(params: UseShiftInviteParams) {
  const { agentReq } = params;
  const { profile } = useSelector((state: SessionType) => state.session);
  const workplaceId = profile?.userId;
  const facilityTimeZone = profile?.tmz ?? DEFAULT_TIMEZONE;
  const { data: shiftTimes } = useShiftTimes(workplaceId);
  const [customChargeRateBoost, setCustomChargeRateBoost] = useState<number>();

  const isShiftInviteFacilityDocumentOverrideEnabled = useCbhFlag(
    CbhFeatureFlag.OVERRIDE_FACILITY_REQUIRED_DOCS_FOR_SHIFT_INVITE,
    { defaultValue: true }
  );
  const postShiftToMarketplaceDefaultValue = useCbhFlag(
    CbhFeatureFlag.SHIFT_INVITE_POST_TO_MARKETPLACE_DEFAULT,
    { defaultValue: true }
  );
  const [groupInviteSharedData, setGroupInviteSharedData] = useState<GroupShiftInvite>({
    workplaceId,
    agentReq,
    tmz: facilityTimeZone,
    postShiftToMarketplace: postShiftToMarketplaceDefaultValue,
  });
  const [multipleShiftInfo, setMultipleShiftInfo] = useState<
    Map<MultipleShiftInfoKey, ShiftInviteInfo>
  >(new Map<MultipleShiftInfoKey, ShiftInviteInfo>());
  const [multipleShiftValidationDetails, setMultipleShiftValidationDetails] = useState<
    Map<string, ShiftInviteValidationDetails>
  >(new Map<string, ShiftInviteValidationDetails>());
  const [multipleCheckShiftInviteDetails, setMultipleCheckShiftInviteDetails] = useState<
    Map<string, CheckShiftInviteDetail>
  >(new Map<string, CheckShiftInviteDetail>());
  const { mutateAsync: validateShiftInviteAsync, isLoading: isValidatingShiftInfo } =
    useValidateShiftInvite();
  const { mutateAsync: checkShiftInviteDetailAsync } = useCheckShiftInviteDetail();
  const { user, admin } = useSelector((state: SessionType) => state.session);

  async function validateShiftInfo(validateShiftInfoParams: ValidateShiftInfoParams) {
    const { shiftInviteInfo, selectedWorker } = validateShiftInfoParams;
    try {
      await validateShiftInviteAsync({
        shiftInviteRequest: {
          workerId: selectedWorker?.workerId ?? "",
          workplaceId,
          shiftDetails: {
            start: zonedTimeToUtc(shiftInviteInfo.start, facilityTimeZone),
            end: zonedTimeToUtc(shiftInviteInfo.end, facilityTimeZone),
            agentReq: groupInviteSharedData.agentReq,
          },
          postShiftToMarketplace: groupInviteSharedData.postShiftToMarketplace,
        },
      });
    } catch (error: unknown) {
      logEvent(HCF_USER_EVENTS.SHIFT_INVITE, {
        action: "VALIDATION_ERROR",
        ...{
          workplaceId,
          workerType: agentReq,
          ...(admin ? { adminUserId: user?._id } : { workplaceUserId: user?._id }),
          ...(selectedWorker ?? {}),
        },
      });
      if (error instanceof AxiosError) {
        const errorData = error.response?.data.errors?.[0];
        const unmetCriteria = errorData?.reasons;
        const missingDocuments = errorData?.missingDocuments;
        const errorMessage = errorData?.detail ?? "Invalid Shift Detail";
        if (unmetCriteria?.includes(NON_BOOKABLE_CRITERIA.WORKER_MISSING_REQUIREMENTS)) {
          const documentNames = getDocsNames(missingDocuments);
          // Error for any missing documents if the facility cannot override document requirements
          if (!isShiftInviteFacilityDocumentOverrideEnabled) {
            return {
              message: "Worker is missing required documents",
              tooltip: generateDocumentListText({ documents: documentNames }),
              level: Severity.ERROR,
              missingDocuments: documentNames,
            };
          }

          const missingStateOrClipboardDocs = missingDocuments?.filter(
            (document) => document.level !== RequirementMappingLevel.HCF
          );
          // Error if there are any missing state or Clipboard Health documents
          if (missingStateOrClipboardDocs?.length > 0) {
            const stateOrClipboardDocumentNames = getDocsNames(missingStateOrClipboardDocs);
            return {
              message: "Worker is missing state or Clipboard requirements",
              tooltip: generateDocumentListText({
                documents: stateOrClipboardDocumentNames,
                requiredBy: "state or Clipboard Health",
              }),
              level: Severity.ERROR,
              missingDocuments: stateOrClipboardDocumentNames,
            };
          }

          // Warn if only facility documents are missing and the facility can override document requirements
          return {
            message: "Worker is missing facility requirements",
            tooltip: generateDocumentListText({ documents: documentNames, requiredBy: "facility" }),
            level: Severity.WARN,
            missingDocuments: documentNames,
          };
        }

        if (unmetCriteria?.includes(NON_BOOKABLE_CRITERIA.SHIFT_OVERWORKING)) {
          return {
            message: "Potential overwork",
            tooltip: "This shift may lead to work exceeding the recommended 17-hour limit",
            level: Severity.WARN,
            isOverworkingShift: true,
          };
        }

        return { message: errorMessage, level: Severity.ERROR };
      }
    }
  }

  async function checkShiftInviteDetail(checkShiftInviteDetailParams: ValidateShiftInfoParams) {
    const { shiftInviteInfo, selectedWorker } = checkShiftInviteDetailParams;
    try {
      return await checkShiftInviteDetailAsync({
        shiftInviteRequest: {
          workerId: selectedWorker?.workerId ?? "",
          workplaceId,
          shiftDetails: {
            start: zonedTimeToUtc(shiftInviteInfo.start, facilityTimeZone),
            end: zonedTimeToUtc(shiftInviteInfo.end, facilityTimeZone),
            agentReq: groupInviteSharedData.agentReq,
            customChargeRateBoost,
          },
          postShiftToMarketplace: groupInviteSharedData.postShiftToMarketplace,
        },
      });
    } catch (error: unknown) {
      logEvent(HCF_USER_EVENTS.SHIFT_INVITE, {
        action: "CHECK_SHIFT_DETAILS_ERROR",
        ...{
          workplaceId,
          workerType: agentReq,
          ...(admin ? { adminUserId: user?._id } : { workplaceUserId: user?._id }),
          ...(selectedWorker ?? {}),
        },
      });
      return undefined;
    }
  }

  const validateAndUpdateShiftInvite = async (
    validateAndUpdateShiftInviteParams: ValidateAndUpdateShiftInviteParams
  ) => {
    const { selectedShiftInfo, selectedDate, selectedWorker } = validateAndUpdateShiftInviteParams;
    const { shiftType, start, end, isSelected } = selectedShiftInfo;
    if (
      !isDefined(start) ||
      !isDefined(end) ||
      !isDefined(selectedDate) ||
      !isValid(start) ||
      !isValid(end) ||
      !isValid(selectedDate)
    ) {
      return;
    }
    const shiftInfoKey = getShiftInfoKey({ date: selectedDate, name: shiftType });
    const newShiftInfo = {
      start,
      end,
      name: shiftType,
      selectedDate,
      customChargeRateBoost,
    };
    setMultipleShiftInfo((previousMultipleShiftInfo) => {
      const newMultipleShiftInfo = new Map(previousMultipleShiftInfo);
      if (isSelected) {
        newMultipleShiftInfo.set(shiftInfoKey, newShiftInfo);
      } else {
        newMultipleShiftInfo.delete(shiftInfoKey);
      }
      return newMultipleShiftInfo;
    });
    const validationDetails = isSelected
      ? await validateShiftInfo({ shiftInviteInfo: newShiftInfo, selectedWorker })
      : undefined;

    setMultipleShiftValidationDetails((previousMultipleShiftValidationDetails) => {
      const newMultipleShiftValidationDetails = new Map(previousMultipleShiftValidationDetails);
      if (isDefined(validationDetails)) {
        newMultipleShiftValidationDetails.set(shiftInfoKey, validationDetails);
      } else {
        newMultipleShiftValidationDetails.delete(shiftInfoKey);
      }
      return newMultipleShiftValidationDetails;
    });
  };

  async function checkMultipleShiftInviteDetails(
    selectedWorker: NearbyWorkerSearchResult | undefined
  ) {
    if (isDefined(customChargeRateBoost)) {
      const promises = Array.from(multipleShiftInfo.entries()).map(
        async ([shiftInfoKey, shiftInfo]) => {
          const checkShiftInviteDetails = await checkShiftInviteDetail({
            shiftInviteInfo: shiftInfo,
            selectedWorker,
          });
          return {
            shiftInfoKey,
            shiftDetails: isDefined(checkShiftInviteDetails)
              ? checkShiftInviteDetails.data.data.attributes.shiftDetails
              : undefined,
          };
        }
      );

      const results = await Promise.all(promises);
      setMultipleCheckShiftInviteDetails((previousMultipleCheckShiftInviteDetails) => {
        const newMultipleCheckShiftInviteDetails = new Map(previousMultipleCheckShiftInviteDetails);
        for (const { shiftInfoKey, shiftDetails } of results) {
          if (isDefined(shiftDetails)) {
            newMultipleCheckShiftInviteDetails.set(shiftInfoKey, shiftDetails);
          }
        }
        return newMultipleCheckShiftInviteDetails;
      });
    }
  }

  return {
    groupInviteSharedData,
    setGroupInviteSharedData,
    multipleShiftInfo,
    setMultipleShiftInfo,
    multipleShiftValidationDetails,
    setMultipleShiftValidationDetails,
    shiftTimes,
    validateAndUpdateShiftInvite,
    isValidatingShiftInfo,
    facilityTimeZone,
    customChargeRateBoost,
    setCustomChargeRateBoost,
    multipleCheckShiftInviteDetails,
    setMultipleCheckShiftInviteDetails,
    checkMultipleShiftInviteDetails,
  };
}

export const [ShiftInviteProvider, useShiftInviteContext] = constate(useShiftInvite);
