const adminWebPrefix = "Admin Web:";
const workPlaceWebPrefix = "Workplace Web:";

export const ADMIN_EVENTS = {
  OPEN_SEARCH: `${adminWebPrefix} Search Opened`,
  FILTER_SEARCH: `${adminWebPrefix} Search Filtered`,
  SELECT_HCP: `${adminWebPrefix} HCP Selected`,
  SELECT_HCF: `${adminWebPrefix} HCF Selected`,
  VIEW_ADMIN_TOOLBOX: `${adminWebPrefix} Shift Admin Toolkit Viewed`,
  VIEW_HCPS_TO_ASSIGN: `${adminWebPrefix} Assign HCP List Viewed`,
  ASSIGN_HCP: `${adminWebPrefix} Worker Assigned`,
  CONFIRM_HCP: `${adminWebPrefix} HCP Confirmed`,
  OPEN_HCF_DRAWER: `${adminWebPrefix} HCF Drawer Opened`,
  OPEN_HCP_DRAWER: `${adminWebPrefix} HCP Drawer Opened`,
  VIEW_SHIFT_MENU_OPTIONS: `${adminWebPrefix} Shift Menu Options Viewed`,
  VIEW_SHIFTS_TO_VERIFY: `${adminWebPrefix} Shifts to Verify Viewed`,
  VIEW_UNVERIFIED_SHIFTS: `${adminWebPrefix} Unverified Shifts Viewed`,
  VERIFY_SHIFT: `${adminWebPrefix} Shift Verified`,
  SELECT_SIGNATORY: `${adminWebPrefix} Signatory Selected`,
  ACCESSED_HCF_TUTORIALS: `${adminWebPrefix} HCF Tutorials Accessed`,
  CONFIRM_VERIFICATION: `${adminWebPrefix} Verification Confirmed`,
  OPENED_ADMIN_PROFILE_LINK: `${adminWebPrefix} Admin Profile Link Opened`,
  INVOICE_VIEWED: `${adminWebPrefix} Invoice Viewed`,
  INVOICE_PDF_DOWNLOADED: `${adminWebPrefix} Invoice PDF Downloaded`,
  TIMESHEET_VIEWED: `${adminWebPrefix} Timesheet Viewed`,
  TIMESHEET_PDF_DOWNLOADED: `${adminWebPrefix} Timesheet PDF Downloaded`,
  INVOICE_NUMBER_SEARCHED: `${adminWebPrefix} Invoice Number Searched`,
  INVOICES_FILTERED: `${adminWebPrefix} Invoices filtered`,
  SEARCH_FACILITY: `${adminWebPrefix} Specific Facility Searched`,
  FETCH_FACILITIES: `${adminWebPrefix} List of Facilities Fetched`,
  SHIFT_TIME_CHANGE: `${adminWebPrefix} Shift Time Changed`,
  SHIFT_CHARGE_RATE_CHANGE: `${adminWebPrefix} Shift Charge Rate Changed`,
  SHIFT_PAY_RATE_CHANGE: `${adminWebPrefix} Shift Pay Rate Changed`,
  SHIFT_NOTE_CHANGE: `${adminWebPrefix} Shift Note Changed`,
  INVOICED_REASSIGN_HCP: `${adminWebPrefix} HCP Shift Reassigned`,
  FACILITY_CANCEL: `${adminWebPrefix} Facility Cancelled`,
  WORKER_CANCEL: `${adminWebPrefix} Worker Cancelled`,
  NO_CALL_NO_SHOW: `${adminWebPrefix} No Call No Show Happened`,
  SHIFT_UNASSIGN: `${adminWebPrefix} Shift Unassigned`,
  SHIFT_DELETE: `${adminWebPrefix} Shift Deleted`,
  INVOICED_SHIFT_RATE_CHANGE: `${adminWebPrefix} Shift Rate Change Invoiced`,
  SHIFT_SUCCESS_CHANGE: `${adminWebPrefix} Invoiced Shift Completed`,
  SHIFT_CANCEL_CHANGE: `${adminWebPrefix} Invoiced Shift Cancelled`,
  INVOICE_STATUS_CHANGED: `${adminWebPrefix} Invoice Status Changed`,
  FACILITY_STATE_CHANGED: `${adminWebPrefix} Facility State Changed`,
  ADMIN_EDIT_SHIFT: `${adminWebPrefix} Shift Edited`,
  ACCESSED_HCF_ARTICLES: `${adminWebPrefix} HCF Articles Accessed`,
  NOTIFICATION_PREFERENCES_EDITED: `${adminWebPrefix} Notification Preferences Edited`,
  NOTIFICATION_PREFERENCES_UPDATED: `${adminWebPrefix} Notification Preferences Updated`,
  ADD_LICENSE_BEGUN: `${adminWebPrefix} Add License Begun`,
  LICENSE_ADDED: `${adminWebPrefix} License Added`,
  LICENSE_EDITED: `${adminWebPrefix} License Edited`,
  LICENSE_VERIFICATION_DOCUMENT_CHOSEN: `${adminWebPrefix} License Verification Document Chosen`,
  LICENSE_STATUS_CHANGED: `${adminWebPrefix} License Status Changed`,
  LICENSE_UPDATED: `${adminWebPrefix} License Updated`,
  VARIABLE_CHARGE_STATUS_UPDATED: `${adminWebPrefix} Variable Charge Status Updated`,
  OPEN_LOCATION_TAB: `${workPlaceWebPrefix} Open Admin Locations tab`,
  EDIT_LOCATION_INFO: `${workPlaceWebPrefix} Edit Admin Locations info`,
  OPEN_STAFFED_SHIFTS: `${workPlaceWebPrefix} Open Admin Staffed shifts`,
  OPEN_UPCOMING_SHIFTS: `${workPlaceWebPrefix} Open Admin Upcoming 2 weeks`,
  OPEN_CALENDAR: `${workPlaceWebPrefix} Open Admin Calendar`,
  OPEN_PAYROLL_PAGE: `${workPlaceWebPrefix} Open Admin Payroll page`,
  OPEN_TIMECLOCK_REQUESTS_PAGE: `${workPlaceWebPrefix} Open Timeclock Requests page`,
  OPEN_STRIPE_PAYOUTS: `${workPlaceWebPrefix} Open Stripe payouts`,
  OPEN_STRIPE_TRANSFERS: `${workPlaceWebPrefix} Open Stripe transfers`,
  UNVERIFIED_SHIFTS_VIEWED: `${workPlaceWebPrefix} View unverified shifts`,
  LIVE_WORKER_ETA_SHIFT_VIEWED: `${workPlaceWebPrefix} Live Worker ETA Shift Viewed`,
  LIVE_WORKER_ETA_PAGE_VIEWED: `${workPlaceWebPrefix} Live Worker ETA Page Viewed`,
  LIVE_WORKER_ETA_VISIBILITY_CHANGED: `${workPlaceWebPrefix} Changed trip visibility on the map`,
  WORKER_LICENSE_ACTIVATED: `Frontend: Worker License Activated`, // not using common prefix here because same event is triggered from hcp app
  FACILITY_CHARGE_RATE_CHANGED: `${adminWebPrefix} Facility Charge Rate Changed`,
};

export const HCF_USER_EVENTS = {
  MASS_UPLOAD_CALENDAR_BUTTON_CLICKED: `${workPlaceWebPrefix} Mass Upload Calendar Button Clicked`,
  MASS_UPLOAD_POPUP_CANCELLED: `${workPlaceWebPrefix} Mass Upload Popup Cancelled`,
  MASS_UPLOAD_FILE_OPENED: `${workPlaceWebPrefix} Mass Upload File Opened`,
  MASS_UPLOAD_FILE_SUBMISSION_CONFIRMED: `${workPlaceWebPrefix} Mass Upload File Submission Confirmed`,
  MASS_UPLOAD_FILE_SUBMISSION_SUCCEEDED: `${workPlaceWebPrefix} Mass Upload File Submission Succeeded`,
  MASS_UPLOAD_FILE_SUBMISSION_FAILED: `${workPlaceWebPrefix} Mass Upload File Submission Failed`,
  SHIFT_CREATED: `${workPlaceWebPrefix} Shift Created`,
  CALENDAR_VIEWED: `${workPlaceWebPrefix} Calendar Viewed`,
  USERS_VIEWED: `${workPlaceWebPrefix} Users Viewed`,
  MY_ACCOUNT_VIEWED: `${workPlaceWebPrefix} Viewed My Account Page`,
  EDIT_MY_ACCOUNT_INFORMATION: `${workPlaceWebPrefix} Edit My Account Information`,
  EDIT_MY_NOTIFICATION_PREFERENCES: `${workPlaceWebPrefix} Edit My Notification Preferences`,
  WORKER_INSTRUCTIONS_VIEWED: `${workPlaceWebPrefix} Worker Instructions Viewed`,
  CHAT_OPENED: `${workPlaceWebPrefix} Chat opened`,
  SHIFT_REVIEW_SUBMITTED: `${workPlaceWebPrefix} Shift Review Submitted`,
  BLOCK_WORKER: `${workPlaceWebPrefix} Block / DNR Worker`,
  UNBLOCK_WORKER: `${workPlaceWebPrefix} Unblock Worker`,
  CANCEL_SHIFT: `${workPlaceWebPrefix} Cancel Shift`,
  SEARCH_WORKER: `${workPlaceWebPrefix} Worker Searched`,
  WORKER_PROFILE_NAVIGATED: `${workPlaceWebPrefix} Worker Profile Navigated`,
  VIEW_DOCUMENT: `${workPlaceWebPrefix} View Document`,
  OPEN_SHIFT_REVIEW: `${workPlaceWebPrefix} Open Shift Review`,
  SHIFT_DETAILS_CTA_CLICKED: `${workPlaceWebPrefix} Shift Details CTA Clicked`,
  SENT_HOME_CONFIRMATION_SUBMITTED: `${workPlaceWebPrefix} Sent Home Confirmation Submitted`,
  FACILITY_CANCELLED_ME_CONFIRMATION_SUBMITTED: `${workPlaceWebPrefix} Facility Cancelled Me Confirmation Submitted`,
  DASHBOARD_VIEWED: `${workPlaceWebPrefix} Dashboard Viewed`,
  CONTRACT_TERMS_VIEWED: `${workPlaceWebPrefix} Contract Terms page viewed`,
  RATINGS_BANNER_CLOSED: `${workPlaceWebPrefix} Ratings Banner Closed`,
  RATINGS_BANNER_CLICKED: `${workPlaceWebPrefix} Ratings Banner CTA Clicked`,
  RATINGS_CAROUSEL_SKIPPED: `${workPlaceWebPrefix} Worker Skipped on the Ratings Carousel`,
  RATINGS_CAROUSEL_CLOSED: `${workPlaceWebPrefix} Worker Ratings Carousel Closed Prematurely`,
  RATINGS_CAROUSEL_LAST_STEP: `${workPlaceWebPrefix} Last Step of Carousel Reached`,
  RATINGS_CAROUSEL_VIEWED: `${workPlaceWebPrefix} Worker Ratings Carousel Viewed`,
  WORKER_ETA_SEEN: `${workPlaceWebPrefix} Worker ETA seen`,
  WORKERS_FAVORITE_BANNER: `${workPlaceWebPrefix} User Engaged with Favorites Banner`,
  WORKERS_PREFERED_BANNER: `${workPlaceWebPrefix} User Engaged with Favorites + High Attendance Banner`,
  URGENT_SHIFT_CANCELLED: `${workPlaceWebPrefix} Urgent shift cancel action`,
  HELP_CENTER_OPENED: `${workPlaceWebPrefix} Help Center Opened`,
  WORKER_PHONE_DIALED: `${workPlaceWebPrefix} Worker Phone Number Dialed`,
  NEGOTIATION_MODAL_VIEWED: `${workPlaceWebPrefix} Negotiation Modal Viewed`,
  SHIFT_CHARGE_UPDATED: `${workPlaceWebPrefix} Shift Charge Updated`,
  RATE_NEGOTIATION_ACCEPTED: `${workPlaceWebPrefix} Rate Accepted`,
  TIME_NEGOTIATION_ACCEPTED: `${workPlaceWebPrefix} Time Accepted`,
  NEGOTIATION_ENDED: `${workPlaceWebPrefix} Negotiation Ended`,
  NEGOTIATION_PROPOSED: `${workPlaceWebPrefix} Rate Proposed`,
  ADD_EDIT_TEAM_MEMBER: `${workPlaceWebPrefix} Add/Edit Team Member`,
  NOTIFICATION_CENTER_VIEWED: `${workPlaceWebPrefix} Notification Center Viewed`,
  NOTIFICATION_CENTER_ICON_VIEWED: `${workPlaceWebPrefix} Notification Center Icon Viewed`,
  DEACTIVATE_TEAM_MEMBER: `${workPlaceWebPrefix} Deactivate Team Member`,
  UNLINK_TEAM_MEMBER: `${workPlaceWebPrefix} Unlink Team Member`,
  ACTIVATE_TEAM_MEMBER: `${workPlaceWebPrefix} Activate Team Member`,
  LINK_TEAM_MEMBER: `${workPlaceWebPrefix} Link Team Member`,
  VISIT_ROLE_DETAILS: `${workPlaceWebPrefix} Visit Role Details`,
  GETTING_STARTED: `${workPlaceWebPrefix} Getting Started`,
  GETTING_STARTED_PAGE_VIEWED: `${workPlaceWebPrefix} Getting Started Page Viewed`,
  INTERMEDIATE_SHIFT_POSTING_OPTIONS: `${workPlaceWebPrefix} Intermediate Shift Posting Options Modal`,
  INTERMEDIATE_SHIFT_POSTING_OPTIONS_VIEWED: `${workPlaceWebPrefix} Intermediate Shift Posting Options Modal Viewed`,
  WORKER_DIRECTORY_PROFILE: `${workPlaceWebPrefix} Worker Directory Profile`,
  WORKER_DIRECTORY_PROFILE_PAGE_VIEWED: `${workPlaceWebPrefix} Worker Directory Profile Page Viewed`,
  WORKER_DIRECTORY: `${workPlaceWebPrefix} Worker Directory`,
  WORKER_DIRECTORY_PAGE_VIEWED: `${workPlaceWebPrefix} Worker Directory Page Viewed`,
  WORKER_DIRECTORY_ITEM: `${workPlaceWebPrefix} Worker Directory Item`,
  WORKER_TYPEFORM_BLOCK_SHIFTS: `${workPlaceWebPrefix} Worker TypeForm Block Shifts`,
  WORKER_TYPEFORM_BLOCK_SHIFTS_PAGE_VIEWED: `${workPlaceWebPrefix} Worker TypeForm Block Shifts Page Viewed`,
  LOGGED_IN: `${workPlaceWebPrefix} Logged In`,
  UNFAVORITE_WORKER: `${workPlaceWebPrefix} Unfavorite Worker`,
  SHIFT_CREATE_ACTIONS: `${workPlaceWebPrefix} Shift Create Actions`,
  WORKPLACE_GET_ACCESS_PAGE_VIEWED: `${workPlaceWebPrefix} Self-Signup Get Access Page Viewed`,
  WORKPLACE_GET_ACCESS: `${workPlaceWebPrefix} Self-Signup Get Access`,
  SELF_SIGNUP_POTENTIAL_WORKER_POPUP: `${workPlaceWebPrefix} Self-Signup Potential Worker Popup`,
  FACILITY_CONTRACT_REQUIREMENT_POPUP: `${workPlaceWebPrefix} Facility Contract Requirement Popup`,
  SELF_SIGNUP_CONTRACT_PAGE_VIEWED: `${workPlaceWebPrefix} Self-Signup Contract Page Viewed`,
  SELF_SIGNUP_CONTRACT_POPUP: `${workPlaceWebPrefix} Self-Signup Contract Popup`,
  WORKPLACE_LOW_CHARGE_UPDATE: `${workPlaceWebPrefix} Low Charge Update Rates Button Clicked`,
  AVAILABLE_WORKER_CLICKED: `${workPlaceWebPrefix} Available worker clicked`,
  WORKPLACE_REFER_AND_EARN_PAGE_VIEWED: `${workPlaceWebPrefix} Refer And Earn Page Viewed`,
  WORKPLACE_REFER_AND_EARN: `${workPlaceWebPrefix} Refer And Earn`,
  PAST_SHIFTS_REPORT_REQUESTED: `${workPlaceWebPrefix} Past Shifts Report Requested`,
  SIDEBAR_MENU_ITEM_CLICKED: `${workPlaceWebPrefix} Sidebar Menu Item Clicked`,
  FACILITY_APP_DOWNLOAD_BANNER: `${workPlaceWebPrefix} Facility Mobile App Download Banner`,
  SHIFT_TYPE_SHIFT_START_MISMATCHED: `${workPlaceWebPrefix} Shift Type and Shift Start Mismatched`,
  SHIFT_CHARGE_RATE: `${workPlaceWebPrefix} Shift Charge Rate`,
  WORKER_LEFT_EARLY_CTA_VIEWED: `${workPlaceWebPrefix} Worker Left Early CTA Viewed`,
  WORKER_LEFT_EARLY_CTA_CLICKED: `${workPlaceWebPrefix} Worker Left Early CTA Clicked`,
  WORKER_LEFT_EARLY_REPLACEMENT_REQUEST: `${workPlaceWebPrefix} Worker Left Early Replacement Requested`,
  FACILITY_NO_SHOW_REPLACEMENT_REQUEST: `${workPlaceWebPrefix} Facility No Show Replacement Requested`,
  SHIFT_INVITE: `${workPlaceWebPrefix} Shift Invite`,
  CALENDAR_SETTINGS: `${workPlaceWebPrefix} Calendar Settings`,
  CREATE_MAGIC_SHIFT: `${workPlaceWebPrefix} Create Magic Shift`,
  MAX_HOURS_RESTRICTION: `${workPlaceWebPrefix} Max Hours Restrictions`,
  VIEWED_UPDATE_FACILITY_NOTES_ALERT: `${workPlaceWebPrefix} Viewed Update Facility Notes Alert`,
  CLICKED_UPDATE_FACILITY_NOTES_ALERT: `${workPlaceWebPrefix} Clicked Update Facility Notes Alert`,
};

export const COMMON_EVENTS = {
  SELECT_DAY: `${adminWebPrefix} Day Selected`,
  VIEW_PAGENAME: `Invoice By Number`,
};
