import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";

interface IUseNotificationCenterFlag {
  isAdmin: boolean;
}

/**
 * Checks if the "notification center" feature is enabled.
 */
export const useNotificationCenterFlag = ({
  isAdmin,
}: IUseNotificationCenterFlag): {
  ffStatus: boolean;
  showNotificationCenter: boolean;
} => {
  const isNotificationCenterActive = useCbhFlag(CbhFeatureFlag.NOTIFICATION_CENTER_ACTIVE, {
    defaultValue: false,
  });

  return {
    ffStatus: isNotificationCenterActive,
    showNotificationCenter: !isAdmin && isNotificationCenterActive,
  };
};
