import { logEvent } from "@src/appV2/lib/analytics";
import { HCF_USER_EVENTS } from "src/constants/firebaseEvents";
import { CarouselTriggeredBy } from "src/modules/workerReviews";

export const logSkipWorkerRating = (
  workerId: string,
  workplaceId: string,
  workplaceUserId: string,
  sessionId: string
) => {
  logEvent(HCF_USER_EVENTS.RATINGS_CAROUSEL_SKIPPED, {
    workerId,
    workplaceId,
    workplaceUserId,
    sessionId,
    createdAt: new Date().toISOString(),
  });
};

export const logEarlyClose = (
  workplaceId: string,
  workplaceUserId: string,
  totalWorkerSlidesShown: number,
  totalWorkersReviewed: number,
  totalWorkersSkipped: number,
  sessionId: string
) => {
  logEvent(HCF_USER_EVENTS.RATINGS_CAROUSEL_CLOSED, {
    workplaceId,
    workplaceUserId,
    totalWorkersReviewed,
    totalWorkerSlidesShown,
    totalWorkersSkipped,
    sessionId,
    createdAt: new Date().toISOString(),
  });
};

type LastStepOptions = {
  reviewOtherWorkersSelected?: boolean;
  reviewTheseWorkersSelected?: boolean;
  closeSelected?: boolean;
  totalWorkerSlidesShown: number;
  totalWorkersReviewed: number;
  totalWorkersSkipped: number;
};
export const logLastStep = (
  workplaceId: string,
  workplaceUserId: string,
  sessionId: string,
  options: LastStepOptions
) => {
  logEvent(HCF_USER_EVENTS.RATINGS_CAROUSEL_LAST_STEP, {
    workplaceId,
    workplaceUserId,
    sessionId,
    createdAt: new Date().toISOString(),
    ...options,
  });
};

export function logModalViewed(
  workplaceId: string,
  workplaceUserId: string,
  sessionId: string,
  numberOfWorkersAwaitingRating: number,
  source: CarouselTriggeredBy
) {
  logEvent(HCF_USER_EVENTS.RATINGS_CAROUSEL_VIEWED, {
    workplaceId,
    workplaceUserId,
    sessionId,
    numberOfWorkersAwaitingRating,
    source,
  });
}
