import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";

export function useActiveChatWindowDays() {
  const activeChatWindowDaysFlag = useCbhFlag(
    CbhFeatureFlag.IN_APP_CHAT_LIMITED_DAYS_AFTER_BOOKED_SHIFT,
    {
      defaultValue: { days: 30 },
    }
  );
  return activeChatWindowDaysFlag.days;
}
