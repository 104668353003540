import request from "superagent";

import { getAuthHeader } from "../utils/superagent";

export const getFacilityUserAccounts = async ({ name }) => {
  const response = await request
    .get(`${global.api}/facilityUser/accounts`)
    .set(await getAuthHeader())
    .query({ name });
  return response.body.facilities;
};

export const getFacilityUser = async () => {
  const { body } = await request
    .get(`${global.api}/facilityUser/findByEmail`)
    .set(await getAuthHeader());
  return body;
};

export const getActiveUsers = async (facilityId) => {
  return request
    .get(`${global.api}/facilityUser/activeUsers`)
    .query({ facilityId })
    .set(await getAuthHeader());
};
