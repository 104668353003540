import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import once from "lodash/once";
import { SessionType } from "src/modules/interface";
import { useFacilityOnboardingFlag } from "./useFacilityOnboardingFlag";
import { getLocation } from "src/utils/routes";

export function useFacilityOnboarding(
  profile: SessionType["session"]["profile"] | undefined,
  hasPermission: boolean,
  isAdmin: boolean,
  isFacility: boolean
) {
  const history = useHistory();
  const location = useLocation();
  // capture the intial pathname to decide whether to redirect to a specfic page or not
  const [initialPathName] = useState(location.pathname);
  const numberOfSession = localStorage.getItem(`numberOfSession_${profile?.userId}`) ?? 0;

  const navigateToOnboardingPage = useCallback(
    once(() => {
      history.push(getLocation("facilityOnboarding"));
    }),
    [profile?.userId]
  );

  const isFeatureEnabled = useFacilityOnboardingFlag(profile, hasPermission, isAdmin, isFacility);

  useEffect(() => {
    if (!profile?.onboardingSteps || !profile.userId) {
      return;
    }
    if (
      (initialPathName === "/" || +numberOfSession <= 1) &&
      isFeatureEnabled &&
      (profile?.onboardingSteps?.stage === "pending" ||
        profile?.onboardingSteps?.stage === "started")
    ) {
      navigateToOnboardingPage();
    }
  }, [
    navigateToOnboardingPage,
    profile?.onboardingSteps,
    isFeatureEnabled,
    profile?.userId,
    initialPathName,
    numberOfSession,
  ]);
}
