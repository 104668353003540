import request from "superagent";
import { getAuthHeader } from "src/utils/superagent";
import {
  AssignedShiftsCount,
  ExclusionDetails,
  FetchAgentDetails,
  FetchAssignedShifts,
  FetchAssignedShiftsCount,
  FetchAssignedShiftsReturnType,
  WorkerDetails,
} from "./workplaceWorker.types";

export const fetchAgentDetails = async ({
  workerId,
  workplaceId,
}: FetchAgentDetails): Promise<WorkerDetails> => {
  const { body } = await request
    .get(`${global.api}/agentprofile/${workerId}/facility/${workplaceId}`)
    .set(await getAuthHeader());
  return body;
};

export const fetchAssignedShiftsCount = async ({
  workerId,
  workplaceId,
}: FetchAssignedShiftsCount): Promise<AssignedShiftsCount> => {
  const { body } = await request
    .get(`${global.api}/shifts/assigned-count`)
    .set(await getAuthHeader())
    .query({ workerId, workplaceId, requireLastWorkedShift: true });

  return body;
};

export const fetchAssignedShifts = async ({
  workerId,
  workplaceId,
  category,
  pageToken,
}: FetchAssignedShifts): Promise<FetchAssignedShiftsReturnType> => {
  const { body } = await request
    .get(`${global.api}/shifts/assigned`)
    .set(await getAuthHeader())
    .query({ workerId, workplaceId, category, pageToken });

  return body;
};

export const fetchExclusionDetails = async ({
  workerId,
  workplaceId,
}: FetchAssignedShiftsCount): Promise<ExclusionDetails[]> => {
  const { body } = await request
    .get(`${global.api}/exclusion`)
    .set(await getAuthHeader())
    .query({ facilityId: workplaceId, agentId: workerId });

  return body;
};
