import FavoriteIcon from "@mui/icons-material/Favorite";
import ErrorIcon from "@mui/icons-material/Error";
import { Tooltip } from "@mui/material";

export interface FavoriteIndicatorProps {
  disabled?: boolean;
  cancelled?: boolean;
}

export function FavoriteIndicator(props: FavoriteIndicatorProps) {
  const { disabled, cancelled = false } = props;
  return (
    <Tooltip
      title={
        cancelled
          ? "This professional is on your favorites list, and has recent last minute cancels or no shows at your facility."
          : "This professional is highly rated by your facility."
      }
    >
      {cancelled ? (
        <ErrorIcon
          role="presentation"
          aria-hidden={false}
          aria-label="Toggle Cancelled Favorite Tooltip"
          sx={{
            color: "#a10702",
          }}
        />
      ) : (
        <FavoriteIcon
          role="presentation"
          aria-hidden={false}
          aria-label="Toggle Favorite Tooltip"
          sx={{
            color: (theme) => (disabled ? theme.palette.action.disabled : "#a10702"),
          }}
        />
      )}
    </Tooltip>
  );
}
