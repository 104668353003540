import React, { Fragment, useState } from "react";
import { Card } from "antd";
import styled from "styled-components";
import { ImageViewer } from "../../components/imageViewer";
import { FilePreviewItem } from "./filePreviewItem";

const CustomCard = styled(Card)`
  .ant-card-body {
    padding: 5px;
  }
  img {
    width: 100%;
  }
`;

const FileContainer = styled.div`
  overflow-y: auto;
  max-height: 40vh;
`;

function FilePreviewList({ fileList }) {
  const [previewUrl, setPreviewUrl] = useState(null);
  const [showImageViewer, setShowImageViewer] = useState(false);

  const previewFile = (url) => () => {
    setPreviewUrl(url);
    setShowImageViewer(true);
  };

  return (
    <Fragment>
      <FileContainer>
        {fileList?.map((file, index) => (
          <FilePreviewItem file={file} key={index} previewFile={previewFile} />
        ))}
      </FileContainer>

      <ImageViewer
        visible={showImageViewer}
        images={[{ src: `${previewUrl}`, alt: "Timecard Image" }]}
        onClose={() => setShowImageViewer(false)}
      />
    </Fragment>
  );
}

export { FilePreviewList };
