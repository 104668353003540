import { Modal } from "antd";
import React from "react";

import { ModifyUser } from "./modifyUser";

function ModifyFacilityUserModal({
  visible,
  onUpdate,
  userToEdit,
  onCancel,
  facilityId,
  facility,
}) {
  return (
    <Modal
      title={userToEdit ? `Update ${userToEdit.name}` : "New User"}
      visible={visible}
      footer={null}
      width="75%"
      bodyStyle={{
        height: "70vh",
        /* @ts-expect-error FIXME this wrong type */
        "overflow-y": "auto",
      }}
      maskClosable={false}
      destroyOnClose={true}
      onCancel={onCancel}
    >
      <ModifyUser
        onUpdate={onUpdate}
        userToEdit={userToEdit || {}}
        facilityId={facilityId}
        facility={facility}
      />
    </Modal>
  );
}

export { ModifyFacilityUserModal };
