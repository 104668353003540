import moment from "moment-timezone";

export function formatShiftDate(shiftStart: string, shiftEnd: string, timezone: string): string {
  const start = moment(shiftStart).tz(timezone);
  const end = moment(shiftEnd).tz(timezone);
  const fullFormat = "DD MMM, YYYY, hh:mm A";
  const shortFormat = "hh:mm A";

  return `${start.format(fullFormat)} - ${end.format(
    start.isSame(end, "day") ? shortFormat : fullFormat
  )}`;
}

export function getQualityTitle(rating: number | undefined): string {
  return rating === undefined || rating <= 3
    ? "What qualities should they improve upon?"
    : "What qualities do you like most about them?";
}

export type RatingPerformanceMode = "view" | "edit";

export function getSubmitText(
  mode: RatingPerformanceMode,
  workerReviewId?: string,
  showDnr?: boolean
): string {
  if (mode === "view") {
    return "Edit";
  }

  return `${workerReviewId ? "Update" : "Submit"}${showDnr ? " and Unblock" : ""}`;
}

export function getAdditionalFeedbackPlaceholder(rating: number) {
  if (rating < 3) {
    return "Consider sharing an example of why they weren't up to standard and what they should improve upon.";
  } else if (rating === 3) {
    return "Consider sharing feedback on what they should improve upon and, if it applies, what they did well.";
  } else {
    return "Consider sharing an example of what they did great and feedback on what they should keep doing.";
  }
}
