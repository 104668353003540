import moment from "moment-timezone";
import request from "superagent";
import { logEvent } from "@src/appV2/lib/analytics";
import { HCF_USER_EVENTS } from "src/constants/firebaseEvents";

import {
  FIREBASE_LOGIN_SUCCESS,
  get as fetchSession,
  LOGIN_REQUESTED,
  FIREBASE_TOKEN_READY,
  ADMIN_PROFILE_SUCCESS,
  LOGOUT,
  logout,
} from "./../session";
import { FirebaseAuth } from "../../utils/firebase";
import firebase from "firebase/app";

import { getFacilityUser } from "../../api/facilityUsers";
import { getAuthHeader } from "../..//utils/superagent";
import { removeDatadogRUMUser } from "../../utils/datadog";

export const initFirebaseSession = () => async (dispatch) => {
  /*
   * @Warning
   * onAuthStateChanged() listener causes onAuthChanged() function to be triggered twice, hence commented out
   */
  // FirebaseAuth.onAuthStateChanged(onAuthChanged(dispatch))

  // This method has the same behavior as firebase.auth.Auth.onAuthStateChanged had prior to 4.0.0. (Firebase Docs)
  FirebaseAuth.onIdTokenChanged(onAuthChanged(dispatch));
  await FirebaseAuth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
  dispatch({
    type: FIREBASE_TOKEN_READY,
    data: {
      ready: false,
    },
  });
};

const onFacilityLogin = async ({ dispatch, user, tokenResult }) => {
  const facilityIdsArray = user?.facilities.map((facility) => facility.userId);
  const selectedFacility = localStorage.getItem("selectedFacility");
  const selectedUser = localStorage.getItem("selectedUser");
  const isSelectedFacilityExists = selectedFacility && facilityIdsArray.includes(selectedFacility);
  const userId = isSelectedFacilityExists ? selectedFacility : facilityIdsArray[0] || selectedUser;

  // when facility user logins, selectedFacility is empty (we clear localStorage after loggin out)
  if (!isSelectedFacilityExists) {
    localStorage.setItem("selectedFacility", facilityIdsArray[0]);
    logEvent(HCF_USER_EVENTS.LOGGED_IN, {
      workplaceId: userId,
      workplaceUserId: user?._id,
    });
  }

  dispatch(
    fetchSession({
      userId,
      isFirebaseSession: true,
    })
  );

  dispatch({
    type: FIREBASE_LOGIN_SUCCESS,
    data: {
      firebaseToken: tokenResult.token,
      user: {
        facilityIds: facilityIdsArray,
        tmz: user.tmz,
        access: user.roles,
        permissions: user.permissions,
        _id: user._id,
        name: user.name,
        email: user.email,
        phone: user.phone,
        notify: user.notify,
        isEmailVerified: user.isEmailVerified,
        isSuspicious: user.isSuspicious,
        ...(user?.intercomHash && { intercomHash: user.intercomHash }),
      },
    },
  });
};

const onCBHAdminLoggedIn = async ({ dispatch, user, tokenResult }) => {
  const selectedUser = localStorage.getItem("selectedUser");

  dispatch({
    type: LOGIN_REQUESTED,
  });

  if (tokenResult) {
    dispatch(
      fetchSession({
        userId: selectedUser || user._id,
        isFirebaseSession: false,
      })
    );
  }
  dispatch({
    type: FIREBASE_LOGIN_SUCCESS,
    data: {
      firebaseToken: tokenResult.token,
      user: {
        access: user.roles,
        _id: user._id,
        name: user.name,
        email: user.email,
        phone: user.phone,
        ...(user?.intercomHash && { intercomHash: user.intercomHash }),
      },
    },
  });
};

const handleEmployeeLogin = async (dispatch, tokenResult) => {
  const resp = await request.get(`${global.api}/user/getByEmail`).set(await getAuthHeader());

  dispatch({ type: ADMIN_PROFILE_SUCCESS, data: { user: resp.body } });

  const user = { ...resp.body };
  onCBHAdminLoggedIn({ dispatch, user, tokenResult });
};

const handleFacilityLogin = async (dispatch, tokenResult) => {
  const { user } = await getFacilityUser();
  onFacilityLogin({ dispatch, user, tokenResult });
};

const onLoggedIn = async (dispatch, user) => {
  const tokenResult = await user.getIdTokenResult();
  const { email } = tokenResult.claims;
  localStorage.setItem("AUTH_TOKEN", tokenResult.token);
  dispatch({
    type: FIREBASE_TOKEN_READY,
    data: {
      ready: true,
    },
  });
  email?.includes("clipboardhealth.com")
    ? await handleEmployeeLogin(dispatch, tokenResult)
    : await handleFacilityLogin(dispatch, tokenResult);
};

export const keepUserSessionAlive = async (dispatch, validTillInHours = 720) => {
  const currentUserToken = await FirebaseAuth.currentUser?.getIdTokenResult();
  if (moment().diff(moment(currentUserToken?.authTime), "hours") >= validTillInHours) {
    dispatch(logout());
  }
};

const onLoggedOut = async (dispatch) => {
  dispatch({
    type: LOGOUT,
  });
  removeDatadogRUMUser();
};

const onAuthChanged = (dispatch) => async (user) => {
  if (user) {
    await keepUserSessionAlive(dispatch);
    await onLoggedIn(dispatch, user);
  } else {
    onLoggedOut(dispatch);
  }
};
