import { Layout } from "antd";
import React from "react";
import { supportTicketUrl } from "./constants";
import { useIsHomeHealthEnabled } from "@src/appV2/ExperimentalHomeHealth/lib/useIsHomeHealthEnabled";
import { HomeHealthAppFooter } from "@src/appV2/ExperimentalHomeHealth/lib/HomeHealthAppFooter";

const { Footer } = Layout;

function AppFooter({ style = {}, ...rest }) {
  const isHomeHealthEnabled = useIsHomeHealthEnabled();

  return (
    <Footer style={{ ...style, textAlign: "center" }} {...rest}>
      {isHomeHealthEnabled ? (
        <HomeHealthAppFooter />
      ) : (
        <>
          Clipboard Health ©{new Date().getFullYear()} |{" "}
          <a href={supportTicketUrl} target="_blank" rel="noopener noreferrer">
            Contact Us / Submit a Request
          </a>{" "}
          | 408-837-0116
        </>
      )}
    </Footer>
  );
}

export { AppFooter };
