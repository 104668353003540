import { enqueueSnackbar, closeSnackbar, type SnackbarKey } from "notistack";
import { ReactNode } from "react";

interface ShowToastOptions {
  onClick?: () => void;
  icon?: ReactNode;
  autoHideDurationInMilliseconds?: number;
}

export function showSuccessToast(message: string, options?: ShowToastOptions) {
  const { onClick, icon, autoHideDurationInMilliseconds } = options ?? {};

  return enqueueSnackbar({
    message,
    variant: "success",
    onClick,
    icon,
    autoHideDuration: autoHideDurationInMilliseconds,
  });
}

export function showInfoToast(message: string, options?: ShowToastOptions) {
  const { onClick, icon, autoHideDurationInMilliseconds } = options ?? {};

  return enqueueSnackbar({
    message,
    variant: "info",
    onClick,
    icon,
    autoHideDuration: autoHideDurationInMilliseconds,
  });
}

export function showErrorToast(message: string, options?: ShowToastOptions) {
  const { onClick, icon, autoHideDurationInMilliseconds } = options ?? {};

  return enqueueSnackbar({
    message,
    variant: "error",
    onClick,
    icon,
    autoHideDuration: autoHideDurationInMilliseconds,
  });
}

export function showWarningToast(message: string, options?: ShowToastOptions) {
  const { onClick, icon, autoHideDurationInMilliseconds } = options ?? {};

  return enqueueSnackbar({
    message,
    variant: "warning",
    onClick,
    icon,
    autoHideDuration: autoHideDurationInMilliseconds,
  });
}

export function closeToast(toastKey: SnackbarKey) {
  closeSnackbar(toastKey);
}
