export const MENU_MESSAGES = {
  GETTING_STARTED: "Getting Started",
  CALENDAR: "Calendar",
  LIVE_WORKER_ETA: "Live Worker ETA",
  FACILITY_RULES_AI: "Facility Rules AI",
  WORKERS: "Professionals",
  FAVORITE_BOOKED: "Favorites + Booked",
  BROWSE_PROFESSIONALS: "Browse Professionals",
  PLACEMENT_SECTION: "Jobs",
  HIRE_PROFESSIONALS: "Hire Professionals",
  MANAGE_JOBS: "Manage Jobs",
  CHAT: "Chat",
  MANAGEMENT: "Management",
  MY_ACCOUNT: "My Account",
  TEAM_MEMBERS: "Team Members",
  USERS: "Users",
  FACILITY_INFORMATION: "Facility Information",
  SETTINGS: "Settings",
  REFER_AND_EARN: "Refer & Earn",
  BILLING: "Billing",
  UPCOMING_CHARGES: "Upcoming Charges",
  INVOICE_HISTORY: "Invoice History",
  CONTRACT_TERMS: "Contract Terms",
  HELP_CENTER: "Help Center",
  USER_PROFILE: "User Profile",
  MY_PROFESSIONALS: "My Professionals",
};

export const supportTicketUrl = "https://support.clipboardhealth.com/hc/en-us/requests/new";
