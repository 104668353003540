import { debounce } from "lodash";
import { useState } from "react";
import { logEvent } from "@src/appV2/lib/analytics";
import { ADMIN_EVENTS } from "../../../constants/firebaseEvents";
import { useSearchAgents } from "@src/appV2/User/api/useSearchAgents";
import { useSearchFacilities } from "@src/appV2/User/api/useSearchFacilities";
import { useRecentlyVisited } from "@src/hooks/useRecentlyVisited";

export const AGENTS_LIMIT_FOR_LOAD = 5;
export const FACILITIES_LIMIT_FOR_LOAD = 5;

interface AccountSwitcherUser {
  user: {
    userId: string;
    name?: string;
  };
  type: string;
}

export const useAccountSwitcherHook = ({ pseudo, closeMenu }) => {
  const [searchName, setSearchName] = useState();
  const [accountsListVisible, setAccountsListVisible] = useState(false);
  const [_, addToRecent] = useRecentlyVisited();

  const {
    data: searchUsersResponse,
    isLoading: isSearchUsersLoading,
    fetchNextPage: fetchNextPageSearchUsers,
    fetchStatus: fetchStatusSearchUsers,
    isFetchingNextPage: isFetchingNextPageSearchUsers,
  } = useSearchAgents({
    searchInput: searchName,
    limit: AGENTS_LIMIT_FOR_LOAD,
  });

  const {
    data: searchFacilitiesResponse,
    isLoading: isSearchFacilitiesLoading,
    fetchNextPage: fetchNextPageSearchFacilities,
    fetchStatus: fetchStatusSearchFacilities,
    isFetchingNextPage: isFetchingNextPageSearchFacilities,
  } = useSearchFacilities({
    searchInput: searchName ?? "",
    limit: AGENTS_LIMIT_FOR_LOAD,
  });

  const agentDetails = {
    list: searchUsersResponse?.pages.flatMap((page) => page.list) ?? [],
    count: searchUsersResponse?.pages[0].count ?? 0,
    loading: isSearchUsersLoading && fetchStatusSearchUsers !== "idle",
    fetchNextPage: fetchNextPageSearchUsers,
    isFetchingNextPage: isFetchingNextPageSearchUsers,
  };

  const facilityDetails = {
    list: searchFacilitiesResponse?.pages.flatMap((page) => page.list) ?? [],
    count: searchFacilitiesResponse?.pages[0].count ?? 0,
    loading: isSearchFacilitiesLoading && fetchStatusSearchFacilities !== "idle",
    fetchNextPage: fetchNextPageSearchFacilities,
    isFetchingNextPage: isFetchingNextPageSearchFacilities,
  };

  const handleOnDropdownVisible = (open) => {
    setAccountsListVisible(open);
    if (!open) {
      return;
    }
    logEvent(ADMIN_EVENTS.OPEN_SEARCH);
  };

  const searchAccounts = debounce((name) => {
    setSearchName(name);
    logEvent(ADMIN_EVENTS.FILTER_SEARCH, { user_input: name });
  }, 800);

  const handleChange = ({ user, type }: AccountSwitcherUser) => {
    pseudo({ userId: user.userId });
    if (type !== "ADMIN") {
      addToRecent({ id: user.userId, title: user.name ?? "", type });
    }

    if (closeMenu) {
      closeMenu();
    }
    if (type === "AGENT") {
      logEvent(ADMIN_EVENTS.SELECT_HCP, {
        id: user.userId,
      });
    } else if (type === "FACILITY") {
      logEvent(ADMIN_EVENTS.SELECT_HCF, {
        id: user.userId,
      });
    }
    setAccountsListVisible(false);
  };
  return {
    handleChange,
    searchAccounts,
    handleOnDropdownVisible,
    accountsListVisible,
    facilityDetails,
    agentDetails,
    setAccountsListVisible,
  };
};
