import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import meta from "src/utils/meta";
import { invokeSegment } from "src/components/segmentSnippet";

export const useInitializeSegment = () => {
  const location = useLocation();

  useEffect(() => {
    if (!window.analytics) {
      invokeSegment(meta().segment.key);
    }
    window.analytics?.page();
  }, [location.pathname]);
};
