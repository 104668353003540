import { JobType, ShiftNameType } from "../types";

export const HIRE_WORKERS_PAGE_VIEW_COUNT = "hireWorkersPageViewCount";
export const HIRE_WORKERS_ALERT_VIEW_COUNT = "hireWorkersAlertViewCount";
export const TEMPORARY_RESOURCE_ACCESS_OTP = "resource-access-one-time-password";
export const TEMPORARY_RESOURCE_ACCESS_EMAIL = "temporary-resource-access-email";
export const WHITELISTED_EMAILS = "placement-candidate-whitelisted-emails";
export const DEFAULT_HIRE_PROFESSIONALS_QUALIFICATION_FILTER = [
  "CNA",
  "LVN",
  "RN",
  "CAREGIVER",
  "HHA",
  "CHHA",
];
export const GUEST_PLACEMENT_CANDIDATE_EMAIL = "guest-placement-candidate-email";

export const jobTypeOptions = [
  { label: "Full-time", value: JobType.FULL_TIME },
  { label: "Part-time", value: JobType.PART_TIME },
  { label: "PRN", value: JobType.PER_DIEM },
  { label: "Weekend Warrior", value: JobType.WEEKEND_WARRIOR },
];

export const shiftTypeOptions = [
  { label: "Day", value: ShiftNameType.AM },
  { label: "Evening", value: ShiftNameType.PM },
  { label: "Overnight", value: ShiftNameType.NOC },
];
