import { usePollingDocumentDownloadRequest } from "@src/appV2/WorkerDocuments/usePollingDocumentDownloadRequest";
import constate from "constate";

export function useGlobalPolling() {
  const workerDocumentDownloadPolling = usePollingDocumentDownloadRequest();

  return {
    workerDocumentDownloadPolling,
  };
}

export const [GlobalPollingProvider, useGlobalPollingContext] = constate(useGlobalPolling);
