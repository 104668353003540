import request from "superagent";
import qs from "qs";

import { getAuthHeader } from "src/utils/superagent";
import { environmentConfig } from "@src/appV2/environment";

const workerReviewServiceUrl = environmentConfig.REACT_APP_SHIFT_REVIEW_API_URL;
export interface WorkerReviewRequest {
  id?: string;
  workplaceUserId: string;
  workerId: string;
  shiftId?: string;
  workplaceId: string;
  ratingRequestId?: string;
  additionalFeedback?: string;
  rating?: number;
  dnrWorker?: boolean;
  qualities?: string[];
}

interface GetExclusionRequest {
  facilityId: string;
  agentId?: string;
  agentIds?: string[];
  pageSize?: number;
}

interface CreateExclusionRequest {
  actionBy: string;
  notes: string;
  reason: string;
  facilityId: string;
  agentId: string;
  adminId?: string;
  shiftId?: string;
  shiftReviewId?: string;
}

export type ExclusionForList = {
  createdAt: string;
  agent: {
    userId: string;
    name: string;
  };
  notes: string;
  reason: string;
  _id: string;
  actionBy: "AGENT" | "FACILITY";
};

export type ExclusionForView = {
  actionBy: string;
  agent: string;
  archived: boolean;
  createdAt: string;
  excludedBy: string;
  facility: string;
  notes: string;
  reason: string;
  shiftId: string;
  shiftReviewId?: string | null;
  updatedAt: string;
  _id: string;
};

export type WorkerReviewDetails = {
  additionalFeedback: string;
  createdAt: string;
  deletedAt: string | undefined;
  dnrWorker: boolean;
  id: string;
  qualities: string[];
  rating: number;
  shiftId: string;
  updatedAt: string;
  workerId: string;
  workplaceId: string;
  workplaceUserId: string;
};

export const fetchWorkerReviews = async (
  queries: Pick<WorkerReviewRequest, "workerId" | "shiftId"> & {
    workplaceId?: string;
  }
): Promise<WorkerReviewDetails[]> => {
  const { body } = await request
    .get(`${workerReviewServiceUrl}/reviews`)
    .set(await getAuthHeader())
    .query(queries);
  return body;
};

export const createWorkerReview = async (
  requestBody: WorkerReviewRequest
): Promise<WorkerReviewDetails> => {
  const { body } = await request
    .post(`${workerReviewServiceUrl}/reviews`)
    .set(await getAuthHeader())
    .send(requestBody);
  return body;
};

export const fetchExclusion = async (
  requestBody: GetExclusionRequest
): Promise<ExclusionForList[]> => {
  const response = await request
    .get(`${global.api}/exclusion`)
    .set(await getAuthHeader())
    .query(qs.stringify(requestBody, { arrayFormat: "brackets" }));
  return response.body;
};

export const createExclusion = async (
  requestBody: CreateExclusionRequest
): Promise<ExclusionForView> => {
  const response = await request
    .post(`${global.api}/exclusion`)
    .set(await getAuthHeader())
    .send(requestBody);
  return response.body;
};

export const deleteExclusion = async (exclusionId: string, deleteNote?: string) => {
  const response = await request
    .delete(`${global.api}/exclusion/${exclusionId}`)
    .set(await getAuthHeader())
    .send({ deleteNote });
  return response.body;
};
