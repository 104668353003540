import firebase from "firebase/app";
import "firebase/auth";
import meta from "./meta";

const initFirebaseAuth = () => {
  const { firebaseConfig } = meta();
  const app = firebase.initializeApp(firebaseConfig);
  return app.auth();
};

const FirebaseAuth = initFirebaseAuth();
export { FirebaseAuth };
