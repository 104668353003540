import { useCurrentUserId } from "@src/appV2/ExperimentalHomeHealth/lib/useCurrentUserId";
import { get } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { UseQueryOptions, useQuery, useQueryClient } from "@tanstack/react-query";
import { getVisitQueryKey } from "../../Cases/api/useVisit";
import { VisitOccurrence } from "../../Cases/types";
import {
  VisitOccurrencesParams,
  visitOccurrencesResponseSchema,
  visitOccurrencesParamsSchema,
} from "../types";
import { isDefined } from "@clipboard-health/util-ts";

const VISIT_OCCURRENCES_URL = `${environmentConfig.REACT_APP_HOME_HEALTH_API_URL}/v1/:workplaceId/visit-occurrences`;

export function getVisitOccurrencesQueryKey(params: {
  workplaceId: string;
  params: VisitOccurrencesParams;
}) {
  const keyWithFilter = [
    "home-health",
    params.workplaceId,
    "visit-occurrences",
    params.params.filter,
  ];

  if (isDefined(params.params.sort)) {
    keyWithFilter.push(params.params.sort);
  }

  return keyWithFilter;
}

export function useVisitOccurrences(
  params: VisitOccurrencesParams,
  options: UseQueryOptions<VisitOccurrence[], unknown, VisitOccurrence[]> = {}
) {
  const queryClient = useQueryClient();
  const workplaceId = useCurrentUserId() ?? "";

  return useQuery({
    queryKey: getVisitOccurrencesQueryKey({ workplaceId, params }),
    queryFn: async () => {
      const response = await get({
        url: VISIT_OCCURRENCES_URL.replace(":workplaceId", workplaceId),
        requestSchema: visitOccurrencesParamsSchema,
        queryParams: params,
        responseSchema: visitOccurrencesResponseSchema,
      });

      return response.data.data;
    },
    retry: 0,
    staleTime: 1000 * 60 * 5,
    refetchInterval: 1000 * 60 * 10,
    onSuccess: (visits) => {
      visits.forEach((visit) => {
        queryClient.setQueryData(getVisitQueryKey({ workplaceId, visitId: visit.id }), visit);
      });
    },
    ...options,
  });
}
