import { z } from "zod";
import {
  ExtraWorkedTimeRequestLiterals,
  ExtraWorkedTimeRequestPayer,
  ExtraWorkedTimeRequestStatus,
  ExtraWorkedTimeRequestStatusUpdater,
  ExtraWorkedTimeType,
  IncludedForExtraWorkedTimeRequestType,
} from "./types";

const extraWorkedTimeRequestAttributesSchema = z.object({
  type: z.nativeEnum(ExtraWorkedTimeType),
  agentId: z.string(),
  facilityId: z.string(),
  shiftId: z.string(),
  start: z.string(),
  end: z.string(),
  reason: z.string(),
  authorizingEmployee: z
    .object({
      name: z.string(),
      jobTitle: z.string().nullish(),
    })
    .nullish(),
  status: z.nativeEnum(ExtraWorkedTimeRequestStatus),
  statusUpdatedBy: z.nativeEnum(ExtraWorkedTimeRequestStatusUpdater).nullish(),
  statusUpdatedById: z.string().nullish(),
  statusUpdateReason: z.string().nullish(),
  billedTo: z.nativeEnum(ExtraWorkedTimeRequestPayer).nullish(),
  updatedAt: z.string().nullish(),
});

const extraWorkedTimeRequestMetaSchema = z.object({
  computedPay: z.number(),
  computedCharge: z.number(),
  computedExpirationTime: z.string(),
});

const extraWorkedTimeRequestSchema = z.object({
  id: z.string(),
  type: z.literal(ExtraWorkedTimeRequestLiterals.TYPE),
  attributes: extraWorkedTimeRequestAttributesSchema,
  meta: extraWorkedTimeRequestMetaSchema,
});

const includedFacilityProfileAttributesForExtraWorkedTimeRequestSchema = z.object({
  extraWorkedTimeConfig: z
    .object({
      [ExtraWorkedTimeType.BREAK]: z
        .object({
          isAutoDeclinationEnabled: z.boolean().optional(),
          autoDeclinationReason: z.string().nullish(),
        })
        .nullish(),
    })
    .nullish(),
});

const includedFacilityProfileForExtraWorkedTimeRequestSchema = z.object({
  type: z.literal(IncludedForExtraWorkedTimeRequestType.FACILITY_PROFILE),
  id: z.string(),
  attributes: includedFacilityProfileAttributesForExtraWorkedTimeRequestSchema,
});

const includedFacilityUserAttributesForExtraWorkedTimeRequestSchema = z.object({
  name: z.string().nullish(),
});

const includedFacilityUserForExtraWorkedTimeRequestSchema = z.object({
  type: z.literal(IncludedForExtraWorkedTimeRequestType.FACILITY_USER),
  id: z.string(),
  attributes: includedFacilityUserAttributesForExtraWorkedTimeRequestSchema,
});

const includedShiftAttributesForExtraWorkedTimeRequestSchema = z.object({
  start: z.string(),
  end: z.string(),
  pay: z.number(),
  charge: z.number(),
  facility: z.object({
    name: z.string(),
    geoLocation: z.object({
      coordinates: z.tuple([z.number(), z.number()]),
      type: z.literal("Point"),
    }),
    state: z.string(),
    tmz: z.string(),
  }),
  agentReq: z.string(),
  name: z.string(),
  agent: z.object({
    name: z.string(),
  }),
  timecard: z
    .object({
      digitallySignedBy: z.string(),
      status: z.string(),
      createdAt: z.string(),
      uploadedBy: z.string(),
      files: z
        .array(
          z.object({
            fileStorageFileKey: z.string(),
            filename: z.string(),
            signedType: z.string(),
            timesheet: z.string(),
            url: z.string(),
          })
        )
        .nullish(),
    })
    .nullish(),
});

const includedShiftForExtraWorkedTimeRequestSchema = z.object({
  type: z.literal(IncludedForExtraWorkedTimeRequestType.SHIFT),
  id: z.string(),
  attributes: includedShiftAttributesForExtraWorkedTimeRequestSchema,
});

const includedForExtraWorkedTimeRequestSchema = includedShiftForExtraWorkedTimeRequestSchema
  .or(includedFacilityProfileForExtraWorkedTimeRequestSchema)
  .or(includedFacilityUserForExtraWorkedTimeRequestSchema);

export const extraWorkedTimeRequestsParamsSchema = z.object({
  filter: z
    .object({
      type: z.array(z.nativeEnum(ExtraWorkedTimeType)),
      status: z.array(z.nativeEnum(ExtraWorkedTimeRequestStatus)).nullish(),
      shiftId: z.array(z.string()).nullish(),
      facilityId: z.array(z.string()).nullish(),
      billedTo: z.array(z.nativeEnum(ExtraWorkedTimeRequestPayer)).nullish(),
    })
    .nullish(),
  page: z
    .object({
      cursor: z.string().nullish(),
      size: z.number().nullish(),
    })
    .nullish(),
});

export const extraWorkedTimeRequestsResponseSchema = z.object({
  data: z.array(extraWorkedTimeRequestSchema),
  included: z.array(includedForExtraWorkedTimeRequestSchema),
  meta: z.object({
    count: z.number(),
  }),
});

export const updateExtraWorkedTimeRequestAttributesSchema = z.object({
  status: z.nativeEnum(ExtraWorkedTimeRequestStatus).nullish(),
  statusUpdateReason: z.string().nullish(),
});

export const updateExtraWorkedTimeRequestDataSchema = z.object({
  id: z.string(),
  type: z.literal(ExtraWorkedTimeRequestLiterals.TYPE),
  attributes: updateExtraWorkedTimeRequestAttributesSchema,
});

export const updateExtraWorkedTimeRequestsResponseSchema = z.object({
  data: z.object({
    id: z.string(),
    type: z.literal(ExtraWorkedTimeRequestLiterals.TYPE),
    attributes: extraWorkedTimeRequestAttributesSchema,
  }),
});

export type ExtraWorkedTimeRequestMeta = z.infer<typeof extraWorkedTimeRequestMetaSchema>;
export type ExtraWorkedTimeRequest = z.infer<typeof extraWorkedTimeRequestSchema>;
export type ExtraWorkedTimeRequestAttributes = z.infer<
  typeof extraWorkedTimeRequestAttributesSchema
>;

export type IncludedShiftAttributesForExtraWorkedTimeRequest = z.infer<
  typeof includedShiftAttributesForExtraWorkedTimeRequestSchema
>;

export type IncludedShiftForExtraWorkedTimeRequest = z.infer<
  typeof includedShiftForExtraWorkedTimeRequestSchema
>;

export type IncludedFacilityUserAttributesForExtraWorkedTimeRequest = z.infer<
  typeof includedFacilityUserAttributesForExtraWorkedTimeRequestSchema
>;

export type IncludedFacilityUserForExtraWorkedTimeRequest = z.infer<
  typeof includedFacilityUserForExtraWorkedTimeRequestSchema
>;

export type IncludedFacilityProfileAttributesForExtraWorkedTimeRequest = z.infer<
  typeof includedFacilityProfileAttributesForExtraWorkedTimeRequestSchema
>;

export type IncludedFacilityProfileForExtraWorkedTimeRequest = z.infer<
  typeof includedFacilityProfileForExtraWorkedTimeRequestSchema
>;

export type IncludedForExtraWorkedTimeRequest = z.infer<
  typeof includedForExtraWorkedTimeRequestSchema
>;

export type ExtraWorkedTimeRequestsParams = z.infer<typeof extraWorkedTimeRequestsParamsSchema>;
export type ExtraWorkedTimeRequestsResponse = z.infer<typeof extraWorkedTimeRequestsResponseSchema>;

export type ExtraWorkedTimeRequestUpdateData = z.infer<
  typeof updateExtraWorkedTimeRequestDataSchema
>;
export type ExtraWorkedTimeRequestsUpdateResponse = z.infer<
  typeof updateExtraWorkedTimeRequestsResponseSchema
>;
