import { DollarOutlined } from "@ant-design/icons";
import { Button, Layout } from "antd";
import styled from "styled-components";

const { Header } = Layout;

const backgroundColor = "#001529";
const mediaMaxWidth = "992px";

export const HeaderContainer = styled(Header)`
  display: block;
  justify-content: space-between;
  padding: 0 10px;

  @media (max-width: ${mediaMaxWidth}) {
    display: block;
  }
`;

export const RightContainerOfHeader = styled.div`
  display: flex;
  align-items: center;
  float: right;
  min-width: fit-content;
  height: 100%;
`;

export const NotificationsButton = styled(Button)`
  background-color: ${backgroundColor};
  border: 1px solid white;
  color: white;
  float: right;
  margin-right: 30px;

  &:hover {
    border: 1px solid #d9d9d9;
    color: ${backgroundColor};
  }
`;

export const CornerRibbon = styled.div`
  position: relative;
`;

export const CornerRibbonInner = styled.div`
  /* Displayed at the top left corner */
  left: 0px;
  position: absolute;
  top: 0px;

  /* Size */
  height: 10rem;
  width: 10rem;

  /* Hide the part of its children which is displayed outside */
  overflow: hidden;
`;

export const CornerRibbonContent = styled.div`
  /* Position */
  left: 1rem;
  position: absolute;
  top: 1rem;

  /* Size */
  height: 1.5rem;
  width: 5.656rem;

  /* Displayed diagonally */
  transform: translate(-38px, -8px) rotate(-45deg);

  /* Background color */
  background-color: #d1d5db;

  /* Center the text content */
  text-align: center;
  font-size: 1rem;
`;

export const ReferralDollarOutlined = styled(DollarOutlined)`
  & {
    color: #00ff00;
  }
`;
