import { useCallback } from "react";
import { useLocalStorage } from "usehooks-ts";

export interface RecentlyVisitedPage {
  id: string;
  title: string;
  type: string;
}

const MAX_RECENT_ITEMS = 4;

/**
 * Hook to manage the recently visited pages.
 * We store the current page in the list, but we don't show it in the list until the next page is added.
 *
 * This unfortunately doesn't use URLs, but a basic object with an id, title, and type.
 */
export function useRecentlyVisited(): [
  RecentlyVisitedPage[] | undefined,
  (item: RecentlyVisitedPage) => void
] {
  const [recent, setRecent] = useLocalStorage<RecentlyVisitedPage[]>("cbh.recently-visited", []);

  const push = useCallback(
    (item: RecentlyVisitedPage) => {
      setRecent(() => {
        const existing = recent.find((existingItem) => existingItem.id === item.id);
        if (existing) {
          return recent;
        }

        return [item, ...recent].slice(0, MAX_RECENT_ITEMS);
      });
    },
    [recent, setRecent]
  );

  return [recent, push];
}
