import { z } from "zod";

export const agentProfileSchema = z.object({
  userId: z.string(),
  name: z.string(),
  email: z.string(),
  phone: z.string(),
  qualification: z.string(),
  profileImageUrl: z.string().nullish(),
});

export type AgentProfile = z.infer<typeof agentProfileSchema>;
