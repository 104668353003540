import styled from "styled-components";
import { Modal, Typography } from "antd";
import { Alert } from "src/designsystem/Alert/Alert";
import { BREAKPOINTS } from "src/designsystem/variables";

export const WorkplaceSignContractContainer = styled.div`
  width: calc(100% - 24px);
  margin: auto;
  @media (max-width: ${BREAKPOINTS.md}) {
    width: 100%;
  }
  height: 100%;
  .ant-modal-content button.ant-modal-close {
    position: absolute;
    top: 16px;
    right: 12px;
  }
`;

export const WorkplaceSignContractContainerHeader = styled.div`
  display: flex;
  align-items: center;
  font-size: 32px;
  position: relative;
  margin-bottom: 24px;
  @media (max-width: ${BREAKPOINTS.md}) {
    right: 0px;
    font-size: 16px;
    margin-top: 8px;
    margin-bottom: 4px;
  }
  gap: 8px;
  * {
    margin-bottom: 0;
  }
`;

export const WorkplaceSignContractFormContainer = styled.div`
  margin-top: 16px;
  iframe {
    min-width: 100%;
    min-height: calc(100vh - 336px);
    @media (max-width: ${BREAKPOINTS.md}) {
      min-height: calc(100vh - 220px);
    }
    border-radius: 8px 8px 0 0;
    border: 1px solid #424242;
  }
`;

export const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 8px;
  }
  .ant-modal-body {
    padding: 16px 32px 32px;
  }
  &.workplace-sign-contract-share-modal .ant-modal-content button.ant-modal-close {
    top: 16px;
    right: 12px;
  }
  &.workplace-sign-contract-share-modal .ant-modal-content {
    width: 600px;
    @media (max-width: ${BREAKPOINTS.md}) {
      width: unset;
    }
  }
`;

export const ButtonGroup = styled.footer`
  display: flex;
  width: 100%;
  margin-top: 24px;
  flex-direction: row;
  justify-content: end;
  gap: 16px;
`;

export const WorkplaceShareContractContainer = styled.div`
  & .workplace-share-contract > div {
    display: grid;
    margin-bottom: 0;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr) 2fr;
    column-gap: 10px;
    @media (max-width: ${BREAKPOINTS.sm}) {
      grid-template-columns: auto;
      grid-template-rows: auto;
    }
    div:last-child {
      grid-column: span 2;
    }
  }
  .ant-modal-header .ant-modal-title h3 {
    margin-bottom: 0;
  }
  .ant-modal-header {
    border-bottom-color: #bdbdbd;
    border-radius: 8px 8px 0 0; // radius on top to clear the radius on ant-body
  }
  & textarea {
    height: 100%;
    width: 100%;
    grid-row: span 2;
    border: 1px solid #868686;
    box-shadow: none;
  }

  & input {
    border: 1px solid #868686;
    box-shadow: none;
  }
`;

export const WorkplaceContractFormModalTitle = styled(Typography.Title)`
  &.ant-typography {
    color: #424242;
    font-size: 20px;
    font-weight: 400;
    margin-top: 16px;
  }
`;

export const WorkplaceContractFormModalHelpText = styled.p`
  font-weight: 300;
`;

export const StyledAlert = styled(Alert)`
  background: #eaf2fd;
  border-color: #032e68;
  .ant-alert-content .ant-alert-message {
    display: flex;
    flex-direction: column;
    align-items: start;
    p {
      color: #333333;
      a {
        color: #032e68;
      }
    }
    p:last-child {
      margin-bottom: 0;
    }
  }
`;
