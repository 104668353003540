import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { isDefined } from "@clipboard-health/util-ts";
import { get } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { useCurrentUserId } from "@src/appV2/ExperimentalHomeHealth/lib/useCurrentUserId";
import { AgentProfile, agentProfileSchema } from "@src/appV2/Worker/types";

export function useWorker(
  workerId?: string,
  options: UseQueryOptions<AgentProfile, unknown, AgentProfile> = {}
) {
  const workplaceId = useCurrentUserId();
  const url = `${environmentConfig.REACT_APP_API_MAIN_URL}/api/agentprofile/${workerId}/facility/${workplaceId}`;

  return useQuery({
    queryKey: [url],
    queryFn: async () => {
      const response = await get({
        url,
        responseSchema: agentProfileSchema,
      });

      return response.data;
    },
    retry: 0,
    staleTime: 1000 * 60 * 5,
    refetchInterval: 1000 * 60 * 10,
    ...options,
    enabled: isDefined(workerId) && isDefined(workplaceId) && options.enabled,
  });
}
