import React, { ErrorInfo } from "react";

import "./style.css";
import { logError } from "../../appV2/lib/analytics";
import meta from "../../utils/meta";

const { supportEmail } = meta();

interface State {
  error: any;
}

export class ErrorBoundary extends React.Component<any, State> {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
    };
  }

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    /**
     * Taken from https://docs.datadoghq.com/real_user_monitoring/browser/collecting_browser_errors/?tab=npm#react-error-boundaries-instrumentation
     */
    const renderingError = new Error(error.message);

    renderingError.name = `ReactRenderingError`;
    renderingError.stack = errorInfo.componentStack;
    // We need a type cast here because although DataDog understands "cause"
    // for TypeScript is not a valid property of Error.
    // Targeting ES2022 and adjusting browser compatibility might resolve this.
    // See StackOverflow discussion for more details: https://stackoverflow.com/questions/73378375/error-cause-property-not-recognized-in-typescript
    (renderingError as Error & { cause: Error }).cause = error;

    logError("React Error Boundary", { error: renderingError });
  }

  render() {
    return this.state.error ? (
      <h2 className="error-fallback">
        <span>
          We are sorry, but there seems to be an issue with that request. Please reload and try
          again. If the issue persists please contact Clipboard Health support at{" "}
          <b>{supportEmail} | 408-837-0116</b>
          <br />
        </span>
      </h2>
    ) : (
      this.props.children
    );
  }
}
