import { Dispatch } from "redux";

export type WorkplaceContractState = {
  showModal: boolean;
  triggeredBy?:
    | "post bulk shifts"
    | "create shift block"
    | "submit a shift"
    | "save facility information"
    | "update facility information"
    | "edit notifications"
    | "add new member"
    | "favorite a worker"
    | "unfavorite a worker"
    | "dnr a worker"
    | "view worker's reviews"
    | "offer a block of shifts"
    | "search workers"
    | "filter workers"
    | "update settings"
    | "edit account"
    | "update status"
    | "delete nfc tag"
    | "update photos"
    | "play video"
    | "upload calendar"
    | "worker chat";
  triggeredFrom?:
    | "Calendar"
    | "Facility Information"
    | "My Account"
    | "Team Members"
    | "Worker Directory"
    | "Worker Directory Profile"
    | "Get Started"
    | "Settings"
    | "Available Workers List";
};

const initialState: WorkplaceContractState = {
  showModal: false,
};

type SetupInitialState = {
  type: "SETUP_INITIAL_WORKPLACE_CONTRACT_STATE";
  data: WorkplaceContractState;
};

export type OpenWorkplaceContractModal = {
  type: "OPEN_WORKPLACE_CONTRACT_MODAL";
  data: Pick<WorkplaceContractState, "triggeredBy" | "triggeredFrom">;
};

type CloseWorkplaceContractModal = {
  type: "CLOSE_WORKPLACE_CONTRACT_MODAL";
};

type WorkplaceContractAction =
  | SetupInitialState
  | OpenWorkplaceContractModal
  | CloseWorkplaceContractModal;

export default (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: WorkplaceContractState = initialState,
  action: WorkplaceContractAction
): WorkplaceContractState => {
  switch (action.type) {
    case "SETUP_INITIAL_WORKPLACE_CONTRACT_STATE":
      return {
        ...initialState,
      };
    case "OPEN_WORKPLACE_CONTRACT_MODAL":
      return {
        ...initialState,
        ...action.data,
        showModal: true,
      };
    case "CLOSE_WORKPLACE_CONTRACT_MODAL":
      return {
        ...initialState,
        showModal: false,
      };
    default:
      return state;
  }
};

export const openWorkplaceContractModal = (data: OpenWorkplaceContractModal["data"]) => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: "OPEN_WORKPLACE_CONTRACT_MODAL",
      data,
    });
  };
};

export const closeWorkplaceContractModal = () => {
  return (dispatch: Dispatch) => {
    dispatch({
      type: "CLOSE_WORKPLACE_CONTRACT_MODAL",
    });
  };
};
