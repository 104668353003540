import { ModalCommonProps } from "./types";
import { Button } from "src/designsystem/Button/Button";
import {
  ButtonGroup,
  StyledModal,
  WorkplaceContractFormModalTitle,
  WorkplaceContractFormModalHelpText,
} from "./styles";

function WorkplaceSignContractFallback({ closeModal, onSubmit }: ModalCommonProps) {
  const handleOk = () => {
    closeModal();
    onSubmit();
  };
  return (
    <StyledModal visible footer={null} onCancel={closeModal} cancelText={null}>
      <WorkplaceContractFormModalTitle>
        You should not be landing on this page.
      </WorkplaceContractFormModalTitle>
      <WorkplaceContractFormModalHelpText>
        To proceed forward, please close this popup and continue.
      </WorkplaceContractFormModalHelpText>
      <ButtonGroup>
        <Button variant="primary" onClick={handleOk}>
          OK
        </Button>
      </ButtonGroup>
    </StyledModal>
  );
}

export { WorkplaceSignContractFallback };
