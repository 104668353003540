import { Carousel } from "antd";
import { primitives } from "../../../designsystem/primitives";
import styled from "styled-components";

export const CarouselWrapper = styled(Carousel)({
  "> .slick-list": {
    borderRadius: "12px",
    backgroundColor: "transparent",
    boxShadow: primitives.boxShadow.default,
  },
  "> .slick-dots li button": {
    backgroundColor: "#111",
  },
  "> .slick-dots ul": {
    margin: 0,
    display: "flex",
    alignItems: "center",
  },
  "> .slick-dots li.slick-active button": {
    backgroundColor: "#000",
  },
  "> .slick-dots-bottom": {
    bottom: "0px",
  },
});
