import { z } from "zod";

export enum ShiftInviteStatus {
  ACCEPTED = "ACCEPTED",
  DECLINED = "DECLINED",
  DELETED = "DELETED",
  PENDING = "PENDING",
}

const shiftInviteDetailsSchema = z.object({
  start: z.coerce.date(),
  end: z.coerce.date(),
  agentReq: z.string(),
  name: z.string().nullish(),
  tmz: z.string().optional(),
  customChargeRateBoost: z.number().optional(),
});

export type ShiftInviteDetails = z.infer<typeof shiftInviteDetailsSchema>;

export const shiftInviteAttributeSchema = z.object({
  workerId: z.string(),
  workplaceId: z.string(),
  shiftDetails: shiftInviteDetailsSchema,
  postShiftToMarketplace: z.boolean(),
  groupId: z.string().optional(),
});

export const shiftInviteRequestSchema = z.object({
  data: z.object({
    attributes: shiftInviteAttributeSchema,
    type: z.string(),
  }),
});

export interface ShiftInviteRequestParams {
  shiftInviteRequest: z.infer<typeof shiftInviteAttributeSchema>;
}

export const shiftInviteValidationResponseSchema = z.object({
  data: z.object({
    attributes: z.object({
      status: z.string(),
    }),
  }),
});

export const chargeBreakdownSchema = z.object({
  holiday: z.number(),
  baseCharge: z.number(),
  customRateBoost: z.number().optional(),
});

const shiftInviteChargeDetailsSchema = shiftInviteDetailsSchema.extend({
  charge: z.number(),
  chargeBreakdown: chargeBreakdownSchema,
});

export interface CheckShiftInviteDetail extends z.infer<typeof shiftInviteChargeDetailsSchema> {}

export const checkShiftInviteAttributeSchema = z.object({
  workerId: z.string(),
  workplaceId: z.string(),
  shiftDetails: shiftInviteChargeDetailsSchema,
  postShiftToMarketplace: z.boolean(),
  groupId: z.string().optional(),
});

export const checkShiftInviteDetailsResponseSchema = z.object({
  data: z.object({
    attributes: checkShiftInviteAttributeSchema,
  }),
});

export interface CheckShiftInviteDetailResponse
  extends z.infer<typeof checkShiftInviteDetailsResponseSchema> {}
