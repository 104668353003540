import styled from "styled-components";

const handleBackgroundColorVariant = (variant) => {
  switch (variant) {
    case "primary":
      return "#2563eb";
    case "secondary":
      return "#ffffff";
    default:
      return "#2563eb";
  }
};

const handleOnBackgroundColorVariant = (variant) => {
  switch (variant) {
    case "primary":
      return "#ffffff";
    case "secondary":
      return "#000000";
    default:
      return "#ffffff";
  }
};

export const StyledPopover = styled.div<{
  variant: "primary" | "secondary";
  width?: number;
}>`
  position: relative;
  .ant-popover {
    z-index: 2;
  }
  .ant-popover-inner {
    width: ${({ width }) => (width ? `${width}px` : "220px")};
    border-radius: 8px;
    background-color: ${({ variant }) => handleBackgroundColorVariant(variant)};
  }
  .ant-popover-arrow-content {
    background-color: ${({ variant }) => handleBackgroundColorVariant(variant)};
    &::before {
      background: ${({ variant }) => handleBackgroundColorVariant(variant)};
    }
  }
`;

export const Wrapper = styled.div<{
  variant: "primary" | "secondary";
}>`
  width: 100%;
  display: flex;
  gap: 16px;
  font-size: 12px;
  color: ${({ variant }) => handleOnBackgroundColorVariant(variant)};
  align-items: flex-start;
  justify-content: space-between;
  > div {
    font-size: 16px;
    cursor: pointer;
  }
`;
