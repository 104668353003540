import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { loadSession } from "./modules/session";
import rootReducer from "./modules";
import { NodeEnvironment } from "./appV2/environment";

const state = {
  session: loadSession(),
};

const enhancers: any[] = [];
const middleware = [thunk];

if (process.env.NODE_ENV === NodeEnvironment.DEVELOPMENT) {
  /* @ts-expect-error FIXME this wrong type */
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === "function") {
    enhancers.push(devToolsExtension({ trace: true }));
  }
}

export const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

const store = createStore(rootReducer, state, composedEnhancers);

export default store;
