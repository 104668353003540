import { History } from "history";
import { PreferredWorker, ShiftCategory } from "src/api/workers";

export function getShiftCategory(
  value: string | undefined | null,
  defaultValue: ShiftCategory
): ShiftCategory {
  switch (value) {
    case "past":
      return ShiftCategory.PAST;

    case "in_progress":
      return ShiftCategory.IN_PROGRESS;

    case "favorite":
      return ShiftCategory.FAVORITE;

    case "upcoming":
      return ShiftCategory.UPCOMING;

    default:
      return defaultValue;
  }
}

export function formatRatingNumber(value: number) {
  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 1,
  }).format(value);
}

export const updateSearchParams = (
  opts: { search?: string; category?: string },
  pathname: string,
  history: History
) => {
  const searchParams = new URLSearchParams();
  Object.keys(opts).forEach((key) => {
    if (opts[key] !== undefined) {
      searchParams.set(key, opts[key]);
    }
  });

  history.push({
    pathname,
    search: `?${searchParams.toString()}`,
  });
};

export function isFavoritedReason(reason: PreferredWorker["reason"] | undefined): boolean {
  return reason === "FAVORITE" || reason === "RATING";
}
