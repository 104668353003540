import { useHighRatingWorkers } from "src/containers/workplace/WorkersDirectory/useHighRatingWorkers";
import { SessionType } from "src/modules/interface";
import { useEffect, useState } from "react";
import moment from "moment-timezone";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";

const useFacilityOnboardingFlag = (
  profile: SessionType["session"]["profile"] | undefined,
  hasPermission: boolean,
  isAdmin: boolean,
  isFacilityType: boolean
): boolean => {
  const [loadWorkersDirectory, setLoadWorkersDirectory] = useState(false);
  const { minimumWorkplaceCreationDate } = useCbhFlag(CbhFeatureFlag.HCF_ONBOARDING, {
    defaultValue: {},
  });
  useEffect(() => {
    if (
      !profile?.createdAt ||
      !minimumWorkplaceCreationDate ||
      !profile.onboardingSteps ||
      !hasPermission
    ) {
      return;
    }

    const facilityCreatedAt = moment.tz(profile.createdAt, profile.tmz);
    const minimumCreationDate = moment.tz(minimumWorkplaceCreationDate, profile.tmz);

    const facilityWithinTimeRange = facilityCreatedAt.isAfter(minimumCreationDate);

    if (facilityWithinTimeRange) {
      setLoadWorkersDirectory(true);
    }
  }, [
    profile?.createdAt,
    profile?.tmz,
    minimumWorkplaceCreationDate,
    profile?.onboardingSteps,
    hasPermission,
    setLoadWorkersDirectory,
  ]);

  // This below query is made to get the cached data that internally uses useQuery.
  const { isLoading, data, isError } = useHighRatingWorkers(
    isFacilityType && hasPermission && loadWorkersDirectory ? profile?.userId ?? "" : "",
    {
      pageSize: 2,
    },
    isAdmin
  );

  return !isLoading && !!data.length && !isError;
};

export { useFacilityOnboardingFlag };
