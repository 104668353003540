import { Text } from "@clipboard-health/ui-react";
import { useDisplayTime } from "./useDisplayTime";

export interface LocalTimeProps {
  timeZone?: string;
}

export function LocalTime(props: LocalTimeProps) {
  const { timeZone } = props;
  const localTime = useDisplayTime({ timeZone });

  return (
    <Text color="white" variant="body2">
      Local time: {localTime}
      {timeZone ? ` (${timeZone}) ` : null}
    </Text>
  );
}
