import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LicenseInfo } from "@mui/x-license-pro";
import { MuiSnackBarProvider } from "@src/appV2/lib";
import { MuiThemeProvider } from "@src/appV2/lib/Theme";
import { environmentConfig } from "@src/appV2/environment";

LicenseInfo.setLicenseKey(environmentConfig.REACT_APP_MUI_LICENSE_KEY);

interface MaterialUiProvidersProps {
  children: React.ReactNode;
}
export function MaterialUiProviders(props: MaterialUiProvidersProps) {
  const { children } = props;
  // Material UI components may require multiple providers to work properly.
  // In this component, all of them are grouped:
  // 1. MuiThemeProvider for theme styling
  // 2. LocalizationProvider for DateRange picker
  // 3. MuiSnackbarProvider for notistack toasts
  return (
    <MuiThemeProvider>
      <MuiSnackBarProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>{children}</LocalizationProvider>
      </MuiSnackBarProvider>
    </MuiThemeProvider>
  );
}
