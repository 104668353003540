import React, { useState, useEffect, useMemo } from "react";
import { UserAddOutlined } from "@ant-design/icons";
import { PageHeader, Button } from "antd";
import moment from "moment-timezone";

import { includes } from "lodash";

import { ModifyFacilityUserModal } from "./modifyUser";
import { UserTab } from "./userTab";
import { useSelector } from "react-redux";
import { HCF_USER_EVENTS } from "../../constants/firebaseEvents";
import { logEvent } from "@src/appV2/lib/analytics";
import { getUser } from "../../api/user";
import { FACILITY_ROLES } from "../../constants/facility";
import { useHistory } from "react-router-dom";
import { getLocation } from "src/utils/routes";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";

function FacilityUser({ facilityId, facility }) {
  const [showModal, setShowModal] = useState(false);
  const [refresh, setRefresh] = useState(null);
  const [userToEdit, setUserToEdit] = useState(null);
  const { userId, admin, user } = useSelector((state: any) => state.session);
  const history = useHistory();
  const isMyAccountPageEnabled = useCbhFlag(CbhFeatureFlag.MY_ACCOUNT_PAGE, {
    defaultValue: false,
  });

  const [currentFacility, setCurrentFacility] = useState<any>(null);

  // User with supervisor role (FACILITY_ROLES.DOCUMENT)
  // can not see the add user button.
  const canAddUser = useMemo(() => {
    return (
      admin ||
      includes(user.access, FACILITY_ROLES.ADMIN) ||
      includes(user.access, FACILITY_ROLES.INVOICES) ||
      includes(user.access, FACILITY_ROLES.SHIFT_MANAGEMENT)
    );
  }, [user, admin]);

  useEffect(() => {
    if (facility) {
      setCurrentFacility(facility);
      return;
    }
    (async () => {
      const user = await getUser(facilityId ?? userId);
      setCurrentFacility(user.facility);
    })();
  }, [facility, facilityId, userId]);

  useEffect(() => {
    if (!admin) {
      logEvent(HCF_USER_EVENTS.USERS_VIEWED);
    }
  }, []);

  const openModal = () => setShowModal(true);
  const closeModal = () => {
    setShowModal(false);
    setUserToEdit(null);
  };

  const onUpdate = () => {
    setRefresh(moment().format());
    closeModal();
  };

  const onEdit = (currentUser) => () => {
    if (isMyAccountPageEnabled && currentUser._id === user._id) {
      history.push(getLocation("myAccount"));
    }
    setUserToEdit(currentUser);
    openModal();
  };

  return (
    <>
      <PageHeader
        title="Users"
        extra={
          canAddUser && (
            <Button
              type="primary"
              icon={<UserAddOutlined />}
              data-testid="add-new-user-button"
              onClick={openModal}
            >
              New User
            </Button>
          )
        }
      />
      <UserTab refresh={refresh} onEdit={onEdit} facilityId={facilityId || userId} />
      <ModifyFacilityUserModal
        visible={showModal}
        onUpdate={onUpdate}
        onCancel={closeModal}
        userToEdit={userToEdit}
        facilityId={facilityId ?? userId}
        facility={currentFacility}
      />
    </>
  );
}

export { FacilityUser };
