import { FirebaseAuth } from "../utils/firebase";
import { APP_V2_APP_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { isDefined } from "@clipboard-health/util-ts";

export async function getAuthHeader() {
  let token: string | null = (await FirebaseAuth.currentUser?.getIdToken()) ?? null;

  if (!token) {
    token = localStorage.getItem("AUTH_TOKEN");

    logEvent(APP_V2_APP_EVENTS.AUTH_TOKEN_NOT_AVAILABLE, {
      hasLocalStorageToken: isDefined(token),
    });
  }
  /**
   * ***** WARNING ****
   *  `superagent` requires Authorization to either be a string, or null
   * DO NOT SET `Authorization` to `undefined` because you will break sign in flow
   * See https://app.datadoghq.com/incidents/102
   */
  return { Authorization: token };
}
