import styled from "styled-components";
import { Rate as AntdRate } from "antd";

export const Rate = styled(AntdRate)`
  color: #032e68;

  & > .ant-rate-star:not(:last-child) {
    margin-right: 4px;
  }
`;
