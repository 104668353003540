import React, { useState, useEffect } from "react";
import { Form, Row, Button, PageHeader, Divider, Typography } from "antd";
import ShouldRender from "./ShouldRender";
import axios from "axios";
import { environmentConfig } from "@src/appV2/environment";
import { showErrorToast, showSuccessToast } from "@src/appV2/lib/Notifications";

const { Title } = Typography;

function TriggerQfa() {
  const [isGetData, setIsGetData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hcpId, setHcpid] = useState("");
  const [form] = Form.useForm();

  useEffect(() => {
    getAgentProfile();
  }, []);

  const getAgentProfile = async () => {
    setIsGetData(true);
    try {
      if (localStorage.getItem("authToken")) {
        const agent = await axios.get(
          `${environmentConfig.REACT_APP_API_MAIN_URL || ""}/api/agentProfile`,
          {
            headers: {
              Authorization: localStorage.getItem("authToken"),
            },
          }
        );
        if (agent.data) {
          setHcpid(agent.data.userId);
        }
      } else if (JSON.parse(localStorage.getItem("state") ?? "").type === "AGENT") {
        const userID = JSON.parse(localStorage.getItem("state") ?? "").userId;
        setHcpid(userID);
      } else {
        showErrorToast(`Cannot get HCP id`);
      }
    } catch (error) {
      console.error("Action Resp Error: ", error);
      showErrorToast(`Test panel Error ${(error as Error).message}`);
    } finally {
      setIsGetData(false);
    }
  };

  const onSubmit = async () => {
    setLoading(true);
    try {
      const resp = await axios.post(
        `${environmentConfig.REACT_APP_API_MAIN_URL || ""}/api/testHelpers/account/triggerqfa`,
        {
          hcpId,
        }
      );
      if (resp.data?.success) {
        showSuccessToast(`Triggered QFA successfully`);
      } else {
        showErrorToast(`Failed to change address and trigger QFA`);
      }
    } catch (error) {
      console.error("Action Resp Error: ", error);
      showErrorToast(`Test panel Error ${(error as Error).message}`);
    } finally {
      setLoading(false);
    }
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  return (
    <div>
      <PageHeader className="pageHeader" title="Trigger QFA" />
      <Divider className="TriggerQFAHeaderDivider" />
      <Row justify="start" align="middle" style={{ width: "100%", height: "100%" }}>
        <Form {...layout} name="basic" form={form} onFinish={onSubmit} style={{ width: "600px" }}>
          <Row>
            <Title level={4}>Trigger QFA</Title>
          </Row>
          <Row>
            {isGetData ? (
              <div>Getting required data...</div>
            ) : (
              <ShouldRender condition={hcpId}>
                <div>
                  This action will change the address once and then back to original to trigger QFA
                  cron job in order to show open shifts for HCP. Sometimes it might still take up to
                  1 min to see the shifts.
                </div>
              </ShouldRender>
            )}
          </Row>

          <Row justify="end" align="middle" style={{ marginTop: "1rem" }}>
            <Button type="primary" htmlType="submit" loading={loading}>
              Submit
            </Button>
          </Row>
        </Form>
      </Row>
    </div>
  );
}

export default TriggerQfa;
