import styled from "styled-components";
import { BannerProps } from "./Banner.type";
import { primitives } from "../primitives";

export const Wrapper = styled.div<Pick<BannerProps, "type">>(({ type }) => ({
  display: "flex",
  justifyContent: "space-between",
  padding: "18px 24px",
  borderRadius: "12px",
  color: "#424242",
  background: "#fff",
  flexDirection: "row",
  alignItems: "center",
  flexShrink: 0,
  flexWrap: "wrap",
  ...(type === "warning" && {
    border: `1px solid ${primitives.colors.red[200]}`,
  }),
}));

export const Action = styled.div({
  display: "flex",
  alignItems: "center",
  gap: "24px",
  width: "max-content",
});

export const BannerContent = styled.div({
  display: "flex",
  flexDirection: "column",
  gap: "2px",
});

export const BannerInfo = styled.div({
  display: "flex",
  gap: "16px",
});

export const BannerIcon = styled.div({
  display: "flex",
  fontSize: "24px",
  alignItems: "flex-start",
  paddingTop: "4px",
});
