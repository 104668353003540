import { isDefined } from "@clipboard-health/util-ts";
import { WorkplaceTypesResponse } from "../../WorkplaceTypes/types";

export function getQualificationNamesForWorkplaceType(
  workplaceTypes: WorkplaceTypesResponse,
  workplaceTypeName: string
): string[] {
  const workplaceType = workplaceTypes.data.find(
    (type) => type.attributes.name === workplaceTypeName
  );
  const qualificationsRels = workplaceType?.relationships.qualifications.data ?? [];
  return qualificationsRels
    .map((rel) => {
      const qualification = workplaceTypes.included.find((item) => item.id === rel.id);
      return qualification?.attributes.name;
    })
    .filter((name): name is string => isDefined(name));
}
