import { ThemeProvider } from "@mui/material/styles";
import { type ReactElement, type ReactNode } from "react";

import { getTheme } from "./theme";

const theme = getTheme("light");

interface Props {
  readonly children: ReactNode;
}
export function MuiThemeProvider(props: Props): ReactElement {
  const { children } = props;

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
