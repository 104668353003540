export const primitives = {
  colors: {
    blue: {
      900: "#1e3a8a",
      800: "#1e40af",
      700: "#1d4ed8",
      600: "#2563eb",
      500: "#3b82f6",
      400: "#60a5fa",
      300: "#93c5fd",
      200: "#bfdbfe",
      100: "#dbeafe",
      50: "#eff6ff",
    },
    grey: {
      900: "#101925",
      800: "#1f2937",
      700: "#374151",
      600: "#4b5563",
      500: "#6b7280",
      400: "#9ca3af",
      300: "#d1d5db",
      200: "#e5e7eb",
      100: "#f3f4f6",
      50: "#f9fafb",
    },
    red: {
      100: "#fff5f5",
      200: "#fed7d7",
      700: "#f46565",
    },
    yellow: {
      700: "#f4a165",
    },
    green: {
      700: "#15803d",
    },
  },
  boxShadow: {
    default: "0 4px 10px rgba(55, 56, 60, 0.08), 0 0 2px rgba(55, 56, 60, 0.24)",
  },
};
