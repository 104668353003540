import { Modal } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import { Button } from "src/designsystem/Button/Button";
import { ReactElement } from "react";

export function NewWebVersionModal(): ReactElement {
  const handleRefreshClicked = () => {
    window.location.reload();
  };

  return (
    <Modal
      title="New Web Version Available"
      visible={true}
      footer={
        <Button variant="primary" onClick={handleRefreshClicked}>
          Refresh
        </Button>
      }
      closable={false}
    >
      <Paragraph>
        Please refresh your browser to continue using the latest features and improvements.
      </Paragraph>
    </Modal>
  );
}
