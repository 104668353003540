import { createHttpLink } from "@apollo/client";
import { getAuthHeader } from "../utils/superagent";
import { setContext } from "@apollo/client/link/context";
import { environmentConfig } from "@src/appV2/environment";

const httpLink = createHttpLink({
  uri: environmentConfig.REACT_APP_DOCUMENTS_SERVICE_URL,
});

const authLink = setContext(async () => {
  const token = await getAuthHeader();
  return {
    headers: {
      ...token,
    },
  };
});

const link = authLink.concat(httpLink);

export { link };
