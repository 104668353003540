import { Alert as AntdAlert } from "antd";
import styled from "styled-components";

export const Alert = styled(AntdAlert)<{ type: "info" | "warning" }>`
  margin: 12px 0 24px 0;
  border-radius: 8px;
  border-radius: 8px;
  margin: 8px 0;
  padding: 16px 14px;

  ${({ type }) =>
    type === "info"
      ? `
			border: 1px solid #0C98AC;
      background: #0C98AC50;
	`
      : type === "warning"
      ? `
      background: #F3E4CD;
      border: 1px solid #FFCC1680;
      `
      : undefined}

  .ant-alert-message {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
  }

  .ant-alert-icon {
    font-size: 20px;
    margin-right: 12px;
    border-radius: 100px;
    padding: 6px;

    svg {
      fill: #a10702;
    }
  }
`;
