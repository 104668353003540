import { FormInstance } from "antd";
import { FacilityRole } from "src/api/facilityRoles";
import { FacilityFlags } from "src/containers/facilityUser/helpers";
import { MyAccountStateType } from "src/modules/myAccount";
import { IFormValue, IModalData, IUser, TypeAlertSettings } from "src/modules/myAccount/types";

export enum MYACCOUNT_ACTIONS {
  LOADING = "LOADING",
  TOGGLE_FORM = "TOGGLE_FORM",
  SET_MODAL_DATA = "SET_MODAL_DATA",
  SET_USER = "SET_USER",
  SET_LOGOUT = "SET_LOGOUT",
  SET_FORM_DATA = "SET_FORM_DATA",
  TOGGLE_MODAL = "TOGGLE_MODAL",
  SET_FORM_REFERENCE = "SET_FORM_REFERENCE",
  TOGGLE_EMAIL_MODAL = "TOGGLE_EMAIL_MODAL",
  SET_EMAIL_MODAL_DATA = "SET_EMAIL_MODAL_DATA",
  SET_EXISTING_USER = "SET_EXISTING_USER",
  SET_ROLES = "SET_ROLES",
  RESET_STATE = "RESET_STATE",
  SET_USER_ROLES = "USER_ROLES",
  SET_REDIRECT_URL = "SET_REDIRECT_URL",
  SET_FLAGS = "SET_FLAGS",
  SET_ALERT_SETTINGS = "SET_ALERT_SETTINGS",
}

type ToggleLoadingAction = {
  type: MYACCOUNT_ACTIONS.LOADING;
  payload: "INIT" | "LOADING" | "DONE";
};

type ToggleFormAction = {
  type: MYACCOUNT_ACTIONS.TOGGLE_FORM;
  payload: boolean;
};

type ToggleModalAction = {
  type: MYACCOUNT_ACTIONS.TOGGLE_MODAL;
  payload: boolean;
};

type SetModalDataAction = {
  type: MYACCOUNT_ACTIONS.SET_MODAL_DATA;
  payload: IModalData;
};

type SetUserAction = {
  type: MYACCOUNT_ACTIONS.SET_USER;
  payload: IUser;
};

type SetFormDataAction = {
  type: MYACCOUNT_ACTIONS.SET_FORM_DATA;
  payload: FormData;
};

type SetFormReferenceAction = {
  type: MYACCOUNT_ACTIONS.SET_FORM_REFERENCE;
  payload: FormInstance<IFormValue>;
};

type ToggleEmailModalAction = {
  type: MYACCOUNT_ACTIONS.TOGGLE_EMAIL_MODAL;
  payload: boolean;
};

type SetEmailModalDataAction = {
  type: MYACCOUNT_ACTIONS.SET_EMAIL_MODAL_DATA;
  payload: {
    existingEmail: string;
    newEmail: string;
    consent: boolean;
  };
};

type SetExistingUserAction = {
  type: MYACCOUNT_ACTIONS.SET_EXISTING_USER;
  payload: IUser;
};

type SetRolesAction = {
  type: MYACCOUNT_ACTIONS.SET_ROLES;
  payload: FacilityRole[];
};

type SetUserRolesAction = {
  type: MYACCOUNT_ACTIONS.SET_USER_ROLES;
  payload: string;
};

type SetRedirectUrlAction = {
  type: MYACCOUNT_ACTIONS.SET_REDIRECT_URL;
  payload: string;
};

type SetFlagsAction = {
  type: MYACCOUNT_ACTIONS.SET_FLAGS;
  payload: FacilityFlags;
};

type SetAlertSettingsActions = {
  type: MYACCOUNT_ACTIONS.SET_ALERT_SETTINGS;
  payload: TypeAlertSettings;
};

type ResetStateAction = {
  type: MYACCOUNT_ACTIONS.RESET_STATE;
  payload: MyAccountStateType;
};

export type MyAccountActionTypes =
  | ToggleLoadingAction
  | ToggleFormAction
  | ToggleModalAction
  | SetModalDataAction
  | SetUserAction
  | SetFormDataAction
  | SetFormReferenceAction
  | ToggleEmailModalAction
  | SetEmailModalDataAction
  | SetExistingUserAction
  | SetRolesAction
  | SetUserRolesAction
  | SetRedirectUrlAction
  | SetFlagsAction
  | SetAlertSettingsActions
  | ResetStateAction;
