export const WorkerTypes = {
  CNA: "CNA",
  LVN: "LVN",
  RN: "RN",
  NP: "NP",
  CAREGIVER: "CAREGIVER",
  PACU_RN: "PACU RN",
  OR_RN: "OR RN",
  SPD_TECH: "SPD TECH",
  SURG_TECH: "SURG TECH",
  CRNA: "CRNA",
  PHLEBOTOMIST: "Phlebotomist",
  MLT: "MLT",
  MEDICAL_ASSISTANT: "Medical Assistant",
  PT: "PT",
  PTA: "PTA",
  HHA: "HHA",
  EMT: "EMT",
  RESPIRATORY_THERAPIST: "Respiratory Therapist",
  PARAMEDIC: "Paramedic",
  NON_CLINICAL: "Non Clinical",
  PHARM_TECH: "Pharm Tech",
  MEDICAL_TECHNICIAN: "Medical Technician",
  QMAP: "QMAP",
  MEDICAL_AIDE: "Medical Aide",
  SITE_LEAD: "Site Lead",
} as const;
export type WorkerTypeValues = typeof WorkerTypes[keyof typeof WorkerTypes];

export const NurseWorkerType = {
  NURSE: "NURSE",
};
