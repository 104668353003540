import { Box, type BoxProps } from "@mui/material";
import { forwardRef } from "react";

export type SpanProps = Omit<BoxProps, "component">;

export const Span = forwardRef((props: SpanProps, ref) => (
  <Box component="span" {...props} ref={ref} />
));

Span.displayName = "Span";
