import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";
import { fetchShiftTimes } from "src/containers/facilityDashboard/ShiftCalendar/api";

export function useShiftTimes(workplaceId?: string) {
  const queryKey = useMemo(() => ["shift-times", workplaceId], [workplaceId]);
  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn: () => {
      if (!workplaceId) {
        return undefined;
      }

      return fetchShiftTimes({ userId: workplaceId });
    },
    enabled: !!workplaceId,
  });

  const invalidateCache = useCallback(() => {
    queryClient.invalidateQueries(queryKey);
  }, [queryClient, queryKey]);

  return { data, isLoading, invalidateCache };
}
