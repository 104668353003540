import moment from "moment-timezone";

const SHIFT_ACTION_DEFAULT_YAML_VALUE = `shiftActions:
    -
        shiftId: 5b4caabf4e6a1300149cc802
        action: CLOCK_IN
        actionTime: ${moment().startOf("day").format()}
    -
        shiftId: 5b4caabf4e6a1300149cc802
        action: CLOCK_OUT
        actionTime: ${moment().startOf("day").format()}`;

export { SHIFT_ACTION_DEFAULT_YAML_VALUE };
