import Icon from "@ant-design/icons";
import React from "react";

function HamburgerSvg() {
  return (
    <svg
      fill="currentColor"
      viewBox="64 64 896 896"
      style={{
        marginTop: ".5em",
      }}
      width="2em"
      height="2em"
    >
      <path d="M177.50016 307.2h614.4a20.48 20.48 0 1 0 0-40.96h-614.4a20.48 20.48 0 1 0 0 40.96zM177.50016 757.76h614.4a20.48 20.48 0 1 0 0-40.96h-614.4a20.48 20.48 0 1 0 0 40.96zM177.50016 532.48h614.4a20.48 20.48 0 1 0 0-40.96h-614.4a20.48 20.48 0 1 0 0 40.96z" />
    </svg>
  );
}

export function HamburgerIcon(props) {
  return <Icon component={HamburgerSvg} {...props} />;
}
