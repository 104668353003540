import { Button } from "src/designsystem/Button/Button";
import { Link } from "../../designsystem/Link/Link";
import styled from "styled-components";

export const Wrapper = styled.div`
  font-size: 16px;
  margin: 16px 150px 0 48px;
`;

export const PageTitle = styled.div`
  font-size: 24px;
`;

export const Description = styled.div`
  font-size: 20px;
`;

export const Heading = styled.div`
  font-size: 22px;
  color: #333;
  margin-top: -4px;
`;

export const Caption = styled.div`
  font-size: 16px;
  color: #333;
`;

export const Workers = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fit, 400px);
`;

export const WhatElse = styled.div`
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 32px 8px;
`;

export const Tasks = styled.div`
  display: grid;
  grid-template-columns: 60px 832px 60px;
  padding: 32px 0;
  align-items: center;
  color: #333;
`;

export const StyledLink = styled(Link)`
  display: flex;
  gap: 8px;
  width: max-content;
  align-items: center;
`;

export const ButtonIcon = styled(Button)<{ justifySelf: "left" | "right" }>`
  border-radius: 100%;
  border: 1px solid rgba(51, 51, 51, 0.3);
  width: 40px;
  height: 40px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: ${({ justifySelf }) => justifySelf};
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.1);
  &:hover {
    box-shadow: 0px 4px 6px rgba(51, 51, 51, 0.25);
  }
`;

export const LinkButtonWithIcon = styled.button<{
  underline?: boolean;
  color?: "primary" | "secondary";
}>`
  display: flex;
  align-items: center;
  padding: 0;
  gap: 4px;
  background-color: transparent;
  border: none;
  ${({ underline, color }) =>
    underline === false
      ? undefined
      : `border-bottom: 1px solid ${color === "primary" ? "#032e68" : "#424242"};`}
  color: ${({ color }) => (color === "primary" ? "#032e68" : "#424242")};
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
  &:focus {
    outline: none;
  }
`;

export const StyledButton = styled(Button)<{
  variant: "primary" | "secondary";
}>`
  ${({ variant }) =>
    variant === "secondary"
      ? `
  color: #032e68;
  background-color: #fff;
  border: 2px solid #032e68;
  `
      : undefined}
`;
