import { Box, Divider, Stack } from "@mui/material";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import { ExternalLink } from "@src/appV2/lib/Links";

export function HomeHealthAppFooter() {
  const footerUrls = useCbhFlag(CbhFeatureFlag.HOME_HEALTH_FOOTER_URLS, {
    defaultValue: {
      faqUrl: "https://www.loom.com/share/279b29dcc0094dd3a108644f764c536e",
      demoUrl: "https://docs.google.com/document/d/1Lpa_J2Vqh-bfRR9d-cVZDsKAdk_WGvwgrikhlvfSW2Y",
    },
  });
  return (
    <Stack direction="row" spacing={1} justifyContent="center">
      <Box>Clipboard Health ©{new Date().getFullYear()}</Box>
      <Divider orientation="vertical" flexItem />
      <ExternalLink to="mailto:homehealth@clipboardhealth.com">
        homehealth@clipboardhealth.com
      </ExternalLink>
      <Divider orientation="vertical" flexItem />
      <ExternalLink to={footerUrls.demoUrl}>Watch a demo</ExternalLink>
      <Divider orientation="vertical" flexItem />
      <ExternalLink to={footerUrls.faqUrl}>FAQ</ExternalLink>
    </Stack>
  );
}
