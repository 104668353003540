import { InMemoryCache } from "@apollo/client";

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        hcfTempRequirements: {
          read: (existing = []) => existing,
          merge: (_, incoming = []) => incoming,
        },
      },
    },
  },
});

export { cache };
