import { environmentConfig } from "../appV2/environment";

/**
 * @deprecated Use environmentConfig from src/appV2/environment/config.ts
 */
const meta = () => {
  const app = {
    dev: "localhost:3000",
    demo: "demo.clipboardhealth.com",
    ops: "ops.clipboardhealth.com",
    sandbox: "app-sandbox.clipboardhealth.com",
    staging: "app-staging.getbluebirdhealth.com",
    production: "clipboard.health",
  };

  const commonOptions = {
    shiftSign: true,
    supportPhone: "408-837-0116",
    supportEmail: "support@clipboardhealth.com",
    uspSmsLine: "(510)405-1047",
    zenDeskContact: "https://app.futuresimple.com/crm/contacts",
    googleStreetMapViewApiUrl: "https://maps.googleapis.com/maps/api/streetview",
    workerDocsLink: "https://cbh.care/docs",
    workerOpenShiftsLink: "https://cbh.care/openShifts",
    workerWebApp: "https://hcp.clipboard.health",
    firebaseConfig: {
      apiKey: "AIzaSyC8W_0rbJCPhUHl0pf0XkWC59R4eHGpSmA",
      authDomain: "clipboard-app.firebaseapp.com",
      databaseURL: "https://clipboard-app.firebaseio.com",
      projectId: "clipboard-app",
      storageBucket: "clipboard-app.appspot.com",
      messagingSenderId: "651599910014",
      appId: "1:651599910014:web:1690f2328ca70ced14342f",
      measurementId: "G-EFPXTPH5QT",
    },
    baseChargeLimit: {
      CNA: 70,
      LVN: 90,
      RN: 145,
      CAREGIVER: 70,
      NP: 110,
      "Medical Technician": 70,
    },
    zendeskWebWidgetId: "ze-snippet",
    maxShiftDuration: 17,
    minShiftDuration: 0.5,
  };

  const segment = {
    dev: {
      key: "e369WCLzzXytmMeSPHGMo0PARzFyBcX0",
    },
    sandbox: {
      key: "e369WCLzzXytmMeSPHGMo0PARzFyBcX0",
    },
    production: {
      key: "WBGrMGAgWwplwULUnWxSFLoorUM4JkH7",
    },
  };

  const PRODUCTION_HOSTS = [
    "beta.clipboard.health",
    "backup1.clipboard.health",
    "backup2.clipboard.health",
    "clipboard.health",
  ];

  const locationHost = window.location.host;

  const host = PRODUCTION_HOSTS.includes(locationHost) ? app.production : locationHost;
  const stage = app.dev ? "dev" : app.sandbox ? "sandbox" : undefined;

  switch (host) {
    case app.dev:
      return {
        api: environmentConfig.REACT_APP_BASE_API_URL,
        app: app.dev,
        ...commonOptions,
        stage,
        segment: segment.dev,
      };
    case app.sandbox:
      return {
        api: "/api",
        app: app.sandbox,
        ...commonOptions,
        stage,
        segment: segment.sandbox,
      };
    case app.demo:
      return {
        api: "/api",
        app: app.demo,
        ...commonOptions,
        stage,
        segment: segment.sandbox,
      };
    case app.ops:
      return {
        api: "/api",
        app: app.ops,
        ...commonOptions,
        stage,
        segment: segment.sandbox,
      };
    case app.staging:
      return {
        api: "/api",
        app: app.staging,
        ...commonOptions,
        stage,
        segment: segment.sandbox,
      };
    case app.production:
      return {
        api: locationHost === app.production ? environmentConfig.REACT_APP_BASE_API_URL : "/api",
        app: app.production,
        ...commonOptions,
        firebaseConfig: {
          apiKey: "AIzaSyA3PoA1BjIP1TGD4GiHZsBSOMqHeXE5HYE",
          authDomain: "clipboard-health.firebaseapp.com",
          databaseURL: "https://clipboard-health.firebaseio.com",
          projectId: "clipboard-health",
          storageBucket: "clipboard-health.appspot.com",
          messagingSenderId: "1098403931480",
          appId: "1:1098403931480:web:cf36f44ee505ab81",
          measurementId: "G-K65B9132JS",
        },
        stage,
        segment: segment.production,
      };
    default:
      return {
        api: environmentConfig.REACT_APP_API_MAIN_URL + "/api",
        app: "",
        ...commonOptions,
        stage,
        segment: segment.sandbox,
      };
  }
};

export default meta;
