import moment from "moment-timezone";

const CREATE_HCF_AND_SHIFT_DEFAULT_YAML_VALUE = `# Facility name will be created based on below format:
# Test Facility_{n}_{current date YYYY-MM-DD}_{random Number}

numberOfFacilitiesToCreate: 3
numberOfShiftsForEachFacility: 5
# Change below Address details to override default values
hcf:
    address:
        streetNumber: "400"
        streetName: "Oak Street"
        city: "San Francisco"
        region: "San Francisco County"
        state: "California"
        stateCode: "CA"
        country: "United States"
        countryCode: "US"
        postalCode: "94102"
        formatted: "400 Oak St, San Francisco, CA 94102, USA"
    geoLocation:
        coordinates: [ -122.426094,37.77478199999999 ]
        type: "Point"
    tmz:  America/Los_Angeles
    type: Long Term Care
    rates:
        CNA: 20
        LVN: 20
        RN: 20
        NP: 20
        CAREGIVER: 20
        Medical Technician: 21

# Change below shift details to override default values
shiftData:
    shift: 
        start: ${moment().add(1, "hour").format()}
        end: ${moment().add(3, "hours").format()}
        agentReq: CNA
        name: pm
        charge: 32
        pay: 24`;

export { CREATE_HCF_AND_SHIFT_DEFAULT_YAML_VALUE };
