import request from "superagent";
import { getAuthHeader } from "src/utils/superagent";
import { Facility } from "src/interface";

export type User = {
  _id: string;
  email: string;
  tmz: string;
  facility?: Facility;
  employee?: { name: string };
  agent?: { name: string };
  zenDeskId?: string;
  flags?: {
    chargeRateVisible: boolean;
    canCancelFilledShifts: boolean;
    hasActiveNFCHash: boolean;
  };
};

export const getUser = async (userId: string): Promise<User> => {
  const { body } = await request.get(`${global.api}/user/get/${userId}`).set(await getAuthHeader());
  return body;
};
