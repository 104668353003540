export const DocumentStatus = {
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  MISSING: "MISSING",
  EXPIRED: "EXPIRED",
};

export const DOCUMENT_STATUS_METADATA = {
  APPROVED: {
    color: "blue",
    content: "approved",
    order: 2,
  },
  REJECTED: {
    color: "red",
    content: "rejected",
    order: 3,
  },
  PENDING: {
    color: "gold",
    content: "in review",
    order: 1,
  },
};

export const MISCELLANEOUS = "miscellaneous";

export enum RequirementMappingLevel {
  GLOBAL = "GLOBAL",
  STATE = "STATE",
  COUNTY = "COUNTY",
  MSA = "MSA",
  HCF = "HCF",
}
