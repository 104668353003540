import { CloseCircleOutlined } from "@ant-design/icons";
import { Switch, Table, Modal, Button, Select, Tag } from "antd";
import React, { forwardRef, useEffect, useMemo, useState } from "react";
import { hours, batchDescription } from "../../../constants/facility";
import { fillDefaultAlertSettings } from "../helpers";
import { getBrowser } from "src/browserSupport";
import { CbhFeatureFlag } from "@src/appV2/FeatureFlags";
const { Option } = Select;

export const getLinkToUnblockPushNotifications = () => {
  const text = "Notifications are blocked by your browser; click here for instructions.";
  let link = "";
  const { name } = getBrowser();

  switch (name) {
    case "Chrome":
      link =
        "https://support.google.com/chrome/answer/3220216?hl=en&co=GENIE.Platform%3DDesktop#zippy=%2Cfix-issues-with-notifications";
      break;
    case "Firefox":
      link = "https://support.mozilla.org/en-US/kb/push-notifications-firefox";
      break;
    case "Safari":
      link = "https://support.apple.com/guide/safari/customize-website-notifications-sfri40734/mac";
      break;
    case "Edge":
      link =
        "https://support.microsoft.com/en-us/microsoft-edge/manage-website-notifications-in-microsoft-edge-0c555609-5bf2-479d-a59d-fb30a0b80b2b";
      break;
  }

  return { text, link };
};

export const NotificationInput = forwardRef(
  (
    /**
     * FIXME: Ref isn't used. Does this need `forwardRef`?
     * Also, add appropriate interface.
     */
    props: any,
    ref
  ) => {
    const {
      value,
      onChange,
      roles,
      userGranularSettings,
      userToEdit,
      userToEditIsLoggedIn,
      updateWebPushNotify,
      facilityFlags,
      isRateNegotiationActive,
    } = props;
    const [showBatchingModal, setBatchModal] = useState<any>({
      data: {},
      time: [],
      type: "",
      openModal: false,
    });
    const [pushEnabled, setPushEnabled] = useState(false);
    const [emailFields, setEmailFields] = useState(value["EMAIL"]);
    const [smsFields, setSMSFields] = useState(value["SMS"]);

    /* @ts-expect-error FIXME this wrong type */
    const { braze } = window;

    useEffect(() => {
      const isRateNegotiationCommsEmailActive =
        facilityFlags[CbhFeatureFlag.RATE_NEGOTIATION_COMMS_EMAIL];
      const isRateNegotiationCommsSMSActive =
        facilityFlags[CbhFeatureFlag.RATE_NEGOTIATION_COMMS_SMS];

      if (!isRateNegotiationActive || !isRateNegotiationCommsEmailActive) {
        setEmailFields(value["EMAIL"].filter((val) => val.action !== "rateNegotiationEmail"));
      } else {
        setEmailFields(value["EMAIL"]);
      }
      if (!isRateNegotiationActive || !isRateNegotiationCommsSMSActive) {
        setSMSFields(value["SMS"].filter((val) => val.action !== "rateNegotiationSMS"));
      } else {
        setSMSFields(value["SMS"]);
      }
    }, [facilityFlags, value]);

    useEffect(() => {
      setPushEnabled(!!userToEdit.notify?.WEB_PUSH?.All);
    }, [userToEdit]);

    useEffect(() => {
      async () => {
        try {
          if (userToEditIsLoggedIn && braze?.getUser()) {
            const pushPermissionGranted = braze.isPushPermissionGranted();
            // if pushPermissionGranted is true but data is not being reflected in the database
            if (pushPermissionGranted && !userToEdit.notify?.WEB_PUSH?.All) {
              updateWebPushNotify(true);
              setPushEnabled(true);
            } else if (!pushPermissionGranted && userToEdit.notify?.WEB_PUSH?.All) {
              braze.requestPushPermission();
            }
          }
        } catch (err) {
          console.error(err);
        }
      };
    }, [braze, userToEditIsLoggedIn]);

    useEffect(() => {
      onChange(fillDefaultAlertSettings(userGranularSettings, roles, facilityFlags));
    }, [roles, facilityFlags]);

    const onSwitchChange = (actionData, typeOfSwitch) => (checked) => {
      let newState = [];
      if (actionData.action === "All") {
        newState = value[actionData.type].map((item) => {
          if (typeOfSwitch === "batch") {
            if (item.default.userModifiableBatch && item.default.tempBatchDisable && checked) {
              return item;
            }
            if (item.default.userModifiableBatch) {
              return {
                ...item,
                default: {
                  ...item.default,
                  [typeOfSwitch]: checked,
                  time: actionData.default.time,
                },
              };
            }
          } else if (typeOfSwitch === "enabled") {
            if (item.default.userModifiableState && item.default.userModifiableBatch && !checked) {
              return {
                ...item,
                default: {
                  ...item.default,
                  [typeOfSwitch]: checked,
                  batch: checked,
                  tempBatchDisable: true,
                },
              };
            }
            if (item.default.userModifiableState && item.default.userModifiableBatch) {
              return {
                ...item,
                default: {
                  ...item.default,
                  [typeOfSwitch]: checked,
                  tempBatchDisable: false,
                },
              };
            }
            if (item.default.userModifiableState && !item.default.userModifiableBatch) {
              return {
                ...item,
                default: { ...item.default, [typeOfSwitch]: checked },
              };
            }
          }
          return item;
        });
      } else {
        newState = value[actionData.type].map((item) => {
          if (item.action === actionData.action) {
            if (typeOfSwitch === "batch") {
              return {
                ...item,
                default: {
                  ...item.default,
                  [typeOfSwitch]: checked,
                  time: actionData.default.time,
                },
              };
            } else if (
              typeOfSwitch === "enabled" &&
              checked === false &&
              actionData.default.userModifiableBatch
            ) {
              return {
                ...item,
                default: {
                  ...item.default,
                  [typeOfSwitch]: checked,
                  batch: false,
                  tempBatchDisable: true,
                },
              };
            } else if (
              typeOfSwitch === "enabled" &&
              checked === false &&
              !actionData.default.userModifiableBatch
            ) {
              return {
                ...item,
                default: { ...item.default, [typeOfSwitch]: checked },
              };
            } else if (typeOfSwitch === "enabled" && checked === true) {
              return {
                ...item,
                default: {
                  ...item.default,
                  [typeOfSwitch]: checked,
                  tempBatchDisable: false,
                },
              };
            }
          }
          return item;
        });
      }
      onChange({
        ...value,
        [actionData.type]: [...newState],
      });

      if (typeOfSwitch === "batch" && checked) {
        const currentActionData = actionData;
        if (currentActionData.default.time === undefined) {
          currentActionData.default.time = 16;
        }
        let time: any = null;
        if (currentActionData.default.time > 12) {
          time = [Number((currentActionData.default.time - 2).toString()[1]), "PM"];
        } else if (currentActionData.default.time === 12) {
          time = [Number(currentActionData.default.time), "PM"];
        } else if (currentActionData.default.time === 0) {
          time = [Number(currentActionData.default.time), "AM"];
        } else {
          time = [Number(currentActionData.default.time), "AM"];
        }

        setBatchModal({
          data: currentActionData,
          type: actionData.type,
          time,
          openModal: true,
        });
      }
    };

    const renderNotify = (actionData) => {
      // const { granularControl } = value
      // const thisActionData = granularControl[actionData.type][actionData.action]
      return (
        <Switch
          onChange={onSwitchChange(actionData, "enabled")}
          checked={actionData.default.enabled}
          disabled={!actionData.default.userModifiableState}
        />
      );
    };

    const renderTitle = (title, record) => {
      return <b>{title}</b>;
    };

    const renderBatch = (actionData) => {
      let checked, disabled;

      disabled =
        actionData.default.tempBatchDisable !== undefined && actionData.default.tempBatchDisable
          ? actionData.default.tempBatchDisable
          : !actionData.default.userModifiableBatch;
      checked = actionData.default.batch;

      return (
        <Switch
          onChange={onSwitchChange(actionData, "batch")}
          checked={checked}
          disabled={disabled}
        />
      );
    };
    const columns = [
      {
        title: "Alert Type",
        dataIndex: "title",
        render: renderTitle,
      },
      {
        title: "Enable",
        render: renderNotify,
        width: 150,
      },
      {
        title: "Batched",
        render: renderBatch,
        width: 150,
      },
    ];
    const closeModal = () => {
      const { data } = showBatchingModal;

      onSwitchChange(data, "batch")(false);
      setBatchModal({ data: {}, time: [], type: ``, openModal: false });
    };

    const onWebPushToggle = async () => {
      const newPushEnabled = !pushEnabled;
      if (!braze.isPushBlocked() || !newPushEnabled) {
        if (newPushEnabled) {
          if (!braze.isPushPermissionGranted() && !braze.isPushBlocked()) {
            braze.requestPushPermission();
          }
          braze
            .getUser()
            .setPushNotificationSubscriptionType(braze.User.NotificationSubscriptionTypes.OPTED_IN);
        } else if (!newPushEnabled) {
          braze
            .getUser()
            .setPushNotificationSubscriptionType(
              braze.User.NotificationSubscriptionTypes.UNSUBSCRIBED
            );
        }
        await updateWebPushNotify(newPushEnabled);
        setPushEnabled(newPushEnabled);
      }
    };

    const showInstructionsToUnblockPush = useMemo<any>(() => {
      if (userToEditIsLoggedIn && braze?.getUser()) {
        const isPushBlocked = braze.isPushBlocked();
        if (isPushBlocked) {
          return getLinkToUnblockPushNotifications();
        }
      }
      return {};
    }, [userToEditIsLoggedIn, braze]);

    const renderTitlePush = (title, record) => {
      return <b>{title}</b>;
    };

    const renderNotifyPush = (actionData) => {
      return (
        <Switch
          onChange={onWebPushToggle}
          checked={actionData.enabled}
          disabled={!userToEditIsLoggedIn}
        />
      );
    };

    const renderBatchPush = (actionData) => {
      return <Switch checked={false} disabled={true} />;
    };

    const columnsPush = [
      {
        title: "Alert Type",
        dataIndex: "title",
        render: renderTitlePush,
      },
      {
        title: "Enable",
        render: renderNotifyPush,
        width: 150,
      },
      {
        title: "Batched",
        render: renderBatchPush,
        width: 150,
      },
    ];

    return (
      <>
        <h3>
          Web Browser Notifications
          {showInstructionsToUnblockPush.link && (
            <span
              style={{
                fontSize: "13px",
                marginLeft: "5px",
                textDecoration: "underline",
              }}
            >
              <a href={showInstructionsToUnblockPush.link} target="blank">
                {showInstructionsToUnblockPush.text}
              </a>
            </span>
          )}
        </h3>
        <Table
          pagination={false}
          dataSource={[{ title: "All", enabled: pushEnabled }]}
          columns={columnsPush}
        />
        <br />
        <h3>Emails</h3>
        <Table pagination={false} dataSource={emailFields} columns={columns} />
        <br />

        <h3>
          SMS / Text messages{" "}
          {!userToEdit.phone && (
            <Tag icon={<CloseCircleOutlined />} color="error">
              Please enter a mobile number to receive text messages
            </Tag>
          )}
        </h3>

        <Table pagination={false} dataSource={smsFields} columns={columns} />
        <Modal
          title={`Batching Options`}
          onCancel={closeModal}
          visible={showBatchingModal.openModal}
          destroyOnClose={true}
          width={"80%"}
          footer={[
            <Button key="cancelBatchBtn" onClick={closeModal}>
              Cancel
            </Button>,
            <Button
              key="saveBatchBtn"
              type="primary"
              onClick={() => {
                let { data, time } = showBatchingModal;

                if (time[0] === 12 && time[1] === "PM") {
                  time = 12;
                } else if (time[0] === 12 && time[1] === "AM") {
                  time = 0;
                } else if (time[1] === "PM") {
                  time = Number(time[0]) + 12;
                } else {
                  time = Number(time[0]);
                }
                data.default.time = time;

                onSwitchChange(data, "batch")(true);
                setBatchModal({
                  data: {},
                  time: [],
                  type: ``,
                  openModal: false,
                });
              }}
            >
              Save
            </Button>,
          ]}
        >
          {showBatchingModal.openModal && (
            <Table
              columns={[
                {
                  title: "Time",
                  key: "batchTime",
                  dataIndex: "time",
                },
                {
                  title: "Description",
                  key: "batchDescription",
                  dataIndex: "description",
                },
              ]}
              dataSource={[
                {
                  description: batchDescription[showBatchingModal.type],
                  time: (
                    <>
                      <Select
                        onChange={(value) => {
                          setBatchModal({
                            ...showBatchingModal,
                            time: [value, showBatchingModal.time[1]],
                          });
                        }}
                        value={showBatchingModal.time[0]}
                        style={{ width: 70, marginRight: 10 }}
                      >
                        {hours.map((hour) => (
                          <Option value={hour}>{hour}</Option>
                        ))}
                      </Select>
                      <Select
                        onChange={(value) => {
                          setBatchModal({
                            ...showBatchingModal,
                            time: [showBatchingModal.time[0], value],
                          });
                        }}
                        value={showBatchingModal.time[1]}
                        style={{ width: 70 }}
                      >
                        <Option value={"AM"}>{"AM"}</Option>
                        <Option value={"PM"}>{"PM"}</Option>
                      </Select>
                    </>
                  ),
                },
              ]}
              pagination={false}
            />
          )}
        </Modal>
      </>
    );
  }
);
