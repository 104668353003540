import { UseQueryResult } from "@tanstack/react-query";
import { useGetQuery, UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { WorkplaceTypesResponse, workplaceTypesResponseSchema } from "../types";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";

export function useGetWorkplaceTypes(
  options: UseGetQueryOptions<WorkplaceTypesResponse> = {}
): UseQueryResult<WorkplaceTypesResponse> {
  return useGetQuery({
    url: `${environmentConfig.REACT_APP_BASE_API_URL}/workplace-types`,
    responseSchema: workplaceTypesResponseSchema,
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_WORKPLACE_TYPES_FAILURE,
      userErrorMessage: "Failed to get workplace types",
    },
    refetchOnWindowFocus: false,
    ...options,
  });
}
