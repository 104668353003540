import { useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchWorkerAwaitingReviews } from "src/api/workers";
import { LONG_STALE_TIME } from "src/constants/query";
import once from "lodash/once";
import { useMemo } from "react";
import { CarouselTriggeredBy } from "src/modules/workerReviews";
import { logModalViewed } from "./logs";

export function useWorkerAwaitingReviews(
  workplaceId: string,
  workplaceUserId: string,
  sessionId: string,
  source: CarouselTriggeredBy
) {
  const queryKey = ["worker-reviews-modal", workplaceId];
  const queryClient = useQueryClient();
  const logModalViewedOnce = useMemo(() => once(logModalViewed), []);

  const { data: workerAwaitingReviewsData, isLoading: workerAwaitingReviewsLoading } = useQuery({
    queryKey,
    queryFn: () => fetchWorkerAwaitingReviews(workplaceId),
    staleTime: LONG_STALE_TIME,
    onSuccess: (resp) => {
      logModalViewedOnce(
        workplaceId,
        workplaceUserId,
        sessionId,
        resp?.workers.length ?? 0,
        source
      );
    },
  });

  const invalidateCache = () => {
    queryClient.removeQueries(queryKey);
  };

  return {
    data: workerAwaitingReviewsData?.workers ?? [],
    isLoading: workerAwaitingReviewsLoading,
    invalidateCache,
  };
}
