import styled from "styled-components";

export const CheckBoxWrapper = styled.div`
  margin-top: 8px;

  &.invalid .ant-checkbox-wrapper {
    color: indianred;

    .ant-checkbox-inner {
      border-color: indianred;
    }
  }
`;
