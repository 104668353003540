import "../css/enable_stripe.css";
import React, { useState, useEffect } from "react";
import { Form, Row, Button, PageHeader, Divider, Typography, Alert } from "antd";
import axios from "axios";

import MonacoEditor from "react-monaco-editor";
import yaml from "yaml";
import ShouldRender from "./ShouldRender";
import { environmentConfig } from "@src/appV2/environment";
import { showErrorToast, showSuccessToast } from "@src/appV2/lib/Notifications";

const { Title } = Typography;

export function getEnabledStripeDefaultYamlTestValue(email?: string) {
  return `enableStripe:
  hcpEmail: ${email || "haileyesuszemed@gmail.com"}
  enabled: true
  stripeAccountId: acct_1IrByE2QuLgYcgRu
  status: Instant Payouts Enabled`;
}

function EnableStripe(props: any) {
  const { selectedRegressionTestData = {} } = props;
  const [isGetData, setIsGetData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [enableStripeYAMLData, setEnableStripesYAMLData] = useState<any>(
    getEnabledStripeDefaultYamlTestValue()
  );

  useEffect(() => {
    getAgentProfile();
  }, []);

  useEffect(() => {
    if (selectedRegressionTestData.config) {
      const { enableStripe: enableStripeData } = yaml.parse(selectedRegressionTestData.config);
      setEnableStripesYAMLData(yaml.stringify({ enableStripe: enableStripeData }));
    } else if (selectedRegressionTestData.id) {
      setEnableStripesYAMLData("enableStripe:\n");
    }
  }, [selectedRegressionTestData]);

  const getAgentProfile = async () => {
    setIsGetData(true);
    try {
      const agent = await axios.get(
        `${environmentConfig.REACT_APP_API_MAIN_URL || ""}/api/agentProfile`,
        {
          headers: {
            Authorization: localStorage.getItem("authToken"),
          },
        }
      );
      if (agent.data) {
        const newYaml = getEnabledStripeDefaultYamlTestValue(agent.data.email);
        setEnableStripesYAMLData(newYaml);
      } else {
        showErrorToast(
          `${enableStripeYAMLData.hcpEmail} cannot get agentProfile please insert HCP email manually`
        );
      }
    } catch (error) {
      console.error("Action Resp Error: ", error);
      showErrorToast(`Test panel Error ${(error as Error).message}`);
    } finally {
      setIsGetData(false);
    }
  };

  const editorDidMount = (editor) => {
    editor.focus();
  };

  const options = {
    selectOnLineNumbers: true,
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const onSubmit = async () => {
    setLoading(true);
    try {
      const { enableStripe } = yaml.parse(enableStripeYAMLData);
      const resp = await axios.put(
        `${environmentConfig.REACT_APP_API_MAIN_URL || ""}/api/testHelpers/account/enable/${
          enableStripe.hcpEmail
        }`,
        {
          ...enableStripe,
        },
        {
          headers: {
            Authorization: localStorage.getItem("authToken"),
          },
        }
      );
      if (resp.data?.success) {
        showSuccessToast(`${enableStripe.hcpEmail} stripe action completed successfully`);
      } else {
        showErrorToast(`${enableStripe.hcpEmail} stripe actions did not complete successfully`);
      }
    } catch (error) {
      console.error("Action Resp Error: ", error);
      showErrorToast(`Test panel Error ${(error as Error).message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <PageHeader
        className="enableStripePageHeader"
        title="Enable Stripe"
        extra={[
          <Button
            type="default"
            key={"key"}
            onClick={() => {
              window.open(`https://dev.to/paulasantamaria/introduction-to-yaml-125f`)?.focus();
            }}
          >
            Intro to YAML
          </Button>,
        ]}
      />
      <Divider className="enableStripeHeaderDivider" />
      <Row justify="start" align="middle" style={{ width: "100%", height: "100%" }}>
        <Form {...layout} name="basic" form={form} onFinish={onSubmit} style={{ width: "600px" }}>
          <Row>
            <Title level={4}>YAML Config</Title>
            <div>
              <ShouldRender condition={selectedRegressionTestData.name}>
                <Alert
                  style={{ marginBottom: ".5rem" }}
                  message={`Showing config data for test - ${selectedRegressionTestData.name}`}
                  type="info"
                  showIcon
                />
              </ShouldRender>
            </div>
          </Row>
          <Row>
            {isGetData ? (
              <div>Getting required data...</div>
            ) : (
              <MonacoEditor
                height="400"
                language="javascript"
                theme="vs-dark"
                value={enableStripeYAMLData}
                options={options}
                editorDidMount={editorDidMount}
                onChange={(value) => {
                  setEnableStripesYAMLData(value);
                }}
              />
            )}
          </Row>

          <Row justify="end" align="middle" style={{ marginTop: "1rem" }}>
            <Button type="primary" htmlType="submit" loading={loading}>
              Submit
            </Button>
          </Row>
        </Form>
      </Row>
    </div>
  );
}

export default EnableStripe;
