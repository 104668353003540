import { UseGetQueryOptions, useGetQuery } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { UseQueryResult } from "@tanstack/react-query";
import {
  ExtraWorkedTimeRequestsParams,
  ExtraWorkedTimeRequestsResponse,
  extraWorkedTimeRequestsParamsSchema,
  extraWorkedTimeRequestsResponseSchema,
} from "../schema";

export const extraWorkedTimeRequestsUrl = `${environmentConfig.REACT_APP_BASE_API_URL}/extra-worked-time-requests`;

export function useExtraWorkedTimeRequests(
  params: ExtraWorkedTimeRequestsParams,
  options: UseGetQueryOptions<ExtraWorkedTimeRequestsResponse> = {}
): UseQueryResult<ExtraWorkedTimeRequestsResponse> {
  const result = useGetQuery({
    url: extraWorkedTimeRequestsUrl,
    requestSchema: extraWorkedTimeRequestsParamsSchema,
    responseSchema: extraWorkedTimeRequestsResponseSchema,
    queryParams: params,
    meta: {
      userErrorMessage: "Failed fetching extra worked time requests.",
      logErrorMessage: APP_V2_APP_EVENTS.GET_EXTRA_WORKED_TIME_REQUESTS_FAILURE,
    },
    ...options,
  });

  return result;
}
