import { type ReactElement } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { NotFoundPage } from "../lib";

import { HOME_HEALTH_CASES_PATH } from "../ExperimentalHomeHealth/Cases/paths";
import { HomeHealthProtectedCasesPage } from "../ExperimentalHomeHealth/Cases/ProtectedCasesPage";
import { HOME_HEALTH_VISIT_VERIFICATION_PATH } from "../ExperimentalHomeHealth/VisitVerification/paths";
import { HomeHealthProtectedVisitVerificationPage } from "../ExperimentalHomeHealth/VisitVerification/ProtectedVisitVerificationPage";

export function AppV2PrivateRoutes(): ReactElement {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${match.url}/${HOME_HEALTH_CASES_PATH}`}>
        <HomeHealthProtectedCasesPage />
      </Route>
      <Route exact path={`${match.url}/${HOME_HEALTH_VISIT_VERIFICATION_PATH}`}>
        <HomeHealthProtectedVisitVerificationPage />
      </Route>
      <Route path={match.url} exact>
        <div>This is a private route from appV2</div>
      </Route>
      <Route path="*">
        <NotFoundPage />
      </Route>
    </Switch>
  );
}
