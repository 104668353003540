import { type SxProps, type Theme } from "@mui/material";

export function mergeSxProps(
  sxProps1: SxProps<Theme> | undefined,
  sxProps2: SxProps<Theme> | undefined
): SxProps<Theme> {
  const sxList1: SxProps<Theme> = Array.isArray(sxProps1) ? sxProps1 : [sxProps1];
  const sxList2: SxProps<Theme> = Array.isArray(sxProps2) ? sxProps2 : [sxProps2];

  return [...sxList1, ...sxList2].filter(Boolean);
}
