import request from "superagent";
import { getAuthHeader } from "src/utils/superagent";
import { FacilityUserVerificationRequest, ShareContractFormApi } from "./components/types";

export const shareContractForm = async ({
  facilityId,
  ...data
}: ShareContractFormApi & {
  facilityId: string;
}): Promise<ShareContractFormApi> => {
  return request
    .post(`${global.api}/facilityprofile/${facilityId}/contracts/users/shares`)
    .set(await getAuthHeader())
    .send(data)
    .then(({ body }) => body);
};

export const verifyFacilityUser = async ({
  facilityUserId,
  facilityId,
}: FacilityUserVerificationRequest & {
  facilityId: string;
}): Promise<{ success: boolean }> => {
  return request
    .post(`${global.api}/facilityUser/${facilityUserId}/verifications`)
    .set(await getAuthHeader())
    .send({ facilityId })
    .then(({ body }) => body);
};
