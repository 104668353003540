import { useSelector } from "react-redux";
import { SessionType } from "src/modules/interface";
import { useDispatch } from "react-redux";
import { closeWorkplaceContractModal } from "src/modules/workplaceContract";
import { WorkplaceSignContractVerifiedUser } from "./VerifiedUser";
import { WorkplaceSignContractUnverifiedUser } from "./UnverifiedUser";
import { WorkplaceSignContractSuspiciousUser } from "./SuspiciousUser";
import { useCallback, useEffect } from "react";
import { logEvent } from "@src/appV2/lib/analytics";
import { HCF_USER_EVENTS } from "src/constants/firebaseEvents";
import { FacilityStatusObj } from "src/utils/facility";
import { WorkplaceSignContractFallback } from "./Fallback";

function WorkplaceSignContractPromptModal() {
  const { user, profile, admin } = useSelector((state: SessionType) => state.session);
  const workplaceContractState = useSelector((state: SessionType) => state.workplaceContract);

  const dispatch = useDispatch();

  const trackEvent = useCallback(
    (subAction?: string) => {
      if (user && !admin) {
        logEvent(HCF_USER_EVENTS.FACILITY_CONTRACT_REQUIREMENT_POPUP, {
          action: workplaceContractState.triggeredBy,
          subAction,
          popupType: user.isSuspicious
            ? "Suspicious User"
            : user.isEmailVerified
            ? "View Contract"
            : "Email Verification",
          workplaceId: profile.userId,
          workplaceUserId: user._id,
          isEmailVerified: !!user.isEmailVerified,
          isSuspicious: !!user.isSuspicious,
          page: workplaceContractState.triggeredFrom,
        });
      }
    },
    [
      admin,
      profile.userId,
      user,
      workplaceContractState.triggeredBy,
      workplaceContractState.triggeredFrom,
    ]
  );

  useEffect(() => {
    trackEvent("popup triggered");
  }, [trackEvent]);

  const onCloseModal = () => {
    trackEvent("close");
    dispatch(closeWorkplaceContractModal());
  };

  // The order of this if statements matter
  if (user?.isSuspicious) {
    return (
      <WorkplaceSignContractSuspiciousUser
        closeModal={onCloseModal}
        onSubmit={() => trackEvent("email us")}
      />
    );
  } else if (profile.status === FacilityStatusObj.UNSIGNED) {
    return (
      <WorkplaceSignContractVerifiedUser
        workplaceId={profile.userId}
        closeModal={onCloseModal}
        onSubmit={() => trackEvent("view contract")}
      />
    );
  } else if (!user?.isEmailVerified) {
    return (
      <WorkplaceSignContractUnverifiedUser
        closeModal={onCloseModal}
        onSubmit={() => trackEvent("view and sign")}
      />
    );
  }
  return (
    <WorkplaceSignContractFallback
      closeModal={onCloseModal}
      onSubmit={() => trackEvent("fallback")}
    />
  );
}

export { WorkplaceSignContractPromptModal };
