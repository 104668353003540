import React, { Suspense } from "react";
import styled from "styled-components";

const LazyLoad = (Component) => {
  return function (props) {
    return (
      <Suspense fallback={<FallbackLoader />}>
        <Component {...props} />
      </Suspense>
    );
  };
};

const StyledFallbackLoaderContainer = styled.div`
  .lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #4798ff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #4798ff transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

function FallbackLoader() {
  return (
    <StyledFallbackLoaderContainer
      style={{
        minHeight: 400,
        width: "100%",
        position: "relative",
        padding: "10% 50%",
      }}
    >
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </StyledFallbackLoaderContainer>
  );
}

export { LazyLoad, FallbackLoader };
