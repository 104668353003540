import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SessionType } from "src/modules/interface";
import { keepUserSessionAlive } from "src/modules/session/init";

const useLogoutIn24Hours = () => {
  const { type, profile } = useSelector((state: SessionType) => state.session);
  const dispatch = useDispatch();
  const isFirebase24HoursExpiryEnabled = useCbhFlag(
    CbhFeatureFlag.FIREBASE_24_HOURS_EXPIRY_ENABLED,
    {
      defaultValue: false,
    }
  );
  useEffect(() => {
    const isAdmin = profile?.userId && type === "ADMIN";

    if (isAdmin && isFirebase24HoursExpiryEnabled) {
      keepUserSessionAlive(dispatch, 24);
    }
  }, [type, profile, dispatch, isFirebase24HoursExpiryEnabled]);
};

export { useLogoutIn24Hours };
