import { WorkplaceSignContractVerifiedUserProps } from "./types";
import { Link } from "react-router-dom";
import { Button } from "src/designsystem/Button/Button";
import {
  ButtonGroup,
  StyledModal,
  WorkplaceContractFormModalTitle,
  WorkplaceContractFormModalHelpText,
} from "./styles";
import { getLocation } from "src/utils/routes";

function WorkplaceSignContractVerifiedUser({
  workplaceId,
  closeModal,
  onSubmit,
}: WorkplaceSignContractVerifiedUserProps) {
  return (
    <StyledModal visible closable onCancel={closeModal} footer={null} cancelText={null}>
      <WorkplaceContractFormModalTitle level={3}>
        You're almost there!
      </WorkplaceContractFormModalTitle>
      <WorkplaceContractFormModalHelpText>
        To finish activating your facility profile, we need someone from the facility to review and
        sign our contract. It only takes a few minutes to complete!
      </WorkplaceContractFormModalHelpText>
      <ButtonGroup>
        <Link
          to={getLocation("workplaceSignContract", {
            pathParams: { workplaceId },
          })}
          target="_blank"
          rel="noopener"
          color="primary"
          type="button"
        >
          <Button variant="primary" onClick={onSubmit}>
            View contract
          </Button>
        </Link>
      </ButtonGroup>
    </StyledModal>
  );
}

export { WorkplaceSignContractVerifiedUser };
