import { CarouselRef } from "antd/lib/carousel";
import { WorkerRatingCarouselModal } from "src/components/WorkerRatingCarouselModal/WorkerRatingCarouselModal";
import { useWorkerReviewsBanner } from "src/components/WorkerReviewsBanner/useWorkerReviewsBanner";
import { useWorkplaceSignContractBanner } from "src/containers/workplaceSignContract/useContractBanner";
import { Banner } from "src/designsystem/Banner/Banner";
import { BannerProps } from "src/designsystem/Banner/Banner.type";
import { VerticalSpacing } from "src/designsystem/VerticalSpacing/VerticalSpacing";
import { useRef } from "react";
import { BsArrowRight } from "react-icons/bs";
import { CarouselWrapper } from "./carouselBanner.styles";

export function CarouselBanner() {
  const ref = useRef<CarouselRef>(null);
  const workplaceSignContractBanner = useWorkplaceSignContractBanner();
  const workerReviewBanner = useWorkerReviewsBanner();
  const banners = [
    workplaceSignContractBanner?.bannerProps,
    workerReviewBanner?.bannerProps,
  ].filter(Boolean) as BannerProps[];
  return (
    <>
      {banners.length > 0 && (
        <>
          <CarouselWrapper
            ref={ref}
            autoplay={true}
            autoplaySpeed={5000}
            adaptiveHeight={true}
            effect="fade"
            appendDots={(dots) => (
              <div>
                <ul> {dots} </ul>
                <BsArrowRight
                  data-testid="arrow-button"
                  color="black"
                  cursor="pointer"
                  fontSize={20}
                  onClick={() => ref.current?.next()}
                />
              </div>
            )}
          >
            {banners.map((banner, index) => (
              <div key={index}>
                <Banner {...banner} />
              </div>
            ))}
          </CarouselWrapper>
          <VerticalSpacing size="lg" />
        </>
      )}
      {workerReviewBanner?.modalProps && (
        <WorkerRatingCarouselModal {...workerReviewBanner?.modalProps} />
      )}
    </>
  );
}
