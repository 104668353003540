const AGENT_LICENSE_UPDATE = `service:
    name: agentLicenceUpdated
    data:
        agentId: 5b43d2919932000014f5b51b
        state: California`;

const AGENT_ADDRESS_UPDATE = `service:
    name: agentAddressUpdated
    data:
        agentId: 5b43d2919932000014f5b51b`;

export { AGENT_ADDRESS_UPDATE, AGENT_LICENSE_UPDATE };
