import { z } from "zod";
import { VisitOccurrenceStatus, responseErrorSchema, visitOccurrenceSchema } from "../Cases/types";

export const visitOccurrencesFiltersSchema = z.object({
  workerId: z.string().optional(),
  status: z.nativeEnum(VisitOccurrenceStatus).optional(),
});
export type VisitOccurrencesFilters = z.infer<typeof visitOccurrencesFiltersSchema>;

export enum VisitOccurrencesSort {
  CREATED_AT = "createdAt",
  COMPLETED_AT = "completedAt",
  ACTIONED_AT = "actionedAt",
  REJECTION_REASON = "rejectionReason",
  EXTERNAL_PATIENT_IDENTIFIER = "externalPatientIdentifier",
  VISIT_TYPE = "visitType",
}

export const visitOccurrencesSortSchema = z.enum(
  Object.values(VisitOccurrencesSort)
    .map((value) => [value, `-${value}`])
    .flat() as [string, ...string[]]
);

export const visitOccurrencesParamsSchema = z.object({
  filter: visitOccurrencesFiltersSchema.optional(),
  sort: visitOccurrencesSortSchema.optional(),
});
export type VisitOccurrencesParams = z.infer<typeof visitOccurrencesParamsSchema>;

export const visitOccurrencesResponseSchema = z.object({
  data: z.array(visitOccurrenceSchema),
  errors: z.array(responseErrorSchema).optional(),
});
export type VisitOccurrencesResponse = z.infer<typeof visitOccurrencesResponseSchema>;

export const updateVisitOccurrenceRequestSchema = z.object({
  data: visitOccurrenceSchema.omit({ id: true }).extend({
    attributes: z.object({
      status: z.nativeEnum(VisitOccurrenceStatus).optional(),
      rejectionReason: z.string().optional(),
      paid: z.boolean().optional(),
      payRate: z
        .object({
          amountInMinorUnits: z.number(),
          currencyCode: z.literal("USD"),
        })
        .optional(),
      chargeRate: z
        .object({
          amountInMinorUnits: z.number(),
          currencyCode: z.literal("USD"),
        })
        .optional(),
    }),
  }),
});
export type UpdateVisitOccurrenceRequest = z.infer<typeof updateVisitOccurrenceRequestSchema>;

export const updateVisitOccurrenceResponseSchema = z.object({
  data: visitOccurrenceSchema,
  errors: z.array(responseErrorSchema).optional(),
});
export type UpdateVisitOccurrenceResponse = z.infer<typeof updateVisitOccurrenceResponseSchema>;
