import { DateCalendar, DateCalendarProps, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { ForwardedRef, forwardRef } from "react";
import { GroupShiftInviteDayPicker } from "./GroupShiftInviteDayPicker";

type GroupShiftInviteMultiDatePickerCalendarProps = Omit<DateCalendarProps<Date>, "onChange"> & {
  onChange: (value: Date) => void;
  highlightedDays: Date[];
  selectedDate: Date | undefined;
  id?: string;
};
// implementation extended from @src/designsystem/MultiDatePickerCalendar/MultiDatePickerCalendar
export const GroupShiftInviteMultiDatePickerCalendar = forwardRef(
  (props: GroupShiftInviteMultiDatePickerCalendarProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { highlightedDays, selectedDate, ...pickerProps } = props;
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateCalendar
          {...pickerProps}
          ref={ref}
          value={selectedDate ?? null}
          onChange={(value) => {
            if (!value) {
              return;
            }
            props.onChange(value);
          }}
          slots={{
            day: (dayProps) => (
              <GroupShiftInviteDayPicker {...dayProps} selectedDate={selectedDate} />
            ),
          }}
          slotProps={{ day: { selectedDays: highlightedDays } as never }}
        />
      </LocalizationProvider>
    );
  }
);
