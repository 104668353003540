import { useShiftTimes } from "@src/containers/postShifts/hooks/useShiftTimes";
import { ShiftType } from "@src/containers/postShifts/postShift.schema";
import { convertShiftTimeToDateTime } from "@src/containers/facilityDashboard/ShiftCalendar/newShift/shiftDate";
import { useFacilityTimeZone } from "./useFacilityTimeZone";
import { zonedTimeToUtc } from "date-fns-tz";
import { isDefined } from "@clipboard-health/util-ts";

export function useShiftInfo(workplaceId: string, shiftType: ShiftType = ShiftType.AM) {
  const { data: shiftTimes } = useShiftTimes(workplaceId);
  const facilityTimezone = useFacilityTimeZone();

  if (!isDefined(shiftTimes)) {
    return;
  }

  const { startTime, endTime } = convertShiftTimeToDateTime({
    shiftTime: shiftTimes[shiftType],
  });
  return {
    start: zonedTimeToUtc(startTime, facilityTimezone).toISOString(),
    end: zonedTimeToUtc(endTime, facilityTimezone).toISOString(),
    shiftType,
  };
}
