import { z } from "zod";

const qualificationAttributesSchema = z.object({
  name: z.string(),
  displayLabel: z.string(),
  description: z.string(),
  enabled: z.boolean(),
  createdAt: z.string(),
  updatedAt: z.string(),
});

export const qualificationSchema = z.object({
  type: z.literal("qualification"),
  id: z.string(),
  attributes: qualificationAttributesSchema,
});

export const qualificationResponseSchema = z.object({
  data: qualificationSchema,
});

export const qualificationsResponseSchema = z.object({
  data: z.array(qualificationSchema),
});

const createQualificationAttributesSchema = qualificationAttributesSchema.omit({
  createdAt: true,
  updatedAt: true,
});

export const createQualificationRequestSchema = z.object({
  data: z.object({
    type: z.literal("qualification"),
    attributes: createQualificationAttributesSchema,
  }),
});

const updateQualificationAttributesSchema = qualificationAttributesSchema.omit({
  name: true,
  createdAt: true,
  updatedAt: true,
});

export const updateQualificationRequestSchema = z.object({
  data: z.object({
    id: z.string(),
    type: z.literal("qualification"),
    attributes: updateQualificationAttributesSchema,
  }),
});

export type Qualification = z.infer<typeof qualificationSchema>;
export type QualificationResponse = z.infer<typeof qualificationResponseSchema>;
export type QualificationsResponse = z.infer<typeof qualificationsResponseSchema>;

export type CreateQualificationAttributes = z.infer<typeof createQualificationAttributesSchema>;
export type CreateQualificationParams = CreateQualificationAttributes;

export type UpdateQualificationAttributes = z.infer<typeof updateQualificationAttributesSchema>;
export type UpdateQualificationParams = UpdateQualificationAttributes & { id: string };
