import React from "react";
import { CornerRibbon, CornerRibbonContent, CornerRibbonInner } from "./styles";
import { NodeEnvironment, environmentConfig } from "@src/appV2/environment";

export const EnvironmentRibbon: React.FC = () => {
  const envName = environmentConfig.REACT_APP_ENVIRONMENT_NAME ?? import.meta.env.MODE;

  if (envName === NodeEnvironment.PRODUCTION) {
    return null;
  }

  return (
    <CornerRibbon>
      <CornerRibbonInner>
        <CornerRibbonContent>{envName.substring(0, 3).toUpperCase()}</CornerRibbonContent>
      </CornerRibbonInner>
    </CornerRibbon>
  );
};
