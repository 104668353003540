import React, { useState, useEffect, useMemo } from "react";
import { Select } from "antd";
import "./style.css";

import { getFacilityUserAccounts } from "../../api/facilityUsers";
import { useDebounce } from "../../hooks/useDebounce";

const { Option } = Select;

interface FacilityAccountSwitcherProps {
  userId: string;
  pseudo: (...args: any) => void;
  closeMenu: () => void;
  adjustUIForSmallScreens?: boolean;
}

function FacilityAccountSwitcher({
  userId,
  pseudo,
  closeMenu,
  adjustUIForSmallScreens = false,
}: FacilityAccountSwitcherProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [facilities, setFacilities] = useState<any[]>([]);
  const [search, setSearch] = useState<string>();

  useEffect(() => {
    const fetchFacilityUserAccounts = async () => {
      setIsLoading(true);
      try {
        const facilities = await getFacilityUserAccounts({ name: search });
        setFacilities(facilities);
      } catch (error) {
        console.log("error loading facilities", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchFacilityUserAccounts();
  }, [search]);

  const searchFacilities = useDebounce((name: string) => setSearch(name), []);

  const handleChange = (value) => {
    localStorage.setItem("selectedFacility", value);
    pseudo({ userId: value }, true);
    if (closeMenu) {
      closeMenu();
    }
  };

  const className = useMemo(
    () =>
      adjustUIForSmallScreens
        ? "hide-on-med-and-down facility-account-switcher"
        : "facility-account-switcher",
    [adjustUIForSmallScreens]
  );

  if (facilities.length < 2 && !search) {
    return null;
  }

  return (
    <Select
      placeholder="Select Facility"
      showSearch
      onSearch={searchFacilities}
      loading={isLoading}
      filterOption={false}
      value={userId}
      onChange={handleChange}
      data-testid="facility-account-switcher"
      className={className}
    >
      {facilities.map((facility) => (
        <Option key={facility._id} value={facility.userId}>
          {facility.name}
        </Option>
      ))}
    </Select>
  );
}

export { FacilityAccountSwitcher };
