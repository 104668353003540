import { Route } from "react-router-dom";
import { routes } from "./utils/routes";
import { Suspense } from "react";
import { FallbackLoader } from "./components/lazyLoad";

import { loadLazily } from "./utils/loadLazily";
import { useInitializeMonitoring } from "./hooks";

const WorkplaceSignUp = loadLazily(() =>
  import("./containers/workplaceSignUp/WorkplaceSignUp").then((module) => ({
    default: module.WorkplaceSignUp,
  }))
);

interface GuestProps {
  path: string[];
}

export function Guest(_props: GuestProps) {
  useInitializeMonitoring();

  return (
    <Suspense fallback={<FallbackLoader />}>
      <Route
        exact
        path={[routes.workplaceGetAccess, routes.workplaceReferrals]}
        component={WorkplaceSignUp}
      />
    </Suspense>
  );
}
