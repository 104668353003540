import { FacilityRole } from "src/api/facilityRoles";
import { FacilityFlags } from "src/containers/facilityUser/helpers";
import { MyAccountActionTypes, MYACCOUNT_ACTIONS } from "src/modules/myAccount/actions";
import { IFormData, IModalData, IUser, TypeAlertSettings } from "src/modules/myAccount/types";

export type MyAccountStateType = {
  loading: boolean;
  user?: IUser;
  showForm: boolean;
  formData?: IFormData;
  showModal: boolean;
  modalData?: IModalData;
  roles?: FacilityRole[];
  userRoles?: string[];
  redirectUrl?: string;
  flags?: FacilityFlags;
  alertSettings?: TypeAlertSettings;
};

export const initialState: MyAccountStateType = {
  loading: false,
  user: undefined,
  showForm: false,
  formData: undefined,
  showModal: false,
  modalData: undefined,
  roles: undefined,
  userRoles: undefined,
  redirectUrl: undefined,
  flags: undefined,
  alertSettings: undefined,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const myAccount = (state = initialState, action: MyAccountActionTypes) => {
  const { type, payload } = action;
  switch (type) {
    case MYACCOUNT_ACTIONS.LOADING:
      return {
        ...state,
        loading: payload,
      };
    case MYACCOUNT_ACTIONS.TOGGLE_FORM:
      return {
        ...state,
        showForm: payload,
      };
    case MYACCOUNT_ACTIONS.TOGGLE_MODAL:
      return {
        ...state,
        showModal: payload,
      };
    case MYACCOUNT_ACTIONS.SET_USER:
      return {
        ...state,
        user: payload,
      };
    case MYACCOUNT_ACTIONS.SET_FORM_DATA:
      return {
        ...state,
        formData: payload,
      };
    case MYACCOUNT_ACTIONS.SET_MODAL_DATA:
      return {
        ...state,
        modalData: payload,
      };
    case MYACCOUNT_ACTIONS.SET_FORM_REFERENCE:
      return {
        ...state,
        form: payload,
      };
    case MYACCOUNT_ACTIONS.TOGGLE_EMAIL_MODAL:
      return {
        ...state,
        showEmailModal: payload,
      };
    case MYACCOUNT_ACTIONS.SET_EMAIL_MODAL_DATA:
      return {
        ...state,
        emailModalData: payload,
      };
    case MYACCOUNT_ACTIONS.SET_EXISTING_USER:
      return {
        ...state,
        existingUser: payload,
      };
    case MYACCOUNT_ACTIONS.SET_ROLES:
      return {
        ...state,
        roles: payload,
      };
    case MYACCOUNT_ACTIONS.SET_USER_ROLES:
      return {
        ...state,
        userRoles: payload,
      };
    case MYACCOUNT_ACTIONS.SET_REDIRECT_URL:
      return {
        ...state,
        redirectUrl: payload,
      };
    case MYACCOUNT_ACTIONS.SET_FLAGS:
      return {
        ...state,
        flags: payload,
      };
    case MYACCOUNT_ACTIONS.SET_ALERT_SETTINGS:
      return {
        ...state,
        alertSettings: payload,
      };
    case MYACCOUNT_ACTIONS.RESET_STATE:
      return initialState;

    default:
      return state;
  }
};

export default myAccount;
