import { type ReactElement } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import { DEBUG_PATH, DebugPage } from "../Debug";
import { NotFoundPage } from "../lib";
import { E2ERoutes } from "../E2E/Routes";
import { E2E_TEST_PATH } from "../E2E/paths";

export function AppV2PublicRoutes(): ReactElement {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${match.url}/${DEBUG_PATH}`}>
        <DebugPage />
      </Route>
      <Route path={`${match.url}/${E2E_TEST_PATH}`}>
        <E2ERoutes />
      </Route>
      <Route path="*">
        <NotFoundPage />
      </Route>
    </Switch>
  );
}
