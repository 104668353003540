import "./WindowGlobalFix";
import "./App.css";
import "./index.css";

import { ApolloProvider } from "@apollo/client";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter5Adapter } from "use-query-params/adapters/react-router-5";
import { parse, stringify } from "query-string";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { asyncWithLDProvider, LDEvaluationDetail } from "launchdarkly-react-client-sdk";

import "./App.css";
import { ErrorBoundary } from "./components/errorBoundary";
import App from "./containers/app";
import { GraphQlClient } from "./graphql/client";
import "./index.css";
import store from "./store";
import meta from "./utils/meta";

import { TestPanel } from "src/test-helpers/TestPanel";
import { ChatProvider } from "./containers/chat/chat";
import { QueryProvider } from "./providers/QueryProvider";

import { routes } from "src/utils/routes";
import { Guest } from "./guest";
import { MaterialUiProviders } from "./mui/providers/MaterialUiProviders";
import { loadLazily } from "./utils/loadLazily";
import { Suspense } from "react";
import { FallbackLoader } from "./components/lazyLoad";
import { NodeEnvironment, environmentConfig } from "./appV2/environment";
import { Helmet } from "react-helmet";
import { LD_USER_KIND } from "./appV2/FeatureFlags/cbhUserContext";
import { GlobalPollingProvider } from "./appV2/polling/useGlobalPolling";
import { datadogRum } from "@datadog/browser-rum";

const DocumentProcessorContainer = loadLazily(() =>
  import("./containers/documentProcessor/DocumentProcessorContainer").then((module) => ({
    default: module.DocumentProcessorContainer,
  }))
);

if (
  (environmentConfig.REACT_APP_ENVIRONMENT_NAME ?? import.meta.env.MODE) !==
  NodeEnvironment.PRODUCTION
) {
  if (localStorage.getItem("AUTH_TOKEN")) {
    render(<TestPanel />, document.querySelector("#testPanel"));
  }
}

const target = document.querySelector("#root");

const { api, app } = meta();

window.global.api = api;
window.global.app = app;
(async () => {
  const LDProvider = await asyncWithLDProvider({
    context: { anonymous: true, kind: LD_USER_KIND },
    reactOptions: {
      useCamelCaseFlagKeys: false,
    },
    clientSideID: environmentConfig.REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID,
    options: {
      evaluationReasons: true,
      inspectors: [
        {
          type: "flag-used",
          name: "flag-inspector",
          method: async (key: string, detail: LDEvaluationDetail) => {
            datadogRum.addFeatureFlagEvaluation(key, detail.value);
          },
        },
      ],
    },
  });

  render(
    <ErrorBoundary>
      {/* we will replace apollo with react-query since react-query has better support for both REST and graphql */}
      <ApolloProvider client={GraphQlClient}>
        <QueryProvider>
          <Provider store={store}>
            <Helmet>
              <title>Clipboard Health</title>
            </Helmet>
            <BrowserRouter>
              <QueryParamProvider
                adapter={ReactRouter5Adapter}
                options={{
                  searchStringToObject: parse,
                  objectToSearchString: stringify,
                }}
              >
                <LDProvider>
                  <ChatProvider>
                    <MaterialUiProviders>
                      <GlobalPollingProvider>
                        <Switch>
                          {/* add Guest's path to satisfy Switch's requirement for Route direct children
                        https://github.com/remix-run/react-router/blob/v5/packages/react-router/modules/Switch.js#L27 */}
                          <Guest path={[routes.workplaceGetAccess, routes.workplaceReferrals]} />
                          <Route exact path={routes.verifyDocument}>
                            <Suspense fallback={<FallbackLoader />}>
                              <DocumentProcessorContainer />
                            </Suspense>
                          </Route>
                          <App />
                        </Switch>
                      </GlobalPollingProvider>
                    </MaterialUiProviders>
                  </ChatProvider>
                </LDProvider>
              </QueryParamProvider>
            </BrowserRouter>
          </Provider>
        </QueryProvider>
      </ApolloProvider>
    </ErrorBoundary>,
    target
  );
})();
