import request from "superagent";
import { getAuthHeader } from "./superagent";
import moment from "moment-timezone";
import { isEmpty } from "lodash";
import meta from "./meta";
import { showErrorToast } from "@src/appV2/lib/Notifications";
import type { Shift } from "@src/interface";
import { isDefined } from "@clipboard-health/util-ts";
import { isPast, parseISO } from "date-fns";

const { maxShiftDuration, minShiftDuration } = meta();

export const ShiftActivity = [
  "SHIFT_OPEN",
  "SHIFT_ASSIGN",
  "SHIFT_REASSIGN",
  "SHIFT_UNASSIGN",
  "SHIFT_DELETE",
  "SHIFT_DELETE_FILLED",
  "FACILITY_CANCEL",
  "FACILITY_CANCEL_FILLED",
  "WORKER_CANCEL",
  "NO_CALL_NO_SHOW",
  "SHIFT_CLAIM",
];

export const DEFAULT_BREAK_DURATION = 30;

export const checkConflictingShift = async (startTime, endTime, shift) => {
  try {
    const { body } = await request
      .get(`${global.api}/shift/checkConflict`)
      .query({
        startTime: startTime.format(),
        endTime: endTime.format(),
        agentId: shift.agentId,
        shiftId: shift._id,
      })
      .set(await getAuthHeader());

    const shiftConflict = body.conflict;
    if (!isEmpty(shiftConflict)) {
      // There is conflict

      const nameHCP = shiftConflict.agent.name;
      const facilityName = shiftConflict.facility.name;
      const shiftType = `${shift.name.toUpperCase()}`;
      const strStartTime = moment(shiftConflict.start).format("h:mm A");
      const strEndTime = moment(shiftConflict.end).format("h:mm A");

      const errorMessage = `${nameHCP} has a conflict with a ${facilityName} shift at ${shiftType} from ${strStartTime} to ${strEndTime}`;

      showErrorToast(errorMessage);
      return true;
    }
    return false;
  } catch (error) {
    showErrorToast("Something went wrong while checking for shift conflict");
  }
};

export const doesShiftIncludeLunchBreak = (
  start: string | moment.Moment | undefined,
  end: string | moment.Moment | undefined
) => {
  if (!start || !end) {
    return true;
  }
  const nonLunchDuration = moment.duration(moment(end).diff(moment(start))).asHours();
  return nonLunchDuration > 6;
};

export const roundUpHoursTwoDecimal = (timeInHours: number) => {
  if (Number.isNaN(Number(timeInHours))) {
    return 0;
  }
  const multiplier = 100;
  // to fix weird decimal like 69.18 * 100 = 6918.000000000001
  const fixEpsilonDecimal = (timeInHours * multiplier).toFixed(10);
  return Math.ceil(Number(fixEpsilonDecimal)) / multiplier;
};

export const checkDurationErrorWithStartAndEndDate = (
  startTime: string | Date | moment.Moment,
  endTime: string | Date | moment.Moment
) => {
  let duration;
  if (moment(endTime).isBefore(moment(startTime))) {
    // add 24 hours to end time if it is before start time
    duration = moment(endTime).add(1, "day").diff(moment(startTime), "hours", true);
  } else {
    duration = moment(endTime).diff(moment(startTime), "hours", true);
  }
  return checkDurationError(duration);
};

export const checkDurationError = (durationInHours: number) => {
  if (durationInHours > maxShiftDuration) {
    return `Shift duration can't be longer than ${maxShiftDuration} hours.`;
  } else if (durationInHours < minShiftDuration) {
    return `Shift duration can't be less than ${minShiftDuration * 60} minutes.`;
  }
};

export function isUnfilledPastShift(shift: Pick<Shift, "agentId" | "end">) {
  return !isDefined(shift.agentId) && isPast(parseISO(shift.end));
}
