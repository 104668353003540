import { Link, type LinkProps } from "@mui/material";
import { forwardRef } from "react";

export interface ExternalLinkProps extends Omit<LinkProps, "href"> {
  to: string;
}

export const ExternalLink = forwardRef<HTMLAnchorElement, ExternalLinkProps>((props, ref) => {
  const { to, children, ...rest } = props;
  return (
    <Link ref={ref} href={to} {...rest} target="_blank" rel="noopener noreferrer">
      {children}
    </Link>
  );
});

ExternalLink.displayName = "ExternalLink";
