import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import { Badge, Stack } from "@mui/material";
import { VisitOccurrenceStatus } from "../Cases/types";
import { useVisitOccurrences } from "./api/useVisitOccurrences";

export function VisitVerificationMenuItem() {
  const { data: pendingVisitOccurrences } = useVisitOccurrences({
    filter: { status: VisitOccurrenceStatus.PENDING },
  });
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Badge badgeContent={pendingVisitOccurrences?.length} color="info" max={99}>
        <AssignmentTurnedInOutlinedIcon fontSize="small" />
      </Badge>
      <span>Visit Verification</span>
    </Stack>
  );
}
