import { keyBy } from "lodash";
import request from "superagent";
import { getAuthHeader } from "../utils/superagent";

export const RETRIEVE = "employees/RETRIEVE";

const initialState = {
  employees: {},
};

/**
 * Redux state management and other boilerplate code is painful to manage and hinders discoverability.
 * Redux code guidelines show this bizarre pattern with initializing function arguments
 * without a default for the action. Example:
 * https://redux.js.org/usage/structuring-reducers/initializing-state#single-simple-reducer
 * This is linted against via typical lint rules.
 * Unfortunately, redux recommends this, so we need to mark the lint rule as disabled until
 * we eliminate redux from the code base.
 * https://linear.app/clipboardhealth/issue/FEF-167/deprecate-redux
 */
// eslint-disable-next-line @typescript-eslint/default-param-last
export default (state = initialState, action) => {
  switch (action.type) {
    case RETRIEVE:
      return {
        ...state,
        employees: keyBy(action.data, "userId"),
      };
    default:
      return state;
  }
};

export const getEmployees = () => {
  return async (dispatch) => {
    return request
      .get(`${global.api}/employees/details`)
      .set(await getAuthHeader())
      .then((res) => {
        dispatch({
          type: RETRIEVE,
          data: res.body,
        });
      });
  };
};
