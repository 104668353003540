import { Tag, Button } from "antd";
import { WORKER_STAGES_ENUM, getWorkerStageLabel } from "@src/utils/worker";
import { FacilityStatusObj } from "@src/utils/facility";
import { SearchAttribute } from "./utils";

export function AccountSwitcherOptionGroup({ type, userDetails, handleChange }) {
  const listCount = userDetails?.list.length || 0;
  const moreResults = userDetails.count - listCount;

  if (!userDetails.loading && !listCount) {
    return <p className="no-record">No Records</p>;
  }

  const stageDisplay = (user) => {
    const stage = type === "AGENT" ? user.stage : user.status;

    if (
      [
        WORKER_STAGES_ENUM.RESTRICTED,
        WORKER_STAGES_ENUM.DEACTIVATED,
        WORKER_STAGES_ENUM.SOFT_DELETED,
        FacilityStatusObj.ARCHIVED,
        FacilityStatusObj.SUSPENDED,
        FacilityStatusObj.TERMINATED,
      ].includes(stage)
    ) {
      return <Tag color="red">{getWorkerStageLabel(stage).toUpperCase()}</Tag>;
    }
  };

  const userKeys = ["name", "email", "phone", "userId"];
  return (
    <div>
      {userDetails?.list.map((user) => (
        <div
          className="account-select"
          onClick={() => handleChange({ user, type })}
          key={user._id}
          role="button"
          tabIndex={0}
          onKeyDown={(event) => {
            event.preventDefault();
            if (event.key === "Enter" || event.key === " ") {
              handleChange({ user, type });
            }
          }}
        >
          {userKeys.map((key) => SearchAttribute(user, key))}
          {stageDisplay(user)}
        </div>
      ))}
      {userDetails.loading || userDetails.isFetchingNextPage ? (
        <h4>Loading...</h4>
      ) : (
        <Button
          className="account-select"
          onClick={() => userDetails.fetchNextPage()}
          type="link"
          data-testid="account-more-btn"
        >
          {moreResults > 0 && `+${moreResults} more results`}
        </Button>
      )}
    </div>
  );
}
