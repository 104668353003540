import request from "superagent";
import { getAuthHeader } from "../utils/superagent";

export const createEvent = async (shiftId, event) => {
  return request
    .post(`${global.api}/event/create`)
    .set(await getAuthHeader())
    .send({
      shift: shiftId,
      event,
    });
};
