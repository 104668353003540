import { Title } from "@clipboard-health/ui-react";
import { Box, Stack } from "@mui/material";
import { Image } from "@src/appV2/lib";
// eslint-disable-next-line no-restricted-imports
import { FirebaseAuth } from "@src/utils/firebase";
import qs from "query-string";
import { type ReactElement, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import fluffyMonsterScreamingImage from "./assets/fluffyMonsterScreaming.png";

// This page is used by our E2E tests to bypass OTP login
// Access this route in the test with ?e2eAuthToken=<custom-token-here>
export function E2EAuthPage(): ReactElement {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const { search } = useLocation();

  useEffect(() => {
    const queryParams = qs.parse(search);
    const token = queryParams.e2eAuthToken as string;

    let isCurrent = true;

    const loginWithFirebaseCustomToken = async () => {
      if (token) {
        try {
          await FirebaseAuth.signInWithCustomToken(token);
          if (isCurrent) {
            setIsAuthenticated(true);
          }
        } catch {
          setIsAuthenticated(false);
        }
      }
    };

    void loginWithFirebaseCustomToken();

    return () => {
      isCurrent = false;
    };
  }, [search]);

  return (
    <Box sx={{ height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Stack alignItems="center" direction="column" spacing={1}>
        <Title bold component="h1">
          Logging in with custom token...
        </Title>
        <Title component="h2">{isAuthenticated ? "Authenticated" : "Loading..."}</Title>
        <br />
        <Image src={fluffyMonsterScreamingImage} alt="Authenticating test scenario" width={360} />
      </Stack>
    </Box>
  );
}
