import { Badge, SxProps, Theme, Tooltip } from "@mui/material";
import pluralize from "pluralize";

export interface ExtraWorkedTimeRequestMenuBadgeProps {
  count: number;
  sx?: SxProps<Theme>;
}

const digitWords: { [number: number]: string } = {
  0: "zero",
  1: "one",
  2: "two",
  3: "three",
  4: "four",
  5: "five",
  6: "six",
  7: "seven",
  8: "eight",
  9: "nine",
};

export function ExtraWorkedTimeRequestMenuBadge(
  props: ExtraWorkedTimeRequestMenuBadgeProps
): JSX.Element {
  const { count, sx } = props;

  return (
    <Tooltip
      title={`There ${pluralize("is", count)} ${
        count < 10 ? digitWords[count] : count
      } pending Worked Break Payment ${pluralize("Request", count)}`}
    >
      <Badge badgeContent={count} max={9} color="error" sx={sx}></Badge>
    </Tooltip>
  );
}
