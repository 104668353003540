import { Shift, ShiftSpecifications } from "src/interface";
import request from "superagent";
import { getAuthHeader } from "src/utils/superagent";
import {
  FacilityCalendarCounters,
  FacilityNegotiationCounters,
  FacilityShift,
  FacilityShiftCounters,
  IFetchFacilityCalendarData,
  BulkShiftPost,
  FetchFacilityCalendarAvailableWorkerCountData,
  FetchFacilityCalendarAvailableWorkersData,
  FetchFacilityCalendarAvailableWorkersResponse,
} from "./types";
import { ChargeValue } from "./newShift/utils";
import { logError } from "src/appV2/lib/analytics";
import { environmentConfig } from "@src/appV2/environment";

const workerServiceUrl = environmentConfig.REACT_APP_WORKER_SERVICE_URL;

const defaultShiftTimes = {
  am: {
    start: {
      hour: 5,
      minute: 0,
    },
    duration: {
      hours: 8,
      minutes: 0,
    },
  },

  pm: {
    start: {
      hour: 5,
      minute: 0,
    },
    duration: {
      hours: 8,
      minutes: 0,
    },
  },

  noc: {
    start: {
      hour: 5,
      minute: 0,
    },
    duration: {
      hours: 8,
      minutes: 0,
    },
  },
  custom: {
    start: {
      hour: 7,
      minute: 0,
    },
    duration: {
      hours: 8,
      minutes: 0,
    },
  },
  surg: {
    start: {
      hour: 9,
      minute: 0,
    },
    duration: {
      hours: 4,
      minutes: 0,
    },
  },
};
export type DefaultShiftTimes = typeof defaultShiftTimes;

const fetchFacilityCalendarData = async (
  query: IFetchFacilityCalendarData
): Promise<FacilityCalendarCounters> => {
  const [shiftsData, negotiationsData] = await Promise.all([
    fetchFacilityCalendarShifts(query),
    fetchFacilityCalendarNegotiations({
      ...query,
      agentReq: query.type,
    }),
  ]);
  // Merging negotiationsData to shiftsData, since if some date has negotiation,
  // it will definitely have a shift (but not vice-versa).
  Object.keys(shiftsData).forEach((dateAsString) => {
    if (!negotiationsData[dateAsString]) {
      return;
    }
    shiftsData[dateAsString] = Object.assign(
      shiftsData[dateAsString],
      negotiationsData[dateAsString]
    );
  });
  return shiftsData;
};

const fetchFacilityCalendarShifts = async (
  query: IFetchFacilityCalendarData
): Promise<FacilityShiftCounters> => {
  const { body } = await request
    .get(`${global.api}/calendar/facilityCount`)
    .set(await getAuthHeader())
    .query(query);
  return body;
};

const fetchFacilityCalendarNegotiations = async ({
  agentReq,
  dateFilter,
  tmz,
  facilityId,
}: {
  agentReq: string;
  dateFilter: {
    start: string;
    end: string;
  };
  tmz: string;
  facilityId: string;
}): Promise<FacilityNegotiationCounters> => {
  const { body } = await request
    .get(`${global.api}/negotiation/facilityOpenNegotiationCount`)
    .set(await getAuthHeader())
    .query({
      agentReq,
      dateFilter,
      tmz,
      facilityId,
    });
  return body;
};

const fetchFacilityDayShifts = async (query: {
  admin: boolean;
  dateFilter: {
    start: string;
    end: string;
  };
  tmz: string;
  type: string | undefined;
  facilityId: string;
  includeNegotiations: boolean;
}): Promise<FacilityShift[]> => {
  const { body } = await request
    .get(`${global.api}/calendar/facility`)
    .set(await getAuthHeader())
    .query(query);

  return body;
};

const fetchShiftTimes = async ({ userId }: { userId: string }): Promise<DefaultShiftTimes> => {
  const { body } = await request
    .get(`${global.api}/shiftTimes/get/${userId}`)
    .set(await getAuthHeader());

  return {
    ...defaultShiftTimes,
    ...body.standardDefs,
  };
};

const updateShiftTime = async (requestData: {
  data: {
    start: {
      hour: number;
      minute: number;
    };
    duration: {
      hours: number;
      minutes: number;
    };
  };
  facilityId: string;
  name: keyof DefaultShiftTimes;
}) => {
  const { body } = await request
    .post(`${global.api}/shiftTimes/update`)
    .set(await getAuthHeader())
    .send(requestData);

  return body.standardDefs;
};

const createNewShift = async (data: {
  admin: boolean;
  facilityId: string;
  start: string | undefined;
  end: string | undefined;
  agentReq: string;
  name: string | undefined;
  description: string | undefined;
  surgerySpeciality: {
    hasSedationExperience: boolean | undefined;
    hasTrayAssemblyExperience: string | false | undefined;
    speciality: string | undefined;
  };
  overrideCharges: ChargeValue[] | undefined;
  shiftSpecifications?: ShiftSpecifications[];
}): Promise<Shift & { rushFeeDifferential: number }> => {
  return request
    .post(`${global.api}/shift/create`)
    .set(await getAuthHeader())
    .send(data)
    .then(({ body }) => body);
};

const createBulkShifts = async (data: BulkShiftPost): Promise<Shift[]> => {
  return request
    .post(`${global.api}/shift/create`)
    .set(await getAuthHeader())
    .send(data)
    .then(({ body }) => body);
};

const fetchSuspendedReason = async (data: { facilityId: string; type: string }) => {
  return request
    .get(`${global.api}/shift/facility/suspension`)
    .set(await getAuthHeader())
    .query(data)
    .then(({ body }) => body);
};

const confirmShift = async (data) => {
  return request
    .post(`${global.api}/shift/hcfConfirm`)
    .set(await getAuthHeader())
    .send(data)
    .then(({ body }) => body);
};

const updateFacilityRates = async (data: {
  facilityId: string;
  performedBy: string | undefined;
  rates: Record<string, number>;
}) => {
  return request
    .put(`${global.api}/facilityprofile/put`)
    .set(await getAuthHeader())
    .send(data)
    .then(({ body }) => body);
};

export type PayTable = {
  floor: number;
  marketBase: number;
  marketFill: number;
  sd: number;
  weekdayBase: number;
  weekendBase: number;
};

export enum RecommendedChargeReason {
  CHARGE_BLOCKED_FACILITY = "CHARGE_BLOCKED_FACILITY",
}

export type ChargeOption = {
  standard: number;
  recommended?: number;
  reason?: RecommendedChargeReason;
  fill?: number;
};

export interface EstimatedCharge {
  charge?: ChargeOption;
  nurseCharges?: { LVN: ChargeOption; RN: ChargeOption };
}
const fetchEstimatedCharge = async (
  workplaceId: string,
  agentReq: string,
  shiftType: string,
  shiftStartDate: string
): Promise<EstimatedCharge> => {
  return request
    .get(`${global.api}/v2/shifts/estimated-charge`)
    .set(await getAuthHeader())
    .query({ workplaceId, agentReq, shiftType, shiftStartDate })
    .then(({ body }) => body);
};

const fetchFacilityCalendarAvailableWorkersCount = async (
  query: FetchFacilityCalendarAvailableWorkerCountData,
  workplaceId: string
) => {
  return request
    .get(`${workerServiceUrl}/v1/workplaces/${workplaceId}/calendar/available-worker-count`)
    .set(await getAuthHeader())
    .query(query)
    .then(({ body }) => body)
    .catch((error) =>
      logError("Error in fetchFacilityCalendarAvailableWorkersCount", {
        error,
      })
    );
};

const fetchFacilityCalendarAvailableWorkers = async (
  query: FetchFacilityCalendarAvailableWorkersData,
  workplaceId: string
): Promise<FetchFacilityCalendarAvailableWorkersResponse> => {
  return request
    .get(`${workerServiceUrl}/v1/workplaces/${workplaceId}/calendar/available-workers`)
    .set(await getAuthHeader())
    .query(query)
    .then(({ body }) => body)
    .catch((error) =>
      logError("Error in fetchFacilityCalendarAvailableWorkers", {
        error,
      })
    );
};

export async function deleteShiftInvite(shiftInviteId: string): Promise<void> {
  await request.delete(`${global.api}/shift-invites/${shiftInviteId}`).set(await getAuthHeader());
}

export {
  fetchFacilityCalendarData,
  fetchFacilityDayShifts,
  fetchShiftTimes,
  createNewShift,
  createBulkShifts,
  defaultShiftTimes,
  updateShiftTime,
  fetchSuspendedReason,
  confirmShift,
  updateFacilityRates,
  fetchEstimatedCharge,
  fetchFacilityCalendarAvailableWorkersCount,
  fetchFacilityCalendarAvailableWorkers,
};
