import { differenceInDays } from "date-fns";
import { isDefined } from "@clipboard-health/util-ts";
import { Tooltip } from "@mui/material";
import LocalFireDepartmentOutlined from "@mui/icons-material/LocalFireDepartmentOutlined";

const BONUS_TEXT =
  "Professionals with Streaks receive additional rewards for working at your facility.";

/**
 * @param {string} streakRequirement - required number to achieve a streak. Default: 14 hours
 * @param {string} streakWindow - the time window to achieve the streak. Default: last week
 */
export interface StreakIndicatorProps {
  streak?: {
    validityInterval: {
      from: string;
      to: string;
    };
    status: string;
    minHoursForActiveStreak: number;
    payBoostEnabled: boolean;
  };
  disabled?: boolean;
}

export function getHumanReadableStreakInterval(from?: string, to?: string) {
  if (!isDefined(from) || !isDefined(to) || from === "" || to === "") {
    return "week";
  }

  const differenceInNumberOfDays = differenceInDays(new Date(to), new Date(from));
  if (differenceInNumberOfDays === 0 || differenceInNumberOfDays === 1) {
    return "day";
  }

  if (differenceInNumberOfDays >= 1 && differenceInNumberOfDays < 5) {
    return "few days";
  }

  if (differenceInNumberOfDays >= 5 && differenceInNumberOfDays < 14) {
    return "week";
  }

  return "month";
}

export function StreakIndicator(props: StreakIndicatorProps) {
  const { streak, disabled = false } = props;
  const {
    validityInterval,
    status = "INACTIVE",
    minHoursForActiveStreak = 14,
    payBoostEnabled = false,
  } = streak ?? {};

  const { from: start, to: end } = validityInterval ?? {};

  return (
    <Tooltip
      title={`This professional has worked ${minHoursForActiveStreak} hours in the last ${getHumanReadableStreakInterval(
        start,
        end
      )} at your facility. ${payBoostEnabled ? BONUS_TEXT : ""}`}
    >
      <LocalFireDepartmentOutlined
        role="presentation"
        aria-hidden={false}
        aria-label="Toggle Active Streak Tooltip"
        {...props}
        sx={{
          color: (theme) =>
            disabled || status !== "ACTIVE"
              ? theme.palette.action.disabled
              : theme.palette.warning.main,
        }}
      />
    </Tooltip>
  );
}
