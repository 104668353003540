import { Text } from "@clipboard-health/ui-react";
import { IconButton, Stack, Box, useMediaQuery } from "@mui/material";
import { AiOutlineClose } from "react-icons/ai";
import FacilityAppIcon from "./facilityAppIcon.png";
import { useSelector } from "react-redux";
import { SessionType } from "src/modules/interface";
import { useLocalStorage } from "react-use";
import { HCF_USER_EVENTS } from "src/constants/firebaseEvents";
import { useLocation } from "react-router-dom";
import { isDefined } from "@clipboard-health/util-ts";
import { logEvent } from "@src/appV2/lib/analytics";
import { FacilityStatusObj } from "src/utils/facility";
import {
  facilityAppDownloadBannerVisibilityLocalStorageKey,
  facilityAppStoreUrl,
} from "./constants";
import { primitives } from "src/designsystem/primitives";
import { BREAKPOINTS } from "src/designsystem/variables";

/**
 * FIXME: Refactor the component to use standard MUI components like Title, Text, Button, Image, External links, etc. once added to the project
 * https://clipboardhealth.atlassian.net/browse/CH-25159
 */
export function FacilityAppDownloadBanner() {
  const [isBannerVisible, setIsBannerVisible] = useLocalStorage(
    facilityAppDownloadBannerVisibilityLocalStorageKey,
    true
  );
  const location = useLocation();
  const isMobile = useMediaQuery(`(max-width:${BREAKPOINTS.sm})`);
  const { type, userId, user, admin, profile } = useSelector((state: SessionType) => state.session);
  const isFacility = type === "FACILITY";
  if (
    !isMobile ||
    !isFacility ||
    isBannerVisible === false ||
    profile.status === FacilityStatusObj.UNSIGNED
  ) {
    return null;
  }
  return (
    <Box
      sx={{
        backgroundColor: primitives.colors.grey[200],
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        spacing={1}
        sx={{
          padding: 1,
          pr: 2,
        }}
      >
        <IconButton
          size="small"
          aria-label="Close Banner"
          onClick={() => {
            if (isDefined(user) && !admin) {
              logEvent(HCF_USER_EVENTS.FACILITY_APP_DOWNLOAD_BANNER, {
                source: location.pathname,
                workplaceId: userId,
                workplaceUserId: user._id,
                action: "close_banner",
              });
            }
            setIsBannerVisible(false);
          }}
        >
          <AiOutlineClose name="Close" />
        </IconButton>
        <img
          src={FacilityAppIcon}
          alt=""
          width={64}
          height={64}
          style={{
            borderRadius: "8px",
          }}
        />
        <Stack flexGrow={1}>
          <Text>Download our mobile app</Text>
          <Text
            variant="caption"
            sx={{
              /**
               * FIXME - rely on the theme for lineHeight. Do not locally style typography.
               */
              lineHeight: 1.5,
            }}
          >
            For a smoother experience, download the Clipboard Health Facility app, today.
          </Text>
        </Stack>
        <Stack justifyContent="center" alignItems="center">
          <a
            href={facilityAppStoreUrl}
            target="_self"
            rel="noreferrer"
            onClick={() => {
              if (isDefined(user) && !admin) {
                logEvent(HCF_USER_EVENTS.FACILITY_APP_DOWNLOAD_BANNER, {
                  source: location.pathname,
                  workplaceId: userId,
                  workplaceUserId: user._id,
                  action: "view_app",
                });
              }
            }}
          >
            View
          </a>
        </Stack>
      </Stack>
    </Box>
  );
}
