import { Button, Popover } from "antd";
import { map, xor } from "lodash";
import React, { forwardRef } from "react";
import { useSelector } from "react-redux";

import {
  FACILITY_ROLES,
  FACILITY_ROLE_MAPPING,
  FACILITY_ROLE_DESCRIPTIONS,
} from "../../../constants/facility";
import { ButtonCheckboxContainer } from "../style";

/* @ts-expect-error FIXME type issues */
const RolesInput = forwardRef(({ value, onChange, disabled, userToEditIsLoggedIn }, ref) => {
  const { user, admin } = useSelector((state: any) => state.session);
  const { access } = user || {};
  const updateRoles = (role) => () => {
    let updatedValue: any[] = [];

    if (role === FACILITY_ROLES.ADMIN) {
      if (value.includes(role)) {
        updatedValue = [];
      } else {
        updatedValue = Object.values(FACILITY_ROLES);
      }
    } else {
      updatedValue = xor(value, [role]);
    }

    onChange(updatedValue);
  };

  const isDisabled = (role) => {
    if (userToEditIsLoggedIn && !access.includes(FACILITY_ROLES.ADMIN)) {
      return true;
    }
    if (role === FACILITY_ROLES.ADMIN) {
      if (admin) {
        return false;
      }
      if (!access?.includes(FACILITY_ROLES.ADMIN)) {
        return true;
      }
      return false;
    }
    return value.includes(FACILITY_ROLES.ADMIN);
  };

  return (
    /* @ts-expect-error FIXME this wrong type */
    <ButtonCheckboxContainer ref={ref}>
      {map(FACILITY_ROLES, (role, key) => (
        <Popover content={FACILITY_ROLE_DESCRIPTIONS[role]} key={role}>
          <Button
            key={role}
            ghost={!value.includes(role)}
            disabled={disabled || isDisabled(role)}
            type="primary"
            onClick={updateRoles(role)}
          >
            {FACILITY_ROLE_MAPPING.get(key)}
          </Button>
        </Popover>
      ))}
    </ButtonCheckboxContainer>
  );
});

export { RolesInput };
