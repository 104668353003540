import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { Button } from "src/designsystem/Button/Button";
import { capitalizeFirstLetter } from "src/utils/string";
import { useState } from "react";
import {
  CheckboxCol,
  CheckboxRow,
  FilterFooter,
  FilterLabel,
  StyledCheckbox,
  StyledModal,
} from "../WorkersDirectory.styles";

export interface WorkerFilterModalProps {
  visible: boolean;
  initialQualifications: string[];
  qualifications: string[];
  onCancel: () => void;
  onFilter: (workerTypesToFilter: string[]) => void;
}

export function WorkerFilterModal({
  visible,
  initialQualifications,
  qualifications,
  onCancel,
  onFilter,
}: WorkerFilterModalProps) {
  const [filterQualification, setFilterQualification] = useState(initialQualifications);
  const handleQualificationChange = (event: CheckboxChangeEvent) => {
    const qualification = event.target.value;
    const isSelected = event.target.checked;
    if (isSelected) {
      setFilterQualification((previousFilterQualification) => [
        ...previousFilterQualification,
        qualification,
      ]);
    } else {
      setFilterQualification((previousFilterQualification) =>
        previousFilterQualification.filter((type) => type !== qualification)
      );
    }
  };

  function getQualificationLabel(qualification: string) {
    if (qualification === "LVN") {
      return "LVN/LPN";
    }
    if (qualification.length <= 3) {
      return qualification;
    }
    return qualification.toLowerCase().split(" ").map(capitalizeFirstLetter).join(" ");
  }
  return (
    <StyledModal
      data-testid="worker-filter-modal"
      title="Filter"
      visible={visible}
      onCancel={onCancel}
      maskClosable={false}
      footer={
        <FilterFooter>
          <Button variant="secondary" onClick={() => onFilter([])}>
            Clear filter
          </Button>
          <Button
            variant="primary"
            disabled={!filterQualification.length}
            onClick={() => onFilter(filterQualification)}
          >
            Apply filter
          </Button>
        </FilterFooter>
      }
      width={500}
    >
      <>
        <FilterLabel>Worker Types</FilterLabel>
        <CheckboxRow>
          {qualifications.map((qualification) => (
            <CheckboxCol key={qualification}>
              <div>
                <StyledCheckbox
                  key={qualification}
                  value={qualification}
                  checked={filterQualification.includes(qualification)}
                  onChange={handleQualificationChange}
                />
                {getQualificationLabel(qualification)}
              </div>
            </CheckboxCol>
          ))}
        </CheckboxRow>
      </>
    </StyledModal>
  );
}
