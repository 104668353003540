import { getAuthHeader } from "src/utils/superagent";
import request from "superagent";
import { WorkerDepartureStatusResponse } from "./response.type";
import { showErrorToast } from "@src/appV2/lib/Notifications";
import type { AdminDetailsShift } from "@src/components/adminEditShift/types";
import type { DeleteShiftRequestParams, UnassignShiftRequestParams } from "./types";

export interface WorkerSentHomePayoutParamResponse {
  time: number;
  isBillable: boolean;
  isPayable: boolean;
  isWorkerLate?: boolean;
  workerLatenessInMinutes?: number;
  payableAmount: number;
  billableAmount: number;
}

const deleteShift = async ({
  shiftId,
  userId,
  isFacilityCancel,
  reasonType,
  reasonDescription,
}: DeleteShiftRequestParams) => {
  return request
    .post(`${global.api}/shift/delete`)
    .set(await getAuthHeader())
    .send({
      shiftId,
      sessionUser: userId,
      isFacilityCancel,
      reasonType,
      reasonDescription,
    });
};

export const unassignShift = async ({
  shiftId,
  agentId,
  userId,
  isAgentCancel,
  reasonType,
  reasonDescription,
  isNoCallNoShow,
  requestReplacement = false,
}: UnassignShiftRequestParams) => {
  return request
    .post(`${global.api}/shift/unassign`)
    .set(await getAuthHeader())
    .send({
      shiftId,
      agentId,
      sessionUser: userId,
      isAgentCancel,
      reasonType,
      reasonDescription,
      isNoCallNoShow,
      requestReplacement,
    });
};

const getSelectedShiftData = async (query): Promise<{ shift: AdminDetailsShift } | undefined> => {
  try {
    const { body } = await request
      .get(`${global.api}/adminShifts`)
      .set(await getAuthHeader())
      .query(query);
    return { shift: body };
  } catch (error) {
    showErrorToast((error as Error).message);
  }
};

export interface ShiftCancellationParamsResponse {
  payableAmount: number;
  billableAmount: number;
  cancellationWindow: number;
  payoutDuration: number;
  feeHours: number;
  workerEta?: {
    etaRecordFound: boolean;
    isLate: boolean;
    isLateBeyondThreshold: boolean;
    workerLateDurationInMins: number;
  };
}

const getShiftCancellationParams = async (
  shiftId: string
): Promise<ShiftCancellationParamsResponse> => {
  try {
    const { body } = await request
      .get(`${global.api}/shift/${shiftId}/cancellationParams`)
      .set(await getAuthHeader());

    return body as ShiftCancellationParamsResponse;
  } catch (err) {
    showErrorToast((err as Error).message);
    throw err;
  }
};

const updateShiftCharge = async ({ shiftId, updatedInfo }) => {
  await request
    .put(`${global.api}/shift/facilityChargeOverride`)
    .set(await getAuthHeader())
    .send({
      shiftId,
      updatedInfo,
    });
};

const getFacilityShiftData = async (shiftId: string) => {
  const { body } = await request
    .get(`${global.api}/calendar/facility`)
    .set(await getAuthHeader())
    .query({ shiftId });

  if (body?.[0]?._id) {
    return body[0];
  }

  throw new Error("Could not retrieve the shift data. Please try again later!");
};

const getShiftBetweenHcfAndHcp = async (hcpId: string) => {
  const { body } = await request
    .get(`${global.api}/v2/shifts/shifts-by-hcpId/${hcpId}`)
    .set(await getAuthHeader());

  if (body._id) {
    return body._id;
  }

  throw new Error("Could not retrieve the shift data. Please try again later!");
};

const getWorkerSentHomeCancellationParams = async (
  shiftId: string
): Promise<WorkerSentHomePayoutParamResponse | undefined> => {
  try {
    const { body } = await request
      .get(`${global.api}/shift/${shiftId}/sentHomeCancellationPayParams`)
      .set(await getAuthHeader());

    return body as WorkerSentHomePayoutParamResponse;
  } catch (err) {
    showErrorToast((err as Error).message);
    throw err;
  }
};

async function getWorkerDepartureStatus(
  shiftId: string
): Promise<WorkerDepartureStatusResponse | undefined> {
  try {
    const { body } = await request
      .get(`${global.api}/urgentShifts/check-worker-departure/${shiftId}`)
      .set(await getAuthHeader());

    return body as WorkerDepartureStatusResponse;
  } catch (err) {
    console.error((err as Error).message);
  }
}

export {
  deleteShift,
  getSelectedShiftData,
  getShiftCancellationParams,
  updateShiftCharge,
  getFacilityShiftData,
  getShiftBetweenHcfAndHcp,
  getWorkerSentHomeCancellationParams,
  getWorkerDepartureStatus,
};
