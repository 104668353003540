import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getPreferredWorkers } from "src/api/workers";
import { LONG_STALE_TIME } from "src/constants/query";

export function usePreferredWorker(workplaceId: string, workerId: string, staleTime?: number) {
  const queryClient = useQueryClient();
  const queryKey = ["preferred-worker", workplaceId, workerId];

  const { isLoading: preferredWorkerLoading, data: preferredWorkers } = useQuery({
    queryKey,
    queryFn: () => {
      return getPreferredWorkers({
        workplaceId,
        workerId,
      });
    },
    staleTime: staleTime ?? LONG_STALE_TIME,
  });

  const invalidateCache = () => {
    queryClient.removeQueries(queryKey);
  };

  return {
    data: preferredWorkers?.preferred[0],
    isLoading: preferredWorkerLoading,
    invalidateCache,
  };
}
