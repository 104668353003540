import { ApolloClient } from "@apollo/client";
import { cache } from "./cache";
import { link } from "./authLink";

const GraphQlClient = new ApolloClient({
  link,
  cache,
});

export { GraphQlClient };
