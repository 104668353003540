import { Button, Table, Tag, Tooltip, Typography } from "antd";
import { invert, isEqual, map } from "lodash";
import React from "react";
import { useSelector } from "react-redux";

import { FACILITY_ROLES, FACILITY_ROLE_MAPPING } from "../../../constants/facility";
import { searchFilter } from "../../../utils/filtering";
import { formatPhone } from "../../../utils/strings";

const { Paragraph } = Typography;

const invertedRoles = invert(FACILITY_ROLES);

const renderRoles = (roles) =>
  roles.map((role) => <Tag key={role}>{FACILITY_ROLE_MAPPING.get(invertedRoles[role])}</Tag>);

const roleFilters = map(FACILITY_ROLES, (value, key) => ({ value, text: key }));

const noLocalFilter = () => true;

function UserTable({ loading, dataSource, page, setPage, total, filter, setFilter, onEdit }) {
  const { admin, user } = useSelector((state: any) => state.session);
  const { _id: session, access } = user || {};
  const handleOnChange = (_pagination, tableFilter) => {
    if (!isEqual(filter, tableFilter)) {
      setFilter({ ...tableFilter });
    }
  };

  const renderName = (name, facilityUser) => {
    if (facilityUser._id === session || admin || access?.includes(FACILITY_ROLES.ADMIN)) {
      return (
        <Button type="link" onClick={onEdit(facilityUser)}>
          {name}
        </Button>
      );
    }

    return name;
  };

  function RenderNotesTitle() {
    return <Tooltip title="This field is only visible to Clipboard Health">Notes</Tooltip>;
  }

  const renderNotes = (notes) => (
    <Paragraph ellipsis={{ rows: 2, expandable: true }}>{notes}</Paragraph>
  );

  const renderNotesColumn = () => {
    if (!admin) {
      return {};
    }

    return {
      title: <RenderNotesTitle />,
      key: "notes",
      width: 150,
      dataIndex: "notes",
      render: renderNotes,
    };
  };

  const columns = [
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
      ...searchFilter("name", "name", noLocalFilter),
      filteredValue: filter.name,
      render: renderName,
    },
    {
      title: "Email",
      key: "email",
      dataIndex: "email",
      ...searchFilter("email", "email", noLocalFilter),
      filteredValue: filter.email,
    },
    {
      title: "Mobile",
      key: "phone",
      dataIndex: "phone",
      render: (phone, _record, _index) => formatPhone(phone),
      ...searchFilter("phone", "phone", noLocalFilter),
      filteredValue: filter.phone,
    },
    {
      title: "Designation",
      key: "designation",
      dataIndex: "designation",
    },
    {
      title: "Roles",
      key: "roles",
      dataIndex: "roles",
      align: "center",
      render: renderRoles,
      filters: roleFilters,
      filterMultiple: true,
      filteredValue: filter.roles,
    },
    renderNotesColumn(),
  ];

  return (
    <Table
      /* @ts-expect-error FIXME this wrong type */
      columns={columns}
      loading={loading}
      dataSource={dataSource}
      data-testid="users-table"
      rowKey="_id"
      pagination={{
        hideOnSinglePage: true,
        current: page,
        onChange: setPage,
        total,
        showSizeChanger: false,
      }}
      onChange={handleOnChange}
    />
  );
}

export { UserTable };
