const latestVersions = {
  Chrome: 78,
  Firefox: 70,
  Edge: 44,
};

/**
 * @deprecated
 * FIXME - this code needs a lot of rework. Converted to a standard third party package.
 */
export function getBrowser() {
  const { userAgent } = navigator;
  let currentMatches;
  let browserMatch =
    userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
  if (/trident/i.test(browserMatch[1])) {
    currentMatches = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
    return { name: "IE", version: currentMatches[1] || "" };
  }
  if (browserMatch[1] === "Chrome") {
    currentMatches = userAgent.match(/\bOPR\/(\d+)/);
    if (currentMatches !== null) {
      return { name: "Opera", version: currentMatches[1] };
    }
  }
  if (window.navigator.userAgent.indexOf("Edge") > -1) {
    currentMatches = userAgent.match(/Edge\/(\d+)/);
    if (currentMatches !== null) {
      return { name: "Edge", version: currentMatches[1] };
    }
  }
  if (window.navigator.userAgent.indexOf("Edg") > -1) {
    currentMatches = userAgent.match(/Edg\/(\d+)/);
    if (currentMatches !== null) {
      return { name: "Edge", version: currentMatches[1] };
    }
  }
  if (browserMatch[1] === "Safari") {
    currentMatches = userAgent.match(/\Safari\/(\d+)/);
    if (currentMatches !== null) {
      return { name: "Safari", version: currentMatches[1] };
    }
  }
  browserMatch = browserMatch[2]
    ? [browserMatch[1], browserMatch[2]]
    : [navigator.appName, navigator.appVersion, "-?"];
  if ((currentMatches = userAgent.match(/version\/(\d+)/i)) !== null) {
    return {
      name: "MOBILE_VERSION",
    };
  }
  return {
    name: browserMatch[0],
    version: +browserMatch[1],
  };
}

function checkSupport(browser) {
  let supported = true;
  if (browser.name === "Chrome" && browser.version < latestVersions[browser.name] - 5) {
    supported = false;
  } else if (browser.name === "Firefox" && browser.version < latestVersions[browser.name] - 5) {
    supported = false;
  } else if ((browser.name === "MSIE" || browser.name === "IE") && browser.version < 11) {
    supported = false;
  } else if (browser.name === "Opera") {
    supported = false;
  }
  return supported;
}

const browser = getBrowser();

const supportError = document.getElementById("support_error");
const rootElement = document.getElementById("root");

if (browser.name === "IE" || browser.name === "MSIE") {
  if (supportError) {
    supportError.style.visibility = "visible";
  }
}

if (browser.name !== "IE" && browser.name !== "MSIE" && rootElement && supportError) {
  rootElement.style.display = "block";
  supportError.style.display = "none";
}

const isSupported = checkSupport(browser);

if (!isSupported && rootElement && supportError) {
  rootElement.style.display = "none";
  supportError.style.display = "block";
  supportError.style.visibility = "visible";
}
