import { environmentConfig } from "@src/appV2/environment";
import request from "superagent";

export const _recordShiftTime = async (shiftId: string, actionTime: string, action: string) => {
  const data = {
    stage: action,
    location: [73.0702553, 33.6717341],
    timecard: "",
    locationType: "LIVE",
    appType: "WEB",
    connectivityMode: "ONLINE",
    actionTime,
  };

  let actionRes = await request
    .post(`${environmentConfig.REACT_APP_BASE_API_URL}/api/testHelpers/shift/recordTime/${shiftId}`)
    // .set(await getAuthHeader())
    .send(data);
  actionRes = await actionRes;
  return actionRes;
};
