import { CbhFeatureFlag, useCbhFlag } from "../FeatureFlags";
import { PlacementAccessControlStatus } from "../FeatureFlags/CbhFeatureFlags";

export function useGetPlacementsAccessLevel() {
  const flagValue = useCbhFlag(CbhFeatureFlag.PLACEMENTS_ACCESS_CONTROL_ROLLOUT, {
    defaultValue: PlacementAccessControlStatus.OFF,
  });
  return {
    isEnabled:
      flagValue === PlacementAccessControlStatus.EDIT ||
      flagValue === PlacementAccessControlStatus.VIEW,
    accessLevel: flagValue,
  };
}
