import { useEffect } from "react";
import { initDatadogRum } from "@src/utils/datadog";
import { datadogRum } from "@datadog/browser-rum";

export function useInitializeDatadog() {
  useEffect(() => {
    initDatadogRum();
    datadogRum.startSessionReplayRecording();
  }, []);
}
